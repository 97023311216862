import React from "react";
import styled from "styled-components";
import Service from "./Service";
import { Button } from "../../basics/Button";
import logo from "../../img/logo.svg";
import COLORS from "../../../constants/colors";
import { Header } from "../../composed/Header";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Logoheader1 = styled.img`
  width: 20%;
  display: flex;
  height: auto;
  margin: auto;
  margin-top: 80px;
  object-fit: contain;
  justify-content: center;
  @media only screen and (max-width: 900px) {
    width: 30%;
    margin-top: 50px;
  }
`;

const Grectangle = styled.div`
  display: flex;
  flex-direction: column;
  margin: -115px 15% 0 15%;
  padding: 50px;
  border-radius: 20px;
  max-width: 1000px;
  box-shadow: 0 0 60px 0 rgb(0 0 0 / 4%);
  background-color: #ffffff;
  @media only screen and (max-width: 1600px) {
    margin: -7vw 15% 0 15%;
  }
  @media only screen and (max-width: 900px) {
    margin: -10vw 25px 0 25px;
    padding: 20px 20px;
  }
`;

const Textone = styled.p`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: #777caa;
  margin-bottom: 10px;
  @media only screen and (max-width: 900px) {
    font-size: 22px;
  }
`;

const Texttwo = styled.p`
  font-size: 18px;
  font-weight: 600;
  opacity: 0.6;
  text-align: center;
  color: #777caa;
  margin-bottom: 30px;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

const Textthree = styled.p`
  margin: 30px 15%;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  color: ${COLORS.ORAGE};
  @media only screen and (max-width: 900px) {
    margin: 30px 30px;
    font-size: 22px;
  }
`;

const SummaryButton = styled(Button)`
  margin-bottom: 100px;
  @media only screen and (max-width: 900px) {
    margin-bottom: 60px;
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DemoHeader = styled.p`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

const Summary = () => {
  return (
    <Container>
      <Header
        title="L’outil qui simplifie votre quotidien d’Expert-Comptable"
        buttonTitle="Découvrir"
      />
      <Grectangle>
        <div>
          <Textone>
            Gestion interne 100% collaborative dediée aux Cabinets
            d'Expertise-Comptable.
          </Textone>
          <Texttwo>Une solution idéale pour vous permettre de gérer :</Texttwo>
        </div>
        <Service />
      </Grectangle>
      <SummaryContainer>
        <Logoheader1 src={logo} />

        <Textthree>
          Un outil unique qui vous permettra de piloter votre Cabinet, ainsi
          vous pourrez mettre vos collaborateurs dans les meilleures
          dispositions !
        </Textthree>
        <SummaryButton href="https://calendly.com/ecp-ahmed/15min">
          <DemoHeader>Réserver un rendez vous</DemoHeader>
        </SummaryButton>
      </SummaryContainer>
    </Container>
  );
};

export default Summary;
