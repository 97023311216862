import React from "react";
import styled from "styled-components";
import { Spacer } from "./basics/Spacer";
import { Link } from "react-router-dom";
import {
  SectionSubtitle,
  SectionTitle,
  SemiBoldText,
  SmallText,
} from "./basics/Typography";
import { Header } from "./composed/Header";
import { SectionContainer } from "./HomeComponents/HeaderComponents/SectionContainer";
import Footer from "./Footer";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f4f7;
`;
const SCSemiBoldText = styled(SemiBoldText)`
  font-size: 15px;
  @media only screen and (max-width: 900px) {
  }
`;
const Table = styled.table`
  border-collapse: collapse;
  @media only screen and (max-width: 900px) {
  }
`;
const ColTitle = styled.th`
  border: 1px solid black;
  padding: 5px;
  @media only screen and (max-width: 900px) {
  }
`;
const TableCase = styled.td`
  border: 1px solid black;
  padding: 5px;
  @media only screen and (max-width: 900px) {
  }
`;
const AnnexeTitle = styled(SectionSubtitle)`
  text-decoration: underline;
  @media only screen and (max-width: 900px) {
  }
`;

const SaleConditions = () => {
  return (
    <Container>
      <Header title="Conditions générales de vente" />
      <SectionContainer>
        <SectionTitle>CONDITIONS GÉNÉRALES DE VENTE</SectionTitle>
        <SectionSubtitle>Dernière mise à jour : Août 2021 </SectionSubtitle>
        <Spacer height="50px" />
        <SemiBoldText>1. À PROPOS DE NOUS </SemiBoldText>
        <SCSemiBoldText>
          1.1 Informations concernant la société :
        </SCSemiBoldText>
        <SmallText>
          H&C Technologies, conduisant ses activités sous le nom commercial «
          Nahal», une société par actions simplifiée (sous le numéro 894 798
          909) dont le siège social est sis 21, rue d’Algérie 69001 Lyon. Notre
          numéro de TVA est le FR18894798909. Nous exploitons le site Internet
          https://www.nahal.io
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>1.2 Nous contacter : </SCSemiBoldText>
        <SmallText>
          {" "}
          <b>Pour les Partenaires et les Clients : </b>Si vous avez des
          questions concernant votre compte, la facturation, ou pour tout
          problème technique ou toute assistance, veuillez envoyer un courriel à
          notre équipe du service clients à support@nahal.io. La procédure à
          suivre pour nous signifier une notification formelle concernant toute
          question en vertu du Contrat est énoncée à la clause 17.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>2 NOTRE CONTRAT AVEC VOUS </SemiBoldText>
        <SCSemiBoldText>2.1 Notre contrat : </SCSemiBoldText>
        <SmallText>
          Les présentes conditions générales de vente (« Conditions »)
          s’appliquent à : (i) la commande passée par vous; et/ou au (ii)
          contrat pour la prestation de Services par nous auprès de vous («
          Contrat »). Elles s’appliquent à l’exception de toute autre condition
          que vous cherchez à imposer ou incorporer, ou qui est impliquée par un
          usage du commerce, une pratique ou un usage commercial.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>
          2.2 Les présentes conditions s’appliquent aux Clients et Partenaires
          de Nahal :
        </SCSemiBoldText>
        <SmallText>
          Vous devriez savoir si vous êtes un Client ou un Partenaire, et les
          présentes conditions s’appliquent à vous deux. Client désigne toute
          personne s’inscrivant pour utiliser les Services, y compris des
          clients de Partenaires et Clients Partenaires, et qui n’est pas un
          Partenaire. Partenaire désigne un Expert-comptable et ses Utilisateurs
          autorisés.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>2.3 Utilisateurs :</SCSemiBoldText>
        <SmallText>
          En qualité de Partenaire, vous êtes responsable de l’ensemble des
          Utilisateurs Partenaires utilisant les Services. En qualité de Client,
          vous êtes responsable de l’ensemble de vos Utilisateurs autorisés
          utilisant les Services.
          <br /> 2.3.1 Si vous êtes un Client, nous avons l’intention de
          conclure un accord séparé avec tout Partenaire associé ; et <br />
          2.3.2 Si vous êtes un Partenaire, nous avons l’intention de conclure
          un accord séparé avec tout Client Partenaire, mais vous demeurerez
          responsable de son utilisation des Services.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>2.4 Numéros de clients uniques :</SCSemiBoldText>
        <SmallText>
          Afin de suivre l’utilisation des Services par des Partenaires, et
          d’empêcher toute utilisation non autorisée des Services ainsi qu’il
          est énoncé à la clause 7, nous attribuerons à chaque compte
          utilisateur un numéro de client unique.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>
          2.5 Utilisateurs de l’application mobile :
        </SCSemiBoldText>
        <SmallText>
          Le présent Contrat s’applique à votre utilisation de la plate-forme de
          notre site Internet. Des conditions séparées s’appliqueront à
          l’utilisation de notre application pour téléphone portable.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>2.6 Votre exemplaire :</SCSemiBoldText>
        <SmallText>
          Vous devez imprimer un exemplaire des présentes Conditions ou les
          enregistrer sur votre appareil pour toute référence future.{" "}
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>3 DÉFINITIONS </SemiBoldText>
        <SmallText>
          <b> « Abonné annuel » </b> désigne un abonné annuel ainsi qu’il est
          énoncé dans le Forfait ;
          <Spacer height="20px" />
          <b>« Utilisateurs autorisés » </b> désigne tous membres de votre
          Personnel que vous autorisez à utiliser les Services Documentation ;{" "}
          <Spacer height="20px" />
          <b>« Offre groupée » </b>
          désigne un ensemble de licences utilisateurs du Forfait pour utiliser
          les Produits Cœurs de Métier, les fonctionnalités de Nahal qui sont
          regroupés pour un prix fixe ainsi qu’il est convenu entre les parties
          et énoncé à la clause 5.2 ; <Spacer height="20px" />
          <b>« Jour ouvrable » </b>
          désigne un jour autre qu’un samedi, un dimanche ou un jour férié en
          France; <Spacer height="20px" />{" "}
          <b> « Informations confidentielles » </b>
          désigne toute information de nature confidentielle (que ladite
          information soit ou non enregistrée sur tout support physique,
          électronique ou autre) vous ou nous concernant, qui est
          confidentielle, commercialement sensible et n’appartient pas au
          domaine public (qu’elle porte ou non la mention « confidentiel ») ;{" "}
          <Spacer height="20px" /> <b> « Modification » </b> désignera une Mise
          à niveau inférieur ou une Mise à niveau ; <Spacer height="20px" />
          <b> « Date d’entrée en vigueur » </b> aura la signification qui lui
          est attribuée à la clause 5.6 ; <Spacer height="20px" />{" "}
          <b> « Données Clients » </b> désigne les données saisies par vous, des
          Utilisateurs Partenaires (si vous êtes un Partenaire) ou par nous pour
          votre compte, dans le but d’utiliser les Services ou de faciliter
          votre utilisation des Services. <Spacer height="20px" />{" "}
          <b> « Démo » </b> aura la signification qui lui est attribuée à la
          clause 4.1 ; <Spacer height="20px" /> <b> « Documentation » </b>{" "}
          désigne le document que nous mettons à votre disposition : (i) par
          e-mail ; ou (ii) en ligne via https://www.nahal.io/ (ou autre adresse
          Internet que nous vous notifions de temps à autre et qui contient une
          description des Services ainsi que les instructions d’utilisation des
          Services ; <Spacer height="20px" />
          <b> « Mise à niveau inférieur » </b> désigne : (i) une réduction du
          prix de l’abonnement payé eu égard à un Forfait ou une Offre groupée
          découlant de la suppression d’une fonctionnalité et/ou d’une Option
          et/ou (ii) une réduction du nombre de licences utilisateurs
          s’appliquant à votre Forfait ou Offre groupée et/ou (iii) le transfert
          vers un Forfait de niveau inférieur. <Spacer height="20px" />{" "}
          <b> « Frais » </b>
          désigne les frais redevables en vertu du présent Contrat (hors TVA et
          toutes autres taxes pertinentes, le cas échéant), ainsi que nous le
          détaillons de temps à autre, y compris sur notre site Internet et dans
          le cadre de la Confirmation de Commande ; <Spacer height="20px" />{" "}
          <b>« Essai gratuit » </b> aura la signification qui lui est attribuée
          à la clause 4.1 ; <Spacer height="20px" />{" "}
          <b>« Période d’abonnement initiale » </b> désigne pour les Abonnés
          mensuels une période d’un mois et pour les Abonnés annuels ou les
          Abonnés mensuels avec Engagement d’un An une période de 12 mois,
          débutant, dans chaque cas, à la Date d’entrée en
          <Spacer height="20px" /> <b> « Entité locale » </b> aura la
          signification qui lui est attribuée à la clause 9.13 ;
          <Spacer height="20px" /> <b>« Abonné mensuel » </b> désigne un abonné
          mensuel ainsi qu’il est énoncé dans le Forfait ;{" "}
          <Spacer height="20px" />
          <Spacer height="20px" /> <b> « Heures d’ouverture habituelles » </b>{" "}
          désigne 08h00 à 17h00 heure locale de Paris, chaque Jour ouvrable ;
          <Spacer height="20px" />
          <b> «Confirmation de Commande » </b> aura la signification qui lui est
          à la clause 5.6 ; <Spacer height="20px" />{" "}
          <b> « Clients Partenaires »</b>
          désigne vos clients qui utilisent les Services ou pour le compte
          desquels vous utilisez les Services en vertu des conditions du
          Forfait, y compris tous membres du Personnel desdits clients ;{" "}
          <Spacer height="20px" /> <b> « Utilisateurs Partenaires » </b> désigne
          les (a) Utilisateurs autorisés, et les (b) Clients Partenaires ;{" "}
          <Spacer height="20px" /> <b> « Forfait » </b> aura la signification
          qui lui est attribuée à la clause 5.1, et fera partie des Services ;{" "}
          <Spacer height="20px" /> <b>« Période de Renouvellement » </b> aura la
          signification qui lui est attribuée à la clause 17.6 ;{" "}
          <Spacer height="20px" />
          <b>« Services » </b> désigne les Produits Cœurs de Métier et services
          Nahal en ligne fournis par Nahal pour la « gestion interne ». Ceci
          comprend des services complémentaires achetés séparément (le cas
          échéant), sauf indication contraire dans la confirmation de commande
          de services complémentaires. Certains produits, fonctionnalités et
          services, que ce soit dans le cadre d’un Forfait ou d’une Offre
          groupée ou en tant qu’Option, vous seront fournis directement par
          Nahal (ou l’une de ses filiales) tandis que d’autres produits et
          services vous seront fournis par un tiers et le rôle de Nahal dans la
          fourniture desdits produits ou services sera celui d’apporteur
          d’affaires uniquement ; et <Spacer height="20px" />{" "}
          <b>« Personnel » </b>
          désigne les administrateurs, partenaires, cadres, employés, agents
          et/ou contractants indépendants ; <Spacer height="20px" />{" "}
          <b> « Période d’abonnement » </b> désigne la Période d’abonnement
          initiale toute Période de Renouvellement ultérieure.{" "}
          <Spacer height="20px" /> <b>« Mise à niveau » </b> désigne : (i)
          l’ajout d’un service payant à votre Forfait ou Offre groupée
          existant(e) ; et/ou (ii) l’augmentation du nombre de licences
          utilisateurs de votre Offre groupée ou Forfait ; ou (iii) le transfert
          vers un Forfait de niveau supérieur.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>4 Démos et Essais gratuits </SemiBoldText>
        <SmallText>
          <SCSemiBoldText>4.1 </SCSemiBoldText> Vous avez le droit de demander
          une démonstration ou un essai gratuit des Services (si disponible),
          que nous organiserons à une date et une heure convenues, afin de vous
          permettre de décider si les Services répondent ou non à vos exigences
          (« <b> Démo </b> »). Nous pouvons également convenir de temps à autre
          de vous donner accès à nos Services uniquement avec une fonctionnalité
          limitée afin que vous puissiez surveiller les activités de vos Clients
          Partenaires («<b> Essai gratuit </b> »). Au cours de tout(e) Démo ou
          Essai gratuit convenu(e), les conditions à l’Annexe 1 s’appliqueront
          et le présent Contrat sera modifié ainsi qu’il est stipulé à l’Annexe
          1.
          <Spacer height="20px" /> <SCSemiBoldText>4.2 </SCSemiBoldText>À
          l’issue de tout(e) Démo ou Essai gratuit, vous pouvez décider de nous
          passer ou non une commande conformément à la clause 5 ci-dessous.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>5 Nos Services</SemiBoldText>
        <SCSemiBoldText>5.1 Choisir votre service Nahal :</SCSemiBoldText>
        <SmallText>
          Vous acceptez le service (et le niveau approprié) suite à une
          consultation avec l’un de nos représentants du service clients ou en
          ligne via notre site Internet, (un « <b> Forfait </b> ») convenant à
          votre utilisation prévue des Services et d’après les informations que
          vous nous fournissez (y compris le nombre de Clients Partenaires
          requis). Vous pouvez effectuer une Mise à niveau inférieur ou une Mise
          à niveau de votre Forfait à tout moment, sous réserve de la clause 8.{" "}
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>5.2 Offres groupées :</SCSemiBoldText>
        <SmallText>
          Lorsque vous acceptez une Offre groupée de services, y compris les
          Forfaits de service y compris les options intégrant tous les modules
          vous convenez d’utiliser les services et de payer les frais ainsi
          qu’il est convenu dans ladite Offre groupée.{" "}
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>5.3 Passer votre commande :</SCSemiBoldText>
        <SmallText>
          Chaque commande constitue une offre de votre part d’acheter les
          Services spécifiés dans votre commande sous réserve des présentes
          Conditions. Vous pouvez nous passer une commande :
          <Spacer height="20px" />● par <b> téléphone </b> en vous adressant à
          l’un de nos représentants du service clients (voir « Nous contacter »
          ci-dessus) ;
          <Spacer height="20px" />● par <b> e-mail </b> en envoyant un courriel
          confirmant votre commande à votre représentant du service clients ou à
          toute autre adresse e-mail que nous pouvons vous notifier ;
          <Spacer height="20px" />● <b> en ligne </b> en suivant les invites à
          l’écran pour choisir votre produit et passer une commande ; ou
          <Spacer height="20px" />● <b> en personne </b> lors d’un événement.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>5.4 Corriger des erreurs de saisie :</SCSemiBoldText>
        <SmallText>
          Notre procédure de commande <b> en ligne </b> vous permet de vérifier
          et de modifier toute erreur avant de nous soumettre votre commande.
          Veuillez vérifier soigneusement la commande avant de la confirmer.
          Vous devez vous assurer que votre commande est complète et exacte.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>5.5 Accepter votre commande :</SCSemiBoldText>
        <SmallText>
          {" "}
          Nous acceptons votre commande une fois que nous avons (i) reçu vos
          coordonnées de paiement via notre formulaire de paiement et (ii)
          traité avec succès le paiement de votre commande. Nous vous enverrons
          alors un courriel détaillant votre commande («{" "}
          <b> Confirmation de Commande </b> »), stade auquel et date à laquelle
          (« <b> Date d’entrée en vigueur </b> ») le Contrat entre vous et nous
          prendra effet. Le Contrat concernera uniquement les Services confirmés
          dans la Confirmation de Commande.{" "}
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>5.6 Fonctionnalités :</SCSemiBoldText>
        <SmallText>
          {" "}
          Des conditions générales supplémentaires peuvent s’appliquer à
          certaines fonctionnalités de nos Services qui vous sont proposées dans
          le cadre de votre Forfait ou Offre groupée.
        </SmallText>
        <Spacer height="20px" />
        <Link to="">
          {" "}
          <SmallText>Utiliser les Services </SmallText>{" "}
        </Link>
        <Spacer height="20px" />
        <SCSemiBoldText>5.7 Licence :</SCSemiBoldText>
        <SmallText>
          Nous vous octroyons un droit limité, non-exclusif et incessible pour
          permettre aux Utilisateurs autorisés d’accéder aux et d’utiliser les
          Services et la Documentation pendant la Période d’abonnement
          conformément aux présentes Conditions, sans le droit d’octroyer de
          sous-licences (« <b> Licence </b> »).
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>5.8 Usage professionnel :</SCSemiBoldText>
        <SmallText>
          Les Services sont réservés à un usage professionnel.{" "}
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>5.9 Fourniture des Services :</SCSemiBoldText>
        <SmallText>
          Nous déploierons des efforts commercialement raisonnables pour
          garantir la disponibilité des Services 24 heures / 24, sept jours /
          sept, pendant la Période d’abonnement, sauf pour :
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>5.10 Disponibilité</SCSemiBoldText>
        <SmallText>
          {" "}
          <Spacer height="20px" />
          5.10.1 une maintenance planifiée conduite pendant la fenêtre de
          maintenance qui s’étend généralement de 06h00 à 10h00 heure de Paris
          le samedi ; et dimanche
          <Spacer height="20px" />
          5.10.2 une maintenance non programmée exécutée en dehors des Heures
          d’ouverture habituelles, à condition que nous ayons déployé des
          efforts raisonnables pour vous signifier un préavis d’au moins 3
          heures pendant les Heures d’ouverture habituelles. //
          eslint-disable-next-line prettier/prettier
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>6 Nos obligations </SemiBoldText>
        <SCSemiBoldText>6.1 </SCSemiBoldText>
        <SmallText>
          Nous nous engageons à fournir les Services avec une diligence et des
          compétences raisonnables.
          <Spacer height="20px" />
        </SmallText>
        <SCSemiBoldText>6.2</SCSemiBoldText>
        <SmallText>
          Nous proposons une assistance à tous les utilisateurs via notre site
          Internet, nos forums d’utilisateurs et par téléphone. Vous acceptez
          que, même si nous nous efforcerons raisonnablement de résoudre des
          problèmes que vous identifiez, la nature des logiciels est telle
          qu’aucune garantie ne peut être soumise quant à la résolution de tout
          problème particulier.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>6.3 Nahal :</SCSemiBoldText>
        <SmallText>
          6.3.1 ne garantit pas que :
          <Spacer height="20px" />
          (i) votre utilisation des Services sera ininterrompue ou exempte
          d’erreur ; ou
          <Spacer height="20px" />
          (ii) que les Services, la Documentation et/ou les informations que
          vous obtenez à travers les Services répondront à vos exigences ; et
          <Spacer height="20px" />
          6.3.2 décline toute responsabilité quant à tout retard, échec de
          livraison, ou tout(e) autre perte ou dommage résultant du transfert de
          données sur des réseaux et installations de communication, Internet
          compris, et vous reconnaissez que les Services et la Documentation
          peuvent faire l’objet de limitations, de retards et autres problèmes
          inhérents à l’utilisation desdits réseaux et installations de
          communication.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>7 Vos obligations </SemiBoldText>
        <SCSemiBoldText> 7.1 Vous vous engagez à :</SCSemiBoldText>
        <SmallText>
          7.1.1 nous apporter toute la coopération nécessaire en relation avec
          le présent Contrat pour garantir la fourniture des Services ;
          <Spacer height="20px" />
          7.1.2 sans préjudice de vos autres obligations en vertu du présent
          Contrat, vous conformer à l’ensemble des lois et réglementations
          applicables eu égard à vos activités en vertu du présent Contrat ;
          <Spacer height="20px" />
          7.1.3 vous assurer que les Utilisateurs Partenaires utilisent les
          Services et la Documentation conformément au présent Contrat et à
          assumer la responsabilité de toute violation par tout Utilisateur
          Partenaire du présent (ou de son) Contrat ;
          <Spacer height="20px" />
          7.1.4 obtenir et conserver l’ensemble des licences nécessaires (à
          l’exception des licences des logiciels que nous utilisons pour fournir
          les Services), des consentements, et des permissions nécessaires pour
          nous permettre et permettre à nos contractants et agents d’exécuter
          nos obligations en vertu du présent Contrat, y compris, sans
          limitation, les Services ;
          <Spacer height="20px" />
          7.1.5 vous assurer que votre réseau et vos systèmes soient conformes
          aux spécifications pertinentes que nous vous communiquons ou mettons à
          votre disposition sur notre site Internet de temps à autre ;
          <Spacer height="20px" />
          7.1.6 vous assurer que chaque Utilisateur autorisé conserve un mot de
          passe sécurisé pour son utilisation des Services et de la
          Documentation et que chaque Utilisateur autorisé tienne son mot de
          passe confidentiel ; et à
          <Spacer height="20px" />
          7.1.7 être, dans la mesure permise par la loi et sauf stipulation
          expresse contraire dans le présent Contrat, seul responsable de la
          fourniture, du maintien et de la sécurisation de vos connexions de
          réseau et liaisons de télécommunications entre vos systèmes et nos
          centres de données, et de l’ensemble des problèmes, situations,
          retards, échecs de livraison et tout(e) autre perte ou dommage
          découlant de ou se rapportant à vos connexions de réseau ou liaisons
          de télécommunication ou causés par Internet.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>7.2</SCSemiBoldText>
        <SmallText>
          Vous vous engagez à ne pas accéder à, stocker, distribuer ou
          transmettre tout(e) ver informatique, cheval de Troie, virus et autres
          programmes ou dispositifs similaires, ou tout contenu au cours de
          votre utilisation des Services qui :
          <Spacer height="20px" />
          7.2.1 est illégal, préjudiciable, menaçant, diffamatoire, obscène,
          illicite, malveillant ou offensant sur le plan racial ou ethnique ;
          <Spacer height="20px" />
          7.2.2 facilite une activité illégale ;
          <Spacer height="20px" />
          7.2.3 représente des images sexuellement explicites ;
          <Spacer height="20px" />
          7.2.4 encourage la violence ;
          <Spacer height="20px" />
          7.2.5 est discriminatoire en étant fondé sur la race, la nationalité
          d’origine, le sexe, la couleur, la croyance religieuse, l’orientation
          sexuelle ou le handicap ; ou
          <Spacer height="20px" />
          7.2.6 est autrement illégal ou porte préjudice ou atteinte à tout(e)
          personne ou bien,
          <Spacer height="20px" />
          et nous nous réservons le droit, sans responsabilité ni préjudice de
          nos autres droits envers vous, de désactiver votre accès à tout
          contenu qui enfreint les dispositions de la présente clause.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>7.3</SCSemiBoldText>
        <SmallText>
          Vous vous engagez à ne pas :
          <Spacer height="20px" />
          7.3.1 sauf ainsi que peut l’autoriser toute loi applicable ne pouvant
          pas être exclue au moyen d’un accord entre les parties et sauf dans la
          mesure expressément permise en vertu du présent Contrat :
          <Spacer height="20px" />
          (i) tenter de copier, modifier, reproduire, créer des œuvres dérivées
          de, cadrer, écrire en miroir, republier, télécharger, afficher,
          transmettre, ou distribuer la totalité ou toute partie des Services
          et/ou de la Documentation (selon le cas) sous quelque forme ou support
          que ce soit ou par quelque moyen que ce soit ; ou
          <Spacer height="20px" />
          (ii) tenter de décompiler, rétrocompiler, désassembler, rétroconcevoir
          ou réduire autrement à une forme perceptible par l’homme la totalité
          ou toute partie des Services ; ou
          <Spacer height="20px" />
          7.3.2 accéder à la totalité ou toute partie des Services et de la
          Documentation afin d’élaborer un produit ou un service qui est en
          concurrence avec les Services et/ou la Documentation ; ou
          <Spacer height="20px" />
          7.3.3 utiliser les Services et/ou la Documentation pour fournir des
          services à des tiers ; ou
          <Spacer height="20px" />
          7.3.4 sous réserve de la clause 18.4, concéder sous licence, vendre,
          louer, louer à bail, transférer, céder, distribuer, afficher,
          divulguer, ou exploiter commercialement autrement, ou mettre autrement
          les Services et/ou la Documentation à la disposition de tout tiers
          (autre que les Utilisateurs Partenaires si vous êtes un Partenaire) ;
          ou
          <Spacer height="20px" />
          7.3.5 tenter d’obtenir, ou aider des tiers à obtenir, un accès aux
          Services et/ou à la Documentation, autrement que pour des Utilisateurs
          Partenaires ou ainsi qu’il est autrement stipulé en vertu de la
          présente clause 7 ; ou
          <Spacer height="20px" />
          7.3.6 dépasser les limites d’utilisation énoncées dans votre Forfait
          ou Offre groupée convenu(e) ou, si vous êtes un Partenaire, supprimer
          et ajouter (ou tenter de supprimer et d’ajouter) des Clients
          Partenaires différents, ou manipuler autrement le nombre de vos
          Clients Partenaires, dans le but de vous assurer de respecter les
          limites d’utilisateurs fixées qui sont définies dans votre Forfait ou
          Offre groupée.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>7.4</SCSemiBoldText>
        <SmallText>
          Vous vous efforcerez raisonnablement d’empêcher tout accès non
          autorisé aux Services et/ou à la Documentation, ou toute utilisation
          non autorisée de ces derniers et/ou de cette dernière, et, dans le cas
          où vous prenez connaissance de tout dit accès ou de toute dite
          utilisation non autorisé(e), vous nous notifierez dans les plus brefs
          délais.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>7.5</SCSemiBoldText>
        <SmallText>
          Les droits stipulés en vertu de la présente clause 7 vous sont
          octroyés à titre exclusif, et ne seront pas considérés comme étant
          octroyés à l’une quelconque de vos filiales ou à une quelconque
          société holding sauf accord contraire de notre part.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>7.6</SCSemiBoldText>
        <SmallText>
          Vous nous indemnisez contre tout(e) perte ou dommage que nous
          encourons suite à votre violation de la présente clause 7 ou de toute
          autre condition du présent Contrat.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>8 Modifications </SemiBoldText>
        <SCSemiBoldText>8.1 Services:</SCSemiBoldText>
        <SmallText>
          Nous nous réservons le droit de modifier tout aspect des Services de
          temps à autre si exigé par toute exigence légale ou réglementaire
          applicable ou pour améliorer les Services. Toute dite amélioration
          n’aura pas une incidence préjudiciable substantielle sur la nature ou
          l’exécution des Services.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>8.2 Forfait :</SCSemiBoldText>
        <SmallText>
          Nous nous réservons le droit de Modifier votre Forfait (y compris le
          niveau du Forfait vous concernant) de temps à autre si nous décidons,
          à notre discrétion exclusive, que le Forfait que vous avez choisi
          n’est plus adapté. Sous réserve des clauses 8.3, 8.4 et 10.7, vous
          pouvez également Modifier votre Forfait à tout moment en vous
          adressant à l’un de nos représentants du service clients, comme suit :
          <Spacer height="20px" />
          8.2.1 toute Mise à niveau de votre Forfait ou Offre groupée prendra
          immédiatement effet et les Frais redevables par vous eu égard à la
          Période d’abonnement alors en vigueur seront majorés au prorata
          jusqu’à son expiration ; et
          <Spacer height="20px" />
          8.2.2 toute Mise à niveau inférieur de votre Forfait ou Offre groupée
          prendra effet à compter de l’expiration de la Période d’abonnement en
          vigueur. La Mise à niveau inférieur de votre Forfait ou Offre groupée
          peut entraîner une réduction du nombre de licences utilisateurs, de
          fonctionnalités, d’Options ou autres Services dont vous disposiez et
          dont vos Utilisateurs disposaient précédemment.
          <Spacer height="20px" />
          Si vous dépassez les limites d’utilisation définies dans votre Forfait
          ou toute Offre groupée que nous convenons avec vous, nous nous
          réservons le droit de modifier le Forfait ou l’Offre groupée faisant
          partie de votre abonnement et/ou de vous facturer l’utilisation
          supplémentaire des Services en dehors de votre Forfait ou Offre
          groupée au cours de la Période d’abonnement suivante.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>
          8.3 Modifications pour les Abonnés mensuels :
        </SCSemiBoldText>
        <SmallText>
          Si vous souhaitez effectuer une Mise à niveau inférieur de votre
          Forfait ou Offre groupée, vous devez signifier à Nahal un préavis par
          écrit d’au moins quatorze (14) jours avant la fin de votre Période
          d’abonnement alors en vigueur.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>
          8.4 Modifications pour les Abonnés annuels ou ACPM :
        </SCSemiBoldText>
        <SmallText>
          {" "}
          Sous réserve de la clause 10.2, si vous souhaitez effectuer une Mise à
          niveau inférieur de votre Forfait ou Offre groupée, vous devez
          signifier à Nahal un préavis par écrit d’au moins trente (30) jours
          avant la fin de votre Période d’abonnement alors en vigueur.{" "}
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>8.5 Remises :</SCSemiBoldText>
        <SmallText>
          {" "}
          Lorsque des offres ou remises ont été appliquées à vos Frais par notre
          équipe de ventes, nous nous réservons le droit de supprimer ces offres
          ou remises à tout moment à notre discrétion exclusive. Lorsque nous
          décidons de supprimer toute offre ou remise, nous vous le notifierons
          par écrit.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>9 Frais et paiement </SemiBoldText>
        <SCSemiBoldText>9.1</SCSemiBoldText>
        <SmallText>
          En contrepartie des Services que nous vous fournissons, et
          conformément à votre Forfait, vous nous payerez les Frais conformément
          à la présente clause 9. Les Frais seront redevables et exigibles par
          vous comme suit :
          <Spacer height="20px" />
          9.1.1 si vous êtes un Abonné annuel, tous les ans à l’avance à la Date
          d’entrée en vigueur ou à la date de renouvellement pertinente ; ou
          <Spacer height="20px" />
          9.1.2 si vous êtes un Abonné mensuel ou un Abonné mensuel avec
          engagement d’un an (voir les clauses 10.1 à 10.7 comprise), tous les
          mois à l’avance à la Date d’entrée en vigueur ou à la date de
          renouvellement pertinente.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>9.2</SCSemiBoldText>
        <SmallText>
          Tous les montants et Frais indiqués ou visés dans le présent Contrat :
          <Spacer height="20px" />
          9.2.1 seront redevables dans votre devise locale ou une devise que
          nous stipulons ; et
          <Spacer height="20px" />
          9.2.2 sont non-annulables et non-remboursables.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>9.3</SCSemiBoldText>
        <SmallText>
          Nous nous réservons le droit de majorer les Frais :
          <Spacer height="20px" />
          9.3.1 lorsque vous dépassez vos limites d’utilisation convenues ainsi
          qu’il est décrit aux clauses 8.2 et 8.5 ; ou
          <Spacer height="20px" />
          9.3.2 à tout moment (en signifiant un préavis raisonnable qui ne
          dépassera en aucun cas les délais prévus à la clause 17.1 et toute
          dite majoration ne sera pas appliquée avant la Période de
          renouvellement suivant la date dudit préavis (ou la première Période
          de renouvellement si vous êtes dans la Période d’abonnement initiale
          lorsque le préavis est signifié) ; ou
          <Spacer height="20px" />
          9.3.3 tous les ans avec prise d’effet à compter de chaque anniversaire
          de la Date d’entrée en vigueur au cours de la période de 12 mois
          précédente et la première dite majoration prendra effet au premier
          anniversaire de la Date d’entrée en vigueur.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>9.5</SCSemiBoldText>
        <SmallText>
          Tous les frais s’entendent hors taxes, charges, droits, prélèvements
          et autres frais de quelque nature que ce soit imposés eu égard à votre
          utilisation du Service et relèveront de votre responsabilité et seront
          redevables par vous. Nous ajouterons les taxes de vente locales à nos
          frais au taux alors en vigueur en fonction de votre localisation.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>9.6</SCSemiBoldText>
        <SmallText>
          Les Frais sont redevables via LemonWay (ou autre prestataire que Nahal
          peut designer), et vous communiquerez des coordonnées bancaires
          valides afin de payer les Frais applicables se rapportant à votre
          Forfait ou Offre groupée. Nos frais d’abonnement sont disponibles à
          l’adresse (<Link to=""> https://www.nahal.io </Link>) et Nahal peut
          modifier ces derniers de temps à autre à sa discrétion ;
          <Spacer height="20px" />
          9.6.1 Les Frais sont redevables tous les mois à l’avance (i) à la date
          à laquelle vous vous êtes abonné aux Services (et tous les mois par la
          suite le même jour chaque mois) ou (ii) le dernier jour du mois ; et
          <Spacer height="20px" />
          9.6.2 Nous nous réservons le droit de limiter l’accès à votre compte
          en cas de défaut de paiement de votre part, ou si vous ne communiquez
          pas de coordonnées bancaires valides nous permettant de facturer le
          montant total de tous frais en souffrance et charges redevables ou si
          vous ne nous payez pas toute somme due pour le Service pour quelque
          raison que ce soit. Nous signifierons un préavis de notre intention de
          limiter votre compte en envoyant un courriel à votre adresse e-mail
          enregistrée. Vous ne serez pas en mesure d’accéder à l’ensemble des
          fonctionnalités de votre compte, et si vous n’utilisez pas votre
          compte dans les 9 mois, votre compte sera supprimé.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>
          10 Abonnement mensuel avec engagement d’un an{" "}
        </SemiBoldText>
        <SCSemiBoldText>10.1 Si vous :</SCSemiBoldText>
        <SmallText>
          10.1.1 êtes un nouveau Partenaire et avez souscrit un Forfait mensuel
          avec engagement d’un an.
          <Spacer height="20px" />
          10.1.2 si vous êtes un Partenaire existant et que vous souhaitez
          Modifier votre Forfait conformément à la clause 8, les dispositions de
          la présente clause 10 s’appliqueront alors à votre Contrat.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>10.2</SCSemiBoldText>
        <SmallText>
          Si vous êtes un Partenaire existant et que vous convenez d’opérer un
          transfert vers un autre Forfait ou une Offre groupée, ledit transfert
          prendra immédiatement effet et les Frais redevables par vous eu égard
          à la Période d’abonnement alors en vigueur seront majorés au prorata
          jusqu’à son expiration puis renouvelés conformément à la clause 17.1.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>10.3</SCSemiBoldText>
        <SmallText>
          Vous n’avez pas le droit d’annuler ou d’effectuer une Mise à niveau
          inférieur avant la fin de la Période d’abonnement initiale ou de toute
          Période de renouvellement (selon le cas) à condition de signifier un
          préavis par écrit d’au moins 30 jours de votre intention d’effectuer
          une mise à niveau inférieur ou d’annuler avant la fin de la Période
          d’abonnement initiale ou de toute Période de renouvellement. Toute
          Mise à niveau inférieur de votre Forfait prendra effet à compter de
          l’entrée en vigueur de la Période de renouvellement suivante.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>10.4</SCSemiBoldText>
        <SmallText>
          Pendant la Période d’abonnement initiale et toute Période de
          renouvellement, les Frais de votre Forfait seront redevables par
          mensualités conformément à la clause 9.2.2.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>10.5</SCSemiBoldText>
        <SmallText>
          Nahal se réserve le droit de majorer les Frais du Forfait eu égard à
          toute Période de renouvellement. Nahal vous notifiera toute dite
          majoration des Frais du Forfait au moins 30 jours avant l’entrée en
          vigueur de la Période de renouvellement pertinente. Les Frais du
          Forfait majorés demeureront redevables par mensualités, à compter du
          début de la Période de renouvellement pertinente, à condition que, à
          la réception d’une notification de majoration des Frais du Forfait,
          vous puissiez demander le transfert de votre abonnement vers un autre
          Forfait. Tout dit transfert de votre Forfait est sous réserve du
          consentement de Nahal.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>10.6</SCSemiBoldText>
        <SmallText>
          Si Nahal résilie votre Forfait pour quelque raison que ce soit, nous
          pouvons vous facturer des frais de résiliation anticipée. Les frais de
          résiliation anticipée ne dépasseront pas les frais et charges que vous
          auriez payés pour le restant de la Période d’abonnement initiale ou la
          Période de renouvellement, selon le cas.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>10.7</SCSemiBoldText>
        <SmallText>
          Si, à tout moment pendant la Période d’abonnement initiale ou toute
          Période de renouvellement de votre Forfait, vous : (i) annulez (ou
          tentez d’annuler) votre Forfait ; ou (ii) ne payez pas tout montant dû
          en vertu de votre contrat avec Nahal à la date d’échéance de paiement
          et demeurez en défaut de paiement pendant plus de 30 jours après avoir
          été notifié par écrit d’effectuer ledit paiement, alors, Nahal peut :
          (i) révoquer immédiatement l’ensemble des licences utilisateurs qui
          vous ont été octroyées et/ou vous empêcher d’accéder à nos Services
          sans préavis ; et/ou (ii) résilier le contrat avec effet immédiat.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>10.8</SCSemiBoldText>
        <SmallText>
          Toute Modification de votre Forfait ou Offre groupée ne vous dégagera
          pas de votre obligation de payer le solde des Frais du Forfait pour le
          restant de la Période d’abonnement initiale ou toute Période de
          renouvellement sauf accord contraire par écrit de Nahal.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>11 Droits exclusifs </SemiBoldText>
        <SCSemiBoldText>11.1</SCSemiBoldText>
        <SmallText>
          Vous reconnaissez et convenez que Nahal et/ou ses concédants de
          licence détiennent l’ensemble des droits de propriété intellectuelle
          afférents aux Services et à la Documentation. Sauf indication expresse
          dans les présentes, le présent Contrat ne vous octroie aucun droit
          afférent à, en vertu de ou dans, tout(e) brevet, droit d’auteur, droit
          de base de données, secret des affaires, dénomination commerciale,
          marque de commerce (enregistré(e) ou non enregistré(e)), ni aucun(e)
          autre droit ou licence eu égard aux Services ou à la Documentation.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>11.2</SCSemiBoldText>
        <SmallText>
          Nous confirmons avoir tous les droits en relation avec les Services et
          la Documentation qui sont nécessaires pour octroyer l’ensemble des
          droits que nous sommes censés octroyer en vertu des conditions du
          présent Contrat et conformément à ces dernières.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>12 Services consultatifs </SemiBoldText>
        <SmallText>
          Nous pouvons, de temps à autre, convenir de vous fournir certains
          services consultatifs, y compris, mais sans limitation, des conseils
          et une assistance en matière de formation et de marketing ainsi que de
          productivité d’équipe (« <b> Services consultatifs </b> »). La
          fourniture de tout dit Service consultatif fera l’objet d’un accord de
          services consultatifs séparé décrivant les services que nous convenons
          de fournir, le calendrier de leur exécution, les frais, les conditions
          de paiement et toute question apparentée.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>13 Confidentialité </SemiBoldText>
        <SCSemiBoldText>13.1</SCSemiBoldText>
        <SmallText>
          Aucune des parties ne divulguera des Informations confidentielles de
          l’autre partie à un tiers, à ceci près que chaque partie peut
          divulguer des Informations confidentielles de l’autre partie :
          <Spacer height="20px" />
          13.1.1 à ses employés, membres du bureau, représentants, prestataires
          de services, sous-traitants ou conseillers qui ont besoin de connaître
          lesdites informations dans le but d’exécuter les obligations de la
          partie en vertu des présentes Conditions, à condition, sans exception,
          que lesdits destinataires soient informés de la nature confidentielle
          des Informations confidentielles qu’ils reçoivent et acceptent des
          engagements de confidentialité raisonnables pour protéger lesdites
          Informations confidentielles ;
          <Spacer height="20px" />
          13.1.2 ainsi que la loi, un tribunal compétent ou toute autorité
          gouvernementale ou de réglementation peut l’exiger ; ou
          <Spacer height="20px" />
          13.1.3 ainsi qu’il est décrit dans notre politique de confidentialité
          ou en vertu de la clause 15.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>13.2</SCSemiBoldText>
        <SmallText>
          Aucune des parties n’utilisera des Informations confidentielles de
          l’autre partie à toute fin autre que l’exécution de ses obligations en
          vertu des présentes Conditions et vous vous engagez, à notre demande
          par écrit ou à la résiliation du présent Contrat, à :
          <Spacer height="20px" />
          13.2.1 cesser d’utiliser nos Informations confidentielles ; et
          <Spacer height="20px" />
          13.2.2 dans les plus brefs délais raisonnables, nous retourner ou
          détruire de manière sécurisée (ou eu égard à des informations détenues
          par voie électronique, supprimer définitivement (dans la mesure
          techniquement réalisable)) l’ensemble de nos Informations
          confidentielles en votre possession.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>13.3</SCSemiBoldText>
        <SmallText>
          Vous reconnaissez que les détails des Services, et les résultats de
          tout test de performance des Services, constituent nos Informations
          confidentielles.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>13.4</SCSemiBoldText>
        <SmallText>
          Nous reconnaissons que les Données Clients sont vos Informations
          confidentielles.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>13.5</SCSemiBoldText>
        <SmallText>
          13.5 Les dispositions ci-dessus de la présente clause 13 survivront à
          la résiliation du présent Contrat, quelle qu’en soit la cause.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>14 Données Clients </SemiBoldText>
        <SCSemiBoldText>14.1</SCSemiBoldText>
        <SmallText>
          Vous détiendrez l’ensemble des droits, titres de propriété et intérêts
          dans et afférents à l’ensemble des Données Clients et serez
          responsable de la légalité, la fiabilité, l’intégrité, l’exactitude et
          la qualité de l’ensemble desdites Données Clients. Vous consentez à ce
          que nous utilisions et à ce que notre prestataire d’hébergement
          utilise les Données Clients à des fins de fourniture des Services.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>14.2</SCSemiBoldText>
        <SmallText>
          Vous nous indemnisez contre tout(e) perte ou dommage que nous
          encourons suite à, ou en relation avec, une réclamation de tout tiers
          alléguant que l’une quelconque des Données Clients enfreint ou
          détourne les droits de propriété intellectuelle dudit tiers et nous
          paierez dans les plus brefs délais le montant de tout(e) décision
          défavorable ou règlement ainsi que nos frais de justice raisonnables
          en relation avec ladite réclamation.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>14.3</SCSemiBoldText>
        <SmallText>
          En cas de perte ou détérioration de Données Clients, votre voie de
          recours unique et exclusive à notre encontre sera pour nous de
          déployer des efforts commerciaux raisonnables pour restaurer les
          Données Clients perdues ou détériorées à partir de la dernière
          sauvegarde desdites Données Clients que nous conservons. Nous
          déclinons toute responsabilité en cas de perte, destruction,
          altération ou divulgation de Données Clients causée par tout tiers (à
          l’exception des tiers que nous sous-traitons pour exécuter des
          services apparentés à la maintenance et la sauvegarde des Données
          Clients).
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>15 Protection des données</SemiBoldText>
        <SCSemiBoldText>15.1</SCSemiBoldText>
        <SmallText>
          À la présente clause 15 :
          <Spacer height="20px" />
          15.1.1 « <b> Législation relative à la Protection des Données </b> »
          désignera la Loi de 2018 relative à la Protection des Données et le
          Règlement Général sur la Protection des Données (UE) 2016/679 (selon
          le cas) et toute autre loi applicable se rapportant à la protection
          des données à caractère personnel et la protection de la vie privée
          des personnes (tous tels que modifiés, mis à jour ou adoptés de
          nouveau de temps à autre) ; et
          <Spacer height="20px" />
          15.1.2 « <b> Données à caractère personnel </b> », «{" "}
          <b> Responsable du traitement des données </b> », «{" "}
          <b> Sous-traitant du traitement des données </b> », «{" "}
          <b> Personne concernée </b> » et « <b> Traitement </b> » auront la
          même signification que dans la Législation relative à la Protection
          des Données.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>15.2</SCSemiBoldText>
        <SmallText>
          Les parties reconnaissent que nous sommes un Sous-traitant du
          traitement des données, agissant pour votre compte et que, aux fins du
          présent Contrat :
          <Spacer height="20px" />
          15.2.1 les types de Données à caractère personnel sont : noms,
          coordonnées et autres informations à caractère personnel sur des
          factures ou reçus téléchargé(e)s ou tout autre élément qui est ajouté
          ou nous est soumis, et les catégories de Personnes concernées sont :
          votre Personnel et le Personnel de vos Clients Partenaires et toute
          autre personne identifiée dans des documents que vous téléchargez («
          Données à caractère personnel ») ; et
          <Spacer height="20px" />
          15.2.2 la nature/les fins du Traitement sont de nous permettre
          d’exécuter les Services (qui constituent l’objet du Traitement), ou de
          fournir des services d’analyse ainsi que vous le demandez, et la durée
          du Traitement sera la durée du présent Contrat.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>15.3</SCSemiBoldText>
        <SmallText>
          Nous agirons également en qualité de Responsable du traitement des
          données eu égard à notre utilisation de données à caractère personnel
          se rapportant à votre Personnel et au Personnel de vos Clients
          Partenaires.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>15.4</SCSemiBoldText>
        <SmallText>
          Nous nous conformerons à nos obligations en vertu de la Législation
          relative à la Protection des Données eu égard aux Données à caractère
          personnel et, lorsque nous sommes votre Sous-traitant du traitement
          des données, nous :
          <Spacer height="20px" />
          15.4.1 Traiterons les Données à caractère personnel uniquement dans la
          mesure, et de la manière, nécessaire aux fins de la fourniture des
          Services et conformément à vos instructions écrites et à la présente
          clause 15 ;
          <Spacer height="20px" />
          15.4.2 mettrons en œuvre des mesures techniques et organisationnelles
          appropriées conformément à la Législation relative à la Protection des
          Données pour garantir un niveau de sécurité correspondant aux risques
          que comporte ledit Traitement ;
          <Spacer height="20px" />
          15.4.3 nous assurerons que tout employé ou toute autre personne
          autorisé(e) à Traiter les Données à caractère personnel soit soumis(e)
          à des obligations de confidentialité appropriées ;
          <Spacer height="20px" />
          15.4.4 à votre demande et en prenant en compte la nature du Traitement
          et des informations dont nous disposons, vous aiderons à garantir le
          respect de nos obligations en vertu des Articles 32 à 36 du Règlement
          Général sur la Protection des Données (UE) 2016/679 (le cas échéant)
          eu égard aux Données à caractère personnel des Partenaires ;
          <Spacer height="20px" />
          15.4.5 obtiendrons au moyen d’un contrat par écrit que tout
          sous-traitant ultérieur du traitement des données qu’il nomme pour
          exécuter ses obligations de traitement en vertu du présent Contrat
          soit, à tous moments pendant la mission, soumis à des obligations de
          traitement des données équivalentes à celles énoncées à la présente
          clause 15 ;
          <Spacer height="20px" />
          15.4.6 n’engagerons aucun tiers pour exécuter ses obligations de
          traitement en vertu du présent Contrat sans obtenir votre consentement
          préalable par écrit, sauf que vous consentez par les présentes à ce
          que nous fassions appel aux sous-traitants ultérieurs du traitement
          suivants : (i) les membres de notre groupe ; et (ii) les prestataires
          de services que nous nommons (dont une liste est disponible sur
          demande) ;
          <Spacer height="20px" />
          15.4.7 vous notifierons, dans les plus brefs délais raisonnables,
          toute requête ou réclamation reçue de la part d’une Personne concernée
          (sans répondre à ladite requête, sauf autorisation de votre part) et
          vous prêterons assistance au moyen de mesures techniques et
          organisationnelles, autant que possible, pour vous permettre de vous
          acquitter de vos obligations eu égard auxdites requêtes et
          réclamations ;
          <Spacer height="20px" />
          15.4.8 vous notifierons sans délai indu dès que nous prendrons
          connaissance d’une violation de Données à caractère personnel ;
          <Spacer height="20px" />
          15.4.9 à votre demande, mettrons à disposition l’ensemble des
          informations nécessaires pour attester de notre respect de la présente
          clause 14 et, sur préavis raisonnable par écrit, permettrons autrement
          les, et contribuerons aux, vérifications que vous conduisez (ou que
          votre représentant autorisé conduit) eu égard aux Données à caractère
          personnel, à condition que vous vous engagiez à (ou que vous vous
          assuriez que vos représentants autorisés s’engagent à) :
          <Spacer height="20px" />
          (i) signer un accord de non-divulgation selon des conditions que nous
          jugeons acceptables avant de conduire ladite vérification ;
          <Spacer height="20px" />
          (ii) être accompagnés par un membre de notre Personnel à tous moments
          pendant votre/leur présence sur le site durant la vérification ;
          <Spacer height="20px" />
          (iii) déployer des efforts raisonnables pour garantir que la conduite
          de toute dite vérification ne perturbe pas déraisonnablement nos
          activités commerciales habituelles ; et
          <Spacer height="20px" />
          (iv) respecter nos politiques informatiques et de sécurité pertinentes
          pendant la conduite de toute dite vérification.
          <Spacer height="20px" />
          15.4.10 à la résiliation ou l’expiration du présent Contrat,
          détruirons, supprimerons ou retournerons (selon vos instructions)
          l’ensemble des Données à caractère personnel et supprimerons
          l’ensemble des copies existantes desdites données à moins que la loi
          n’exige de conserver ou stocker lesdites Données à caractère
          personnel.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>15.5</SCSemiBoldText>
        <SmallText>
          Vous reconnaissez que la clause 15.4.1 ne s’appliquera pas dans la
          mesure où nous sommes tenus par la loi de Traiter les Données à
          caractère personnel autrement que conformément à vos instructions et
          nous reconnaissons que, dans ledit cas, nous devons vous informer dans
          les plus brefs délais de l’exigence légale pertinente avant le
          Traitement (à moins que la loi n’interdise la fourniture desdites
          informations pour des raisons importantes d’intérêt public).
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>16 Limitation de responsabilité </SemiBoldText>
        <SCSemiBoldText>16.1</SCSemiBoldText>
        <SmallText>
          Sauf ainsi qu’il est expressément et spécifiquement stipulé dans le
          présent Contrat :
          <Spacer height="20px" />
          16.1.1 vous assumez la responsabilité exclusive des résultats obtenus
          suite à votre utilisation des Services et de la Documentation, et des
          conclusions tirées de ladite utilisation. Nous déclinons toute
          responsabilité quant à : (i) tout conseil que vous fournissez à des
          Clients Partenaires ou toute perte ou décision que vous subissez ou
          prenez ou que vos Clients Partenaires subissent ou prennent ; et nous
          déclinons toute responsabilité quant à tout(e) perte ou dommage
          occasionné(e) par lesdits conseils fournis ou lesdites décisions
          prises ; et
          <Spacer height="20px" />
          16.1.2 l’ensemble des garanties, déclarations, conditions et
          l’ensemble des autres modalités de quelque nature que ce soit
          présumées par la loi ou le droit commun sont, dans la pleine mesure
          permise par la loi applicable, exclues du présent Contrat.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>16.2</SCSemiBoldText>
        <SmallText>
          Aucune disposition du présent Contrat n’exclut notre responsabilité :
          <Spacer height="20px" />
          16.2.1 quant à tout décès ou préjudice corporel résultant de notre
          négligence ; ou
          <Spacer height="20px" />
          16.2.2 quant à toute fraude ou assertion inexacte et frauduleuse.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>16.3</SCSemiBoldText>
        <SmallText>
          Nous ne serons en aucun cas tenus responsables qu’il s’agisse d’une
          responsabilité délictuelle (négligence ou violation d’une obligation
          d’origine législative comprise), d’une responsabilité contractuelle,
          d’une assertion inexacte, d’une restitution ou autrement quant à toute
          perte de bénéfices, perte d’activités, perte d’opportunités, réduction
          du fonds de commerce et/ou pertes similaires ou perte ou corruption de
          données ou d’informations, ou pure perte économique, ou quant à
          tout(e) perte, coût, dommage, charge ou dépense
          particulier/particulière, indirect(e) ou consécutif/consécutive, dans
          chaque cas, quelle qu’en soit la cause en vertu du présent Contrat.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>16.4</SCSemiBoldText>
        <SmallText>
          Sous réserve des clauses 16.1, 16.2 et 16.3, notre responsabilité
          globale totale, qu’il s’agisse d’une responsabilité contractuelle,
          délictuelle (négligence ou violation d’une obligation d’origine
          législative comprise), d’une assertion inexacte, d’une restitution ou
          autre, découlant en relation avec l’exécution ou l’exécution envisagée
          du présent Contrat sera limitée pendant la Période d’abonnement
          initiale et chaque Période de renouvellement au total des Frais dont
          vous êtes redevable au cours de cette période pertinente.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>17 Durée et résiliation </SemiBoldText>
        <SCSemiBoldText>17.1</SCSemiBoldText>
        <SmallText>
          Le présent Contrat entrera en vigueur à la Date d’entrée en vigueur
          et, sauf résiliation anticipée conformément à la présente clause 17,
          se poursuivra pendant la Période d’abonnement initiale et, par la
          suite, le Contrat sera automatiquement renouvelé de périodes
          consécutives (chacune une « Période de renouvellement »), voir la
          période applicable dans le tableau ci-dessous), à moins que :
          <Spacer height="20px" />
          17.1.1 si vous êtes un Partenaire ou un Client, l’une ou l’autre des
          parties ne notifie à l’autre partie une résiliation, par écrit, avec
          préavis (voir le tableau ci-dessous pour le délai de préavis requis
          minimum applicable) avant la fin de la Période d’abonnement initiale
          ou de toute Période de renouvellement, ou
          <Spacer height="20px" />
          17.1.2 si vous êtes un Client uniquement, vous n’annuliez le
          renouvellement automatique de l’abonnement à votre Forfait en appuyant
          sur le bouton « Annuler l’abonnement » dans l’onglet Mon Profil de
          votre compte ; ou
          <Spacer height="20px" />
          17.1.3 le Contrat ne soit autrement résilié conformément aux
          dispositions des présentes Conditions.
        </SmallText>
        <Spacer height="20px" />
        <Table style={{ border: "1px solid black collapse" }}>
          <thead>
            <tr>
              <td></td>
              <ColTitle colSpan={3}>
                {" "}
                <SemiBoldText>Type d'abonné </SemiBoldText>
              </ColTitle>
            </tr>
            <tr>
              <td></td>
              <ColTitle>
                <SCSemiBoldText>Abonné mensuel</SCSemiBoldText>
              </ColTitle>
              <ColTitle>
                <SCSemiBoldText>Abonné annuel</SCSemiBoldText>
              </ColTitle>
              <ColTitle>
                <SCSemiBoldText>Abonné ACPM</SCSemiBoldText>
              </ColTitle>
            </tr>
          </thead>
          <tbody>
            <tr>
              <ColTitle>
                <SCSemiBoldText>Periode de renouvellement</SCSemiBoldText>
              </ColTitle>
              <TableCase>
                <SmallText>1 mois</SmallText>
              </TableCase>
              <TableCase>
                <SmallText>12 mois</SmallText>
              </TableCase>
              <TableCase>
                <SmallText>12 mois</SmallText>
              </TableCase>
            </tr>
            <tr>
              <ColTitle>
                <SCSemiBoldText>Délai de préavis</SCSemiBoldText>
              </ColTitle>
              <TableCase>
                <SmallText>14 jours</SmallText>
              </TableCase>
              <TableCase>
                <SmallText>30 jours</SmallText>
              </TableCase>
              <TableCase>
                <SmallText>30 jours</SmallText>
              </TableCase>
            </tr>
          </tbody>
        </Table>
        <Spacer height="50px" />
        <SCSemiBoldText>17.2</SCSemiBoldText>
        <SmallText>
          Si vous avez souscrit un Forfait uniquement (par opposition à une
          souscription à un Forfait ou une Offre groupée qui comprend d’autres
          services et fonctionnalités, Options ou autres Services Nahal), alors,
          outre les clauses 9.1 à 9.6 précédentes, ce qui suit s’appliquera :
          <Spacer height="20px" />
          17.2.1 Si vous êtes un Abonné mensuel uniquement, vous pouvez annuler
          votre compte à tout moment en cliquant sur le lien « Annuler le Compte
          » depuis la page paramètres de votre compte sur la plate-forme en
          ligne Nahal. Votre compte et toute licence Utilisateur associée à ce
          dernier demeureront actifs pendant le restant de votre période de
          facturation en cours ;
          <Spacer height="20px" />
          17.2.2 Nous nous réservons le droit d’annuler votre abonnement et de
          supprimer votre compte si ni vous, ni l’un quelconque de vos
          Utilisateurs autorisés, n’avez accédé à votre compte Partenaire ni
          utilisé Nahal pendant au moins 3 mois consécutifs sans préavis. Chacun
          de vos Clients Partenaires sera considéré comme un compte indépendant
          et séparé aux fins de calcul de la période inactive.
          <Spacer height="20px" />
          17.2.3 Si votre Contrat est résilié ou annulé, ni vous, ni vos
          Utilisateurs autorisés, ne pourrez accéder à votre compte. Pendant une
          période de 10 jours à compter de la date de résiliation ou
          d’annulation, vous pouvez nous demander par écrit de rétablir votre
          compte, sous réserve que vous prolongiez votre abonnement à nos
          Services selon les conditions antérieures convenues entre les parties.
          Si nous ne recevons pas une dite notification de demande de votre part
          avant l’expiration de ladite période, votre compte et l’ensemble des
          données qu’il contient peuvent être supprimés à titre permanent sans
          autre préavis.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>17.3</SCSemiBoldText>
        <SmallText>
          Nous nous réservons le droit de cesser de vous fournir et/ou de
          fournir aux Utilisateurs Partenaires (si vous êtes un Partenaire) les
          Services et/ou la Documentation de temps à autre à notre discrétion
          exclusive.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>17.4</SCSemiBoldText>
        <SmallText>
          Sans préjudice de tout(e) autre droit ou voie de recours dont nous
          disposons :
          <Spacer height="20px" />
          17.4.1 nous pouvons résilier le présent Contrat avec effet immédiat en
          vous signifiant une notification par écrit si :
          <Spacer height="30px" />
          (i) un changement de Contrôle est opéré ;
          <Spacer height="20px" />
          (ii) un changement de Forfait ou d’Offre groupée est opéré ; ou si
          <Spacer height="20px" />
          (iii) vous choisissez de passer du statut d’Abonné mensuel à celui
          d’Abonné annuel.
          <Spacer height="20px" />
          17.4.2 Nous pouvons résilier le présent Contrat pour quelque raison
          que ce soit en vous signifiant un préavis par écrit d’au moins 30
          jours.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>17.5</SCSemiBoldText>
        <SmallText>
          Sans préjudice de tout(e) autre droit ou voie de recours dont elle
          dispose, l’une ou l’autre des parties peut résilier le présent Contrat
          avec effet immédiat en signifiant une notification par écrit à l’autre
          partie si :
          <Spacer height="20px" />
          17.5.1 l’autre partie ne paye pas tout montant dû en vertu du présent
          Contrat à la date d’échéance de paiement et demeure en défaut de
          paiement pendant au moins 30 jours après avoir été notifiée par écrit
          d’effectuer ledit paiement ;
          <Spacer height="20px" />
          17.5.2 l’autre partie commet une violation substantielle de toute
          autre condition du présent Contrat, ladite violation étant
          irrémédiable, ou (s’il peut être remédié à ladite violation) ne
          remédie pas à ladite violation dans un délai de 30 jours après en
          avoir été notifiée par écrit ;
          <Spacer height="20px" />
          17.5.3 l’autre partie est en violation répétée de l’une quelconque des
          conditions du présent Contrat d’une manière à justifier
          raisonnablement l’opinion selon laquelle sa conduite est en
          contradiction avec son intention de ou sa capacité à donner effet aux
          conditions du présent Contrat ;
          <Spacer height="20px" />
          17.5.4 l’autre partie suspend, ou menace de suspendre, le paiement de
          ses dettes ou n’est pas en mesure de payer ses dettes à leur échéance
          ou admet son incapacité à payer ses dettes ou est réputée ne pas être
          en mesure de payer ses dettes au sens de l’article 123 de la Loi de
          1986 relative à l’Insolvabilité, comme si les termes « il est prouvé à
          la satisfaction du tribunal » ne figuraient pas aux articles 123(1)(e)
          ou 123(2) de la Loi de 1986 relative à l’Insolvabilité ;
          <Spacer height="20px" />
          17.5.5 l’autre partie entame des négociations avec l’ensemble ou toute
          catégorie de ses créanciers dans le but de rééchelonner l’une
          quelconque de ses dettes, ou soumet une proposition de ou conclut tout
          compromis ou concordat avec ses créanciers autrement qu’aux seules
          fins d’un arrangement en vue d’une fusion solvable de ladite autre
          partie avec une ou plusieurs autres sociétés ou de la reconstitution
          solvable de ladite autre partie ;
          <Spacer height="20px" />
          17.5.6 une requête est présentée, une notification est signifiée, une
          résolution est adoptée, ou une ordonnance est prononcée, pour ou en
          relation avec la liquidation de ladite autre partie autrement qu’aux
          seules fins d’un arrangement en vue d’une fusion solvable de ladite
          autre partie avec une ou plusieurs autres sociétés ou de la
          reconstitution solvable de ladite autre partie ;
          <Spacer height="20px" />
          17.5.7 une demande est formulée auprès d’un tribunal, ou une
          ordonnance est prononcée, pour la nomination d’un administrateur
          judiciaire, ou si une notification de l’intention de nommer un
          administrateur judiciaire est signifiée ou si un administrateur
          judiciaire est nommé, eu égard à l’autre partie ;
          <Spacer height="20px" />
          17.5.8 le détenteur d’une charge flottante admissible sur les actifs
          de ladite autre partie est alors habilité à nommer ou a nommé un
          syndic de faillite ;
          <Spacer height="20px" />
          17.5.9 une personne est alors habilitée à nommer un syndic de faillite
          sur les actifs de l’autre partie ou un syndic de faillite est nommé
          sur les actifs de l’autre partie ;
          <Spacer height="20px" />
          17.5.10 un créancier ou un bénéficiaire de charge de l’autre partie
          grève ou prend possession de, ou une procédure de saisie, d’exécution,
          de séquestre ou autre dite procédure est imposée ou appliquée ou
          intentée à l’encontre de, la totalité ou toute partie des actifs de
          l’autre partie et ledit grèvement ou ladite procédure n’est pas
          annulé(e) dans les 14 jours ; et
          <Spacer height="20px" />
          17.5.11 tout événement se produit, ou une procédure est entamée, eu
          égard à l’autre partie au sein de toute juridiction à laquelle elle
          est soumise, ayant un effet équivalent ou similaire à l’un quelconque
          des événements cités aux clauses 17.11.1 à 17.11.10 (comprise) ;
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>17.6</SCSemiBoldText>
        <SmallText>
          À la résiliation du présent Contrat pour quelque raison que ce soit :
          <Spacer height="20px" />
          17.6.1 nous pouvons toujours vous donner accès à nos Services et/ou à
          la Documentation à condition que :
          <Spacer height="30px" />
          (i) vous utilisiez les Services et/ou la Documentation en vous
          conformant strictement aux conditions du présent Contrat (à ceci près
          que vous ne serez plus dans l’obligation de payer tous Frais) ;
          <Spacer height="20px" />
          (ii) vous accédiez aux et utilisiez les Services entièrement à vos
          propres risques et que nous ne soyons par conséquent pas tenus
          responsables quant à tout(e) réclamation, dommage ou autre
          responsabilité découlant de ou en relation avec votre utilisation des
          Services ;
          <Spacer height="20px" />
          (iii) les Services et la Documentation vous soient fournis « tels
          quels » ; et
          <Spacer height="20px" />
          (iv) nous nous réservons le droit de révoquer immédiatement l’ensemble
          des licences octroyées en vertu du présent Contrat en vous empêchant
          d’accéder à nos Services sans préavis ;
          <Spacer height="20px" />
          17.6.2 nous pouvons détruire ou mettre autrement au rebut l’une
          quelconque des Données Clients en notre possession, à moins que nous
          ne recevions, au plus tard dix jours après la date de résiliation du
          présent Contrat, une demande par écrit de vous remettre la sauvegarde
          alors la plus récente des Données Clients. Nous déploierons des
          efforts commerciaux raisonnables pour vous remettre la sauvegarde dans
          les 30 jours suivant la réception de ladite demande par écrit, à
          condition que vous ayez alors payé l’ensemble des frais et charges en
          souffrance à la résiliation et résultant de cette dernière (qu’ils
          soient dus ou non à la date de résiliation). Vous payerez l’ensemble
          des dépenses raisonnables que nous encourrons pour retourner ou mettre
          au rebut les Données Clients ; et
          <Spacer height="20px" />
          17.6.3 il ne sera porté atteinte ou préjudice à aucun(e) droit, voie
          de recours, obligation ou responsabilité des parties qui a été
          acquis(e) jusqu’à la date de résiliation, y compris le droit de
          réclamer des dommages et intérêts eu égard à toute violation du
          Contrat qui existait à ou avant la date de résiliation.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>18 Généralités </SemiBoldText>
        <SCSemiBoldText>18.1 Force majeure : </SCSemiBoldText>
        <SmallText>
          Nous ne serons pas tenus responsables si l’exécution de nos
          obligations en vertu du présent Contrat, ou la conduite de nos
          activités, est empêchée ou retardée par tout(e) acte, événement,
          omission ou accident indépendant(e) de notre volonté raisonnable, y
          compris, sans limitation, mesure gouvernementale, incendie,
          inondations, insurrection, séisme, épidémie ou pandémie, coupure de
          courant, émeutes, acte de terrorisme, guerre, explosion, embargo,
          grève, pénurie de main-d’œuvre ou de matériaux, interruption des
          transports de quelque nature que ce soit, ralentissement du travail,
          panne d’un service public ou d’un réseau de télécommunications ou
          défaillance de fournisseurs ou sous-traitants.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>18.2 Accord indivisible :</SCSemiBoldText>
        <SmallText>
          Les présentes Conditions, ainsi que tout(e) page Internet, document ou
          politique incorporé(e) aux présentes Conditions par référence,
          constituent l’accord et l’entente indivisibles entre vous et nous
          concernant les objets visés par les présentes Conditions et remplacent
          et éteignent l’ensemble des accords antérieurs (le cas échéant et par
          écrit ou non) entre vous et nous eu égard auxdits objets. Les parties
          reconnaissent et conviennent que, sauf stipulation expresse contraire
          dans les présentes Conditions, elles ne concluent les présentes
          Conditions sur la base d’aucune, et ne se fient à et ne se sont fiées
          à aucune, affirmation, déclaration, garantie ou autre disposition
          (qu’elle soit dans tous les cas verbale, écrite, explicite ou
          implicite) faite, soumise ou convenue par toute personne (qu’il
          s’agisse ou non d’une partie aux présentes Conditions) en relation
          avec l’objet des présentes Conditions, à condition qu’aucune
          disposition des présentes Conditions n’exclue la responsabilité de
          toute partie quant à toute fraude ou assertion inexacte frauduleuse.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>18.3 Révision des conditions :</SCSemiBoldText>
        <SmallText>
          Nous nous réservons le droit de réviser les conditions du présent
          Contrat en mettant à jour les Conditions sur notre site Internet. Nous
          vous conseillons de consulter régulièrement le site Internet pour
          prendre connaissance de toute notification concernant lesdites
          révisions. Votre utilisation continue des Services vaudra acceptation
          de toute condition révisée.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>18.4 Cession :</SCSemiBoldText>
        <SmallText>
          {" "}
          Vous vous engagez à ne pas céder, transférer, sous-traiter un(e)
          quelconque droit ou obligation en vertu des présentes Conditions sans
          notre consentement préalable par écrit (ledit consentement ne devant
          pas être déraisonnablement refusé) et toute cession non autorisée sera
          nulle et non avenue. Nous pouvons à tout moment céder, transférer,
          grever, sous-traiter ou négocier de toute autre manière l’ensemble ou
          l’un(e) quelconque de nos droits ou obligations en vertu du présent
          Contrat. Nous demeurerons responsable de l’exécution par nos
          sous-traitants.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>18.5 Notifications :</SCSemiBoldText>
        <SmallText>
          Les notifications à notre attention doivent être envoyées à
          <Link to=""> support@nahal.io</Link> , ou dans les cas à toute autre
          adresse e-mail que nous vous notifions. Nous vous enverrons les
          notifications à l’adresse e-mail alors en vigueur sur votre compte.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>18.6 Renonciation :</SCSemiBoldText>
        <SmallText>
          {" "}
          Sauf stipulation contraire dans les présentes Conditions, une
          renonciation à tout(e) droit ou voie de recours en vertu des présentes
          Conditions ou de par la loi ne prend effet que si elle est signifiée
          par écrit et elle ne sera pas réputée constituer une renonciation à
          tout(e) violation ou manquement ultérieur(e). Aucun manquement ou
          retard de la part d’une partie dans l’exercice de tout(e) droit ou
          voie de recours prévu(e) en vertu des présentes Conditions ou par la
          loi ne constituera une renonciation audit droit ou à ladite voie de
          recours ou à tout(e) autre droit ou voie de recours, et n’empêchera ou
          ne restreindra tout exercice ultérieur dudit droit ou de ladite voie
          de recours ou de tout(e) autre droit ou voie de recours. Aucun
          exercice unique ou partiel de tout(e) droit ou voie de recours
          prévu(e) en vertu des présentes Conditions ou par la loi n’empêchera
          ou ne restreindra l’exercice ultérieur dudit droit ou de ladite voie
          de recours ou de tout(e) autre droit ou voie de recours.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>18.7 Autonomie des dispositions :</SCSemiBoldText>
        <SmallText>
          Si toute disposition ou disposition partielle des présentes Conditions
          est déclarée invalide, illégale, nulle et non avenue ou inapplicable,
          elle sera réputée être modifiée dans la mesure minimum nécessaire pour
          la rendre valide, légale et applicable. Si ladite modification est
          impossible, la disposition ou disposition partielle pertinente sera
          réputée être supprimée. Aucune modification ou suppression d’une
          disposition ou disposition partielle en vertu de la présente clause
          n’affectera la validité et l’applicabilité des dispositions restantes
          des présentes Conditions.
        </SmallText>{" "}
        <Spacer height="20px" />
        <SCSemiBoldText>
          18.8 Aucune relation de partenariat ni mandant-mandataire :
        </SCSemiBoldText>
        <SmallText>
          Aucune disposition des présentes Conditions n’est destinée à ou n’aura
          pour effet de créer une relation de partenariat entre les parties, ou
          autoriser l’une ou l’autre des parties à agir en qualité de mandataire
          pour l’autre partie, et aucune des parties ne sera
          <Spacer height="20px" />
          habilitée à agir au nom de ou pour le compte de l’autre partie ou à
          lier autrement l’autre partie de quelque manière que ce soit (y
          compris, sans limitation, la soumission de toute déclaration ou
          garantie, la prise en charge de toute obligation ou responsabilité et
          l’exercice de tout droit ou pouvoir).
        </SmallText>{" "}
        <Spacer height="20px" />
        <SCSemiBoldText>18.9 Droits de tiers :</SCSemiBoldText>
        <SmallText>
          Les présentes Conditions ne confèrent aucun droit à toute personne ou
          partie (autre que les parties au présent Contrat et, le cas échéant,
          leurs successeurs et ayants droit autorisés).
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>18.10 Droit applicable :</SCSemiBoldText>
        <SmallText>
          Le présent Contrat et tout(e) litige ou réclamation découlant de ou se
          rapportant à ce dernier ou son objet ou sa constitution (y compris
          litiges ou réclamations non contractuel(le)s) seront régis par et
          interprétés conformément à la législation française.
        </SmallText>
        <Spacer height="20px" />
        <SCSemiBoldText>18.11 Compétence :</SCSemiBoldText>
        <SmallText>
          Chaque partie convient irrévocablement que les tribunaux Français
          auront la compétence exclusive pour régler tout(e) litige ou
          réclamation découlant du ou se rapportant au présent Contrat ou son
          objet ou sa constitution (y compris litiges ou réclamations non
          contractuel(le)s).
        </SmallText>
        <Spacer height="50px" />
        <AnnexeTitle>Annexe 1 Démos et Essais gratuits </AnnexeTitle>
        <SmallText>
          <b>1. </b> <br />
          Nous vous octroyons un droit limité, non-exclusif et incessible pour
          accéder aux et utiliser les Services et la Documentation pendant la
          Démo ou l’Essai gratuit. Nous nous réservons le droit de révoquer la
          licence octroyée en vertu du présent paragraphe 1, et de résilier le
          présent Contrat, à tout moment et sans préavis, en vous empêchant
          d’accéder à nos Services.
          <Spacer height="20px" />
          <b>2. </b> <br />
          Vous utiliserez les Services et/ou la Documentation pendant une Démo
          ou un Essai gratuit en vous conformant strictement aux conditions du
          présent Contrat (à ceci près que les clauses 5, 6.1 et 17 ne
          s’appliqueront pas et sauf accord contraire aucun Frais ne sera
          redevable eu égard à ladite Démo ou audit Essai gratuit en vertu de la
          clause 9).
          <Spacer height="20px" />
          <b>3. </b> <br />
          Vous n’utiliserez pas ou ne tenterez pas d’utiliser les Services
          accessibles dans le cadre de la Démo à des fins de traitement de reçus
          concernant votre propre entreprise.
          <Spacer height="20px" />
          <b>4. </b> <br />
          Vous ne tenterez pas de contourner toute limitation imposée eu égard
          au Service pendant tout(e) Démo ou Essai gratuit.
          <Spacer height="20px" />
          <b>5. </b> <br />
          Pendant la Démo ou l’Essai gratuit, vous convenez que les Services
          et/ou la Documentation sont fournis TELS QUELS sans aucune déclaration
          ou garantie de quelque nature que ce soit quant à leur fonctionnalité,
          qualité, performance, adéquation ou aptitude à l’emploi.
          <Spacer height="20px" />
          <b>6. </b> <br />
          Nous déclinons toute responsabilité quant à tout(e) réclamation,
          dommage, perte ou autre responsabilité découlant de ou en relation
          avec votre utilisation des Services et/ou de la Documentation pendant
          la Démo ou l’Essai gratuit. Pour éviter toute confusion, pendant la
          Démo ou l’Essai gratuit, le présent paragraphe s’appliquera à la place
          de la clause 16.4.
        </SmallText>
        <Spacer height="50px" />
        <AnnexeTitle>
          Annexe 2 - Conditions Générales relatives aux fonctionnalités Nahal{" "}
        </AnnexeTitle>
        <SCSemiBoldText>1 Applicabilité</SCSemiBoldText>
        <SmallText>
          <b>1.1. </b> <br />
          Les présentes Conditions Générales supplémentaires complètent les
          conditions générales principales de Nahal («
          <b> Conditions Générales principales</b> ») et régissent votre
          utilisation de certaines fonctionnalités du Service. En choisissant de
          recevoir ces fonctionnalités, vous convenez d’être liés par les
          présentes Conditions Générales supplémentaires.
          <Spacer height="30px" />
          <b>1.2. </b> <br />«<b> Fonctionnalités</b> » désigne des Produits
          supplémentaires qui font partie de certain(e)s Forfaits ou Offres
          groupées mais sans qu’aucun frais supplémentaire ne soit facturé pour
          leur utilisation (sauf si Nahal n’en décide autrement). Les présentes
          Conditions Générales supplémentaires s’appliquent à votre utilisation
          des fonctionnalités suivantes proposées par Nahal sur sont site
          internet <Link to=""> www.nahal.io.</Link>
        </SmallText>
        <Spacer height="50px" />
      </SectionContainer>
      <Footer />
    </Container>
  );
};

export default SaleConditions;
