import styled from "styled-components";

interface Props {
  width?: string;
  height?: string;
  className?: string;
}

const StyledSpacer = styled.div<Props>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const Spacer = ({ width = "1px", height = "1px", className }: Props) => {
  return <StyledSpacer width={width} height={height} className={className} />;
};
