import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Spacer } from "../basics/Spacer";
import { SemiBoldText } from "../basics/Typography";
import Footer from "../Footer";
import RectRessource from "./RectRessource";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f4f7;
`;
const Header = styled.div`
  background-image: url("./img/img-article-top.png");
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
  margin: 7vw 8vw 0 8vw;
  min-height: 45vw;
  align-self: stretch;
  @media only screen and (max-width: 900px) {
  }
`;
const ReadMore = styled(SemiBoldText)`
  font-size: 24px;
`;

const Ressources = () => {
  return (
    <Container>
      <Header />
      <RectRessource></RectRessource>
      <Spacer height="50px" />
      <Link to="/RessourcesListe" style={{ textDecoration: "none" }}>
        <ReadMore>À lire aussi…</ReadMore>
        <Spacer height="50px" />
      </Link>
      <Footer />
    </Container>
  );
};

export default Ressources;
