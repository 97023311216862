import React from "react";
import styled from "styled-components";
import {
  SectionSubtitle,
  SectionTitle,
  SemiBoldText,
  SmallText,
} from "../basics/Typography";
import { Spacer } from "../basics/Spacer";
import { SectionFooter } from "../composed/SectionFooter";
import { SectionContainer } from "./HeaderComponents/SectionContainer";

const Container = styled(SectionContainer)`
  background-color: #ffffff;
  @media only screen and (max-width: 900px) {
    align-items: center;
    p {
      text-align: center;
    }
  }
`;
const Block1 = styled.div`
  display: flex;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Relclientimg1 = styled.img`
  width: 40vw;
  margin-bottom: 25px;
  @media only screen and (max-width: 900px) {
    width: calc(81vw - 30px);
    align-self: flex-start;
    margin-bottom: 0;
  }
`;

const Relclientimg2 = styled.img`
  width: 13vw;
  object-fit: contain;
  align-self: flex-start;
  margin: 8vw 0 0 -8vw;
  @media only screen and (max-width: 900px) {
    margin: -37vw 0 20px 0;
    width: calc(33vw - 30px);
    align-self: flex-end;
  }
`;
const Block2 = styled.div`
  display: flex;
  @media only screen and (max-width: 900px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const Relclientimg3 = styled.img`
  width: 50vw;
  object-fit: contain;
  align-self: center;
  @media only screen and (max-width: 900px) {
    width: 100%;
    display: flex;
    margin: 20px 0 30px 0;
  }
`;

const Relclientimg4 = styled.img`
  position: relative;
  z-index: 1;
  width: 50vw;
  object-fit: contain;
  align-self: center;
  @media only screen and (max-width: 900px) {
    width: 100%;
    margin: auto;
    display: flex;
  }
`;

const Relclientimg5 = styled.img`
  position: relative;
  z-index: 2;
  width: 33vw;
  height: auto;
  margin: -18vw 0 0 calc(20vw - 100px);
  object-fit: contain;
  align-self: flex-start;
  @media only screen and (max-width: 900px) {
    width: 100%;
    display: flex;
    margin: 0 0 0 0;
  }
`;

const Block3 = styled.div`
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Propaccept = styled.div`
  align-self: center;
  width: calc(25vw - 100px);
  @media only screen and (max-width: 900px) {
    width: 100%;
    margin: 25px 0 20px 0;
  }
`;
const Cad1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  p {
    margin-left: 30px;
    width: 30vw;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-top: 20px;
    p {
      margin-left: 0;
      width: 100%;
    }
  }
`;

const SideTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(30vw - 100px);
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

const SignTextContainer = styled(SideTextContainer)`
  width: calc(25vw - 100px);
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

const DialogText = ({ className }: { className?: string }) => (
  <SideTextContainer className={className}>
    <SemiBoldText>
      Dialoguez avec votre client en toute simplicité !
    </SemiBoldText>
    <SmallText>
      Sur une application mobile, votre client aura des accès sécurisés afin de
      vous permettre de dialoguer et communiquer toutes les informations
      nécessaires.
    </SmallText>
  </SideTextContainer>
);

const DialogBoxText = ({ className }: { className?: string }) => (
  <>
    <SemiBoldText className={className}>
      Le module "Boite de dialogue" vous permet de créer une procédure derrière
      chaque dialogue !
    </SemiBoldText>
    <SmallText className={className}>
      Anticiper les questions et permettre au client de pouvoir répondre à un
      dialogue comme si vous communiquiez avec lui en direct
    </SmallText>
  </>
);

const GestionRc = () => {
  return (
    <Container>
      <SectionTitle> Gestion de la relation client</SectionTitle>
      <SectionSubtitle>Mon client & Moi</SectionSubtitle>
      <Block1>
        <DialogText />
        <Cad1>
          <Relclientimg1
            src="./img/img-relation-client-1-ipad.png"
            srcSet="./img/img-relation-client-1-ipad@2x.png,
                                ./img/img-relation-client-1-ipad@3x.png"
          />
          <DialogBoxText className="desktop-only" />
        </Cad1>
        <Relclientimg2
          src="img/img-relation-client-2-iphone.png"
          srcSet="img/img-relation-client-2-iphone@2x.png,
                                 img/img-relation-client-2-iphone@3x.png"
          className="imgrelation-client2-iphone"
        />
        <DialogBoxText className="mobile-only" />
      </Block1>
      <Spacer className="desktop-only" height="90px" />
      <Spacer className="desktop-only" height="40px" />
      <Block2>
        <SignTextContainer>
          <SmallText>Proposez à vos clients de </SmallText>
          <SemiBoldText>
            signer des documents directement dans un dialogue formel
          </SemiBoldText>
        </SignTextContainer>
        <Relclientimg3
          src="./img/img-relation-client-3-dk.png"
          srcSet="./img/img-relation-client-3-dk@2x.png,
                                  ./img/img-relation-client-3-dk@3x.png"
        />
      </Block2>
      <Spacer className="desktop-only" height="90px" />
      <Spacer className="desktop-only" height="40px" />
      <Block3>
        <Relclientimg4
          src="./img/img-relation-client-4-dk.png"
          srcSet="./img/img-relation-client-4-dk@2x.png,
                                      ./img/img-relation-client-4-dk@3x.png"
        />
        <Propaccept>
          <SemiBoldText>Proposez/Acceptez des rendez-vous</SemiBoldText>
          <SmallText>
            Permettez à votre client de pouvoir réserver lui-même un rendez-vous
            dont vous avez créé le modèle en amont. Il pourra réserver sur une
            plage horaire à laquelle vous êtes disponible, le module étant
            connecté à votre agenda
          </SmallText>
        </Propaccept>
      </Block3>
      <Relclientimg5
        src="./img/img-relation-client-5-ipad.png"
        srcSet="./img/img-relation-client-5-ipad@2x.png,
                                      ./img/img-relation-client-5-ipad@3x.png"
      />
      <Spacer height="50px" />
      <SectionFooter
        title="Pour en savoir plus"
        subtitle='et découvrir toutes les autres fonctionnalités du module "Mon client et moi", réservez une démonstration'
      />
    </Container>
  );
};

export default GestionRc;
