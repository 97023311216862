import React from "react";
import styled from "styled-components";
import { Spacer } from "./basics/Spacer";
import {
  SectionSubtitle,
  SectionTitle,
  SemiBoldText,
  SmallText,
} from "./basics/Typography";
import { Header } from "./composed/Header";
import Footer from "./Footer";
import { SectionContainer } from "./HomeComponents/HeaderComponents/SectionContainer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f4f7;
`;

const PCSemiBoldText = styled(SemiBoldText)`
  font-size: 15px;
  @media only screen and (max-width: 900px) {
  }
`;

const LemonWayLogo = styled.img.attrs({
  src: "./img/lemonway-logo.png",
})`
  height: 50px;
  object-fit: contain;
  margin-left: 15px;
`;

const LemonWayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PartnerText = styled(SemiBoldText)`
  font-size: 30px;
  margin-bottom: 0;
  color: #565b77;
`;

const PaymentConditions = () => {
  return (
    <Container>
      <Header title="Conditions liées aux moyens de paiement" />
      <SectionContainer>
        <LemonWayContainer>
          <PartnerText>Partenaire </PartnerText>
          <LemonWayLogo />
        </LemonWayContainer>
        <Spacer height="20px" />
        <SectionTitle>
          Conditions Générales d’Utilisation de Services de paiement –
          Contrat-cadre de Services de paiement
        </SectionTitle>
        <SectionSubtitle>Version 2021</SectionSubtitle>
        <Spacer height="50px" />
        <SmallText>
          {" "}
          Le Titulaire d’une part
          <Spacer height="20px" /> et,
          <Spacer height="20px" /> Lemonway, société par actions simplifiée dont
          dont le siège social est situé au 8 rue du Sentier, 75002 Paris,
          France, immatriculée au registre du commerce et des sociétés de Paris
          sous le numéro 500 486 915 (« Lemonway« ), agréée le 24 décembre 2012
          par l’Autorité de Contrôle Prudentiel et de Résolution (« ACPR »,
          France, site internet http://acpr.banque-france.fr/), 4 place de
          Budapest CS 92459, 75436 Paris, en qualité d’Etablissement de Paiement
          hybride, sous le numéro 16 568 J, d’autre part. Désignés
          individuellement une « Partie » et ensemble les « Parties ».
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>AVERTISSEMENT</SemiBoldText>
        <SmallText>
          Le Titulaire peut à tout moment consulter ce Contrat-cadre, le
          reproduire, le stocker sur son ordinateur ou sur un autre support, le
          transférer par courrier électronique ou l’imprimer sur papier de
          manière à le conserver.
          <Spacer height="20px" />A tout instant, conformément à la loi, il est
          possible de vérifier l’agrément de Lemonway sur le site regafi.fr, en
          qualité d’établissement de paiement. Le Site Internet de
          l’établissement de paiement Lemonway est le suivant : www.lemonway.com
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>1. OBJET</SemiBoldText>
        <SmallText>
          Le présent « Contrat-cadre de Services de paiement » est consultable à
          tout moment sur le Site Internet (https://www.lemonway.com). Il régit
          les termes et conditions de l’ouverture d’un Compte de paiement par
          Lemonway au nom du Titulaire et la fourniture de Services de paiement.
          Le Titulaire est invité à les lire avec attention avant de les
          accepter.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>2. DÉFINITIONS</SemiBoldText>
        <SmallText>
          Les termes utilisés dans le présent Contrat-cadre auront, lorsqu’ils
          sont utilisés avec la première lettre en majuscule et indépendamment
          du fait qu’ils soient utilisés au singulier ou au pluriel, le sens
          défini ci-après :
          <br />• Bénéficiaire : personne physique ou morale désignée par le
          Titulaire comme destinataire d’un virement provenant de son Compte de
          paiement. Le Bénéficiaire peut être le Titulaire. <br />•
          Contrat-cadre : Contrat-cadre de Services de paiement tel que défini à
          l’article L. 314-12 du Code monétaire et financier conclu entre
          Lemonway et le Titulaire composé des présentes CGU. <br />• Compte de
          paiement : Compte de paiement au sens de l’article L. 314-1 du Code
          monétaire et financier ouvert dans les livres de Lemonway à l’effet
          d’inscrire au débit et au crédit les Opérations de paiement, les frais
          dus par le Titulaire et toute contrepassation en lien avec ses
          Opérations et de compenser ces montants à la date de leur inscription
          aux fins de faire apparaître un solde net. <br />• Jour Ouvré : un
          jour autre qu’un samedi, un dimanche ou un jour férié en France.{" "}
          <br />• Opérations de paiement : action consistant à verser,
          transférer ou retirer des fonds à partir ou à destination du Compte de
          paiement, indépendamment de toute obligation sous-jacente entre le
          Payeur et le Bénéficiaire.
          <br /> • Ordre de paiement : consentement du Titulaire donné suivant
          le dispositif personnalisé et les procédures convenus entre le
          Titulaire et Lemonway, afin d’autoriser une Opération de paiement.
          <br /> • Partenaire : société commerciale exploitant le Site
          Partenaire et utilisant les services de Lemonway. <br />• Payeur :
          Personne physique ou morale pouvant être le Titulaire en cas
          d’alimentation de son propre Compte de paiement ou utilisatrice du
          Site Partenaire. <br />• Provision : montant disponible inscrit au
          crédit du Compte de paiement pouvant être affecté à l’exécution
          d’Opérations de paiement futures, déterminé par Lemonway après prise
          en compte des Opérations de paiement en cours et du montant de la
          Provision bloquée définie à l’article 5.
          <br /> • Prestataire de Services de paiement (PSP) tiers : prestataire
          de Services de paiement (établissement financier) agréé par une
          autorité d’un Etat partie à l’Espace Économique Européen ayant ouvert
          un compte bancaire ou de paiement au nom du Titulaire. <br />•
          Services de paiement : services fournis par Lemonway en application du
          Contrat-cadre et comprenant l’exécution de virements et l’acquisition
          d’ordres de paiement par cartes et par virements, ainsi que
          l’encaissement de chèques. <br />• Site Internet : Désigne le site
          internet http://www.lemonway.com à partir duquel Lemonway propose les
          Services de paiement. <br />• Site Partenaire ou Site : Désigne le
          site et/ou l’application exploité par le Partenaire en vue de
          permettre à des personnes de réaliser des Opérations de paiement.{" "}
          <br />• Titulaire : Personne physique ou morale disposant d’un Compte
          de paiement permettant d’effectuer et/ou recevoir une Opération de
          paiement.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>3. OUVERTURE DE COMPTE DE PAIEMENT</SemiBoldText>
        <SmallText>
          Le Titulaire doit satisfaire la procédure d’ouverture de Compte de
          paiement ci-après décrite.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          3.1 Déclarations préalables du Titulaire
        </PCSemiBoldText>
        <SmallText>
          Le Titulaire, personne morale ou physique majeure capable, déclare
          expressément avoir la capacité et/ou avoir reçu les autorisations
          requises pour utiliser les Services de paiement fournis par Lemonway
          et garantit Lemonway contre toute responsabilité pouvant résulter pour
          elle d’une fausse déclaration.
          <Spacer height="20px" />
          Le Titulaire déclare agir pour son compte. Le Titulaire a l’obligation
          d’utiliser les services fournis par Lemonway de bonne foi, à des fins
          licites et dans le respect des dispositions du Contrat-cadre.
          <Spacer height="20px" />
          Le Titulaire personne physique déclare être résident en France ou dans
          l’Espace Économique Européen.
          <Spacer height="20px" />
          Pour tout autre pays de résidence ou d’immatriculation, Lemonway se
          garde la possibilité d’étudier la demande d’ouverture d’un Compte de
          paiement afin de se conformer au périmètre géographique de son
          agrément.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          3.2 Transmission des documents d’identification
        </PCSemiBoldText>
        <SmallText>
          Lemonway informe le Titulaire que conformément aux obligations
          d’identification relatives à la lutte contre le blanchiment de
          capitaux et de financement du terrorisme, l’ouverture du Compte de
          paiement est conditionnée à la transmission et validation des
          documents d’identification requis en fonction de la qualité du
          Titulaire.
          <Spacer height="20px" />
          Lemonway se réserve le droit de demander tout autre document ou
          information complémentaire, pour lui permettre d’effectuer les
          vérifications utiles au respect de ses obligations légales y compris
          en matière de lutte contre le blanchiment de capitaux.
          <Spacer height="20px" />
          Le Titulaire accepte que le Site Partenaire fasse parvenir ces
          documents à Lemonway par transmission informatique et téléversement
          sur les systèmes informatiques de Lemonway.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          3.3 Modalités d’approbation du Contrat-cadre
        </PCSemiBoldText>
        <SmallText>
          Le Contrat-cadre doit être approuvé par le Titulaire par tout moyen.
          Ce dernier reconnaît avoir attentivement lu, compris et accepté le
          Contrat-cadre dans son intégralité.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          3.4 Acceptation d’ouverture de Compte de paiement
        </PCSemiBoldText>
        <SmallText>
          Lemonway peut refuser d’ouvrir un Compte de paiement pour tout motif
          sans avoir à justifier sa décision. Celle-ci ne donnera lieu à aucun
          dommage et intérêt.
          <Spacer height="20px" />
          Sous réserve de l’autorisation expresse et écrite de son représentant
          légal, le mineur de moins de dix-huit (18) ans peut ouvrir un Compte
          de paiement.
          <Spacer height="20px" />A cet effet, les documents d’identification
          requis seront ceux du représentant légal ayant donné son autorisation
          expresse et écrite, du mineur ainsi qu’un document attestant du
          pouvoir (livret de famille, décision de justice conférant le pouvoir
          de représentation).
          <Spacer height="20px" />
          Le Site Partenaire pourra faire parvenir l’acceptation ou le refus de
          l’ouverture de son Compte de paiement au Titulaire par envoi d’un
          Email. Le Titulaire peut à compter de l’acceptation s’identifier sur
          le Site Partenaire pour constater que son Compte de paiement est
          ouvert.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          3.5 Régime spécifique de la clientèle occasionnelle
        </PCSemiBoldText>
        <SmallText>
          Le client occasionnel est le client « de passage » qui sollicite
          l’intervention de Lemonway pour la réalisation d’une Opération de
          paiement isolée ou de plusieurs Opérations de paiement présentant un
          lien entre elles dans la limite des plafonds applicables par Lemonway.
          Les Opérations effectuées par un client occasionnel ne feront pas
          l’objet de l’ouverture d’un Compte de paiement.
          <Spacer height="20px" />
          Lemonway rappelle que le régime spécifique de la clientèle
          occasionnelle est uniquement applicable à une certaine typologie de
          clientèle et d’activité.
          <Spacer height="20px" />
          L’acceptation du présent Contrat-cadre ne constitue pas une entrée en
          relation d’affaires et l’ouverture d’un Compte de paiement dans le cas
          où le régime spécifique de la clientèle occasionnelle est applicable.
          <Spacer height="20px" />
          Lemonway rappelle qu’en cas de dépassement des plafonds d’Opérations
          de paiement applicables, l’ouverture d’un Compte de paiement sera
          obligatoire pour effectuer de nouvelles Opérations de paiement.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>4. CRÉDITER LE COMPTE DE PAIEMENT</SemiBoldText>
        <PCSemiBoldText>4.1 Alimentation du Compte de paiement</PCSemiBoldText>
        <SmallText>
          Le Titulaire peut procéder à l’alimentation de son Compte de paiement
          par les moyens de paiement mis à disposition par le Partenaire de
          Lemonway.
          <Spacer height="20px" />
          Lors de l’alimentation du Compte de paiement par virement, par chèque
          (France uniquement) ou par ordre de prélèvement, ces Opérations de
          paiement doivent être réalisées à l’ordre d’un compte bancaire ouvert
          au nom de Lemonway. Lemonway crédite ensuite les fonds correspondants,
          après réception, sur le Compte de paiement.
          <Spacer height="20px" />
          Lemonway peut refuser ou annuler l’enregistrement du moyen de paiement
          utilisé par le Titulaire par mesure de sécurité.
          <Spacer height="20px" />
          Lemonway paramètre des plafonds de paiement dans l’intérêt de la
          protection du Titulaire et le respect de la réglementation en vigueur
          en matière de Services de paiement. Des plafonds uniques, par jour,
          par mois et par an, ainsi que toutes formes de restrictions (notamment
          portant sur l’authentification forte du Titulaire) sont appliquées par
          Lemonway pour lutter contre la fraude.
          <Spacer height="20px" />
          Le Titulaire est informé que toute Opération de paiement risquant
          d’entraîner un dépassement des plafonds applicables sera
          automatiquement rejetée par Lemonway.
          <Spacer height="20px" />
          Toute Opération par carte bancaire ou de paiement, qui ferait l’objet
          d’un impayé, d’un rejet ou d’une opposition verra son montant
          automatiquement déduit par Lemonway du solde net du Compte de
          paiement. Si le solde net est insuffisant, Lemonway est autorisée à
          utiliser toutes les voies de recours contre le Titulaire en vue de
          recouvrer le montant dû. En outre, Lemonway sera en droit de refuser
          l’exécution de toutes les futures remises de fonds effectuées au moyen
          de la carte ayant donné lieu à l’incident.
          <Spacer height="20px" />
          Lemonway répercute, en outre, au débit du Compte de paiement du
          Titulaire, les Opérations de paiement rejetées et autres amendes qui
          peuvent être prononcées par les réseaux d’acceptation.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          4.2 Délais d’inscription des fonds sur le Compte
        </PCSemiBoldText>
        <SmallText>
          Lemonway inscrira les fonds résultant de l’acquisition d’une Opération
          de paiement par carte ou par virement dans les plus brefs délais et au
          plus tard à la fin du Jour Ouvré au cours duquel ils ont été reçus par
          Lemonway.
          <Spacer height="20px" />
          Les délais d’inscription des fonds sur le Compte peuvent être plus
          longs en cas d’utilisation d’un autre moyen de paiement mis à
          disposition par Lemonway.
          <Spacer height="20px" />
          Pour des raisons de sécurité, les délais d’inscription peuvent être
          plus longs en cas de suspicion de fraude dans l’attente de fourniture
          d’informations complémentaires par le Titulaire ou de tout autre tiers
          concerné
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>
          5. DÉBITER UN COMPTE DE PAIEMENT PAR VIREMENT
        </SemiBoldText>
        <PCSemiBoldText>5.1 Initier un Ordre de paiement</PCSemiBoldText>
        <SmallText>
          Lemonway fournit un service de paiement permettant aux Titulaires
          disposant d’un Compte de paiement de donner instruction à Lemonway,
          d’exécuter un virement sous réserve que la Provision du Compte soit
          supérieure au montant total du virement (frais inclus). En cas de
          Provision insuffisante, l’Ordre de paiement sera automatiquement
          refusé.
          <Spacer height="20px" />
          La Provision disponible correspond au solde net du Compte de paiement
          exclusion faite de la Provision bloquée et des Opérations en cours. Le
          montant de la Provision bloquée est déterminé par Lemonway en vue de
          couvrir les éventuelles contrepassations provenant de la contestation
          d’un Ordre de paiement. Une telle contestation peut intervenir dans un
          délai de treize (13) mois à compter du débit en Compte de l’Opération.
          <Spacer height="20px" />
          L’Ordre de paiement pourra être fait à l’attention soit d’un autre
          Compte de paiement ouvert dans les livres de Lemonway, soit d’un
          compte bancaire au nom du Titulaire ouvert dans les livres d’un PSP
          tiers autorisé.
          <Spacer height="20px" />
          L’Ordre de paiement devra comporter les informations suivantes :
          <Spacer height="20px" />
          • le montant en euros ou en devises ;
          <br />
          • le nom et prénom du Bénéficiaire ;
          <br />• le numéro de son compte bancaire ouvert dans les livres du PSP
          tiers.
          <Spacer height="20px" />
          Le Titulaire reconnaît que si la devise du Compte de paiement diffère
          de celle du compte Bénéficiaire vers lequel va s’effectuer le virement
          des fonds, des frais de change seront imputés par le PSP du
          Bénéficiaire. Il appartient au Partenaire de Lemonway et au PSP du
          Bénéficiaire d’informer le Bénéficiaire avant toute acquisition d’un
          ordre de virement, du taux de change pratiqué, des frais et des délais
          d’exécution. Cette information devra être transmise au Titulaire
          Payeur par le Partenaire.
          <Spacer height="20px" />
          Lemonway ne peut être tenu pour responsable si les coordonnées
          bancaires transmises pour les demandes de virement sont erronées ou
          non mises à jour.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          5.2 Irrévocabilité d’un Ordre de paiement
        </PCSemiBoldText>
        <SmallText>
          L’Ordre de paiement valablement donné par un Titulaire est irrévocable
          pour le Titulaire. Le Titulaire ne pourra donc pas en demander
          l’annulation.
          <Spacer height="20px" />
          Lemonway pourra cependant bloquer tout Ordre de paiement en cas de
          suspicion de fraude ou de violation des règles applicables en matière
          de lutte contre le blanchiment et le financement du terrorisme
          (LCB-FT).
          <Spacer height="20px" />
          Les Ordres de paiement devront préalablement recueillir le
          consentement du Payeur ou du Titulaire. En l’absence d’un tel
          consentement, l’Opération ou la série d’Opérations de paiement est
          réputée non autorisée.
          <Spacer height="20px" />
          Le consentement peut être retiré par le Payeur tant que l’Ordre de
          paiement n’a pas acquis un caractère d’irrévocabilité conformément aux
          dispositions de l’article L. 133-8 du Code monétaire et financier.
          <Spacer height="20px" />
          Lorsque l’Opération de paiement est initiée par le Bénéficiaire ou par
          le Payeur qui donne un Ordre de paiement par l’intermédiaire du
          Bénéficiaire, le Payeur ne peut révoquer l’Ordre de paiement après
          avoir transmis l’Ordre de paiement au Bénéficiaire ou donné son
          consentement à l’exécution de l’Opération de paiement au Bénéficiaire.
          <Spacer height="20px" />
          Le consentement à l’exécution d’une série d’Opérations de paiement
          peut aussi être retiré, avec pour effet que toute Opération
          postérieure est réputée non autorisée.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          5.3 Montants des plafonds et limites applicables
        </PCSemiBoldText>
        <SmallText>
          Toute Opération de paiement risquant d’entraîner un dépassement des
          plafonds applicables au montant des paiements mensuels cumulés sera
          automatiquement rejetée par Lemonway.
          <Spacer height="20px" />
          D’autres plafonds ou blocages d’Ordre pourront être activés à tout
          moment par Lemonway en cas de risque de fraude.
          <Spacer height="20px" />
          Lemonway se réserve le droit de contrepasser une Opération de
          paiement, si l’opération de transfert de fonds par carte bancaire ou
          de paiement utilisée pour créditer le Compte de paiement en vue de
          réaliser cette opération est rejetée ou annulée par le PSP émetteur de
          la carte.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>5.4 Délais d’exécution</PCSemiBoldText>
        <SmallText>
          Les délais maximaux d’exécution des Services de paiement, conformément
          à l’arrêté du 29 juillet 2009, d’application de l’article L. 133-13 du
          Code monétaire et financier, sont les suivants :
          <Spacer height="20px" />
          • une Opération de paiement initiée un Jour Ouvré sera exécutée au
          plus tard par Lemonway le Jour Ouvré suivant si elle est réalisée en
          euros au profit d’un établissement de crédit localisé dans un Etat
          membre de l’Union Européenne ;
          <br />• une Opération de paiement initiée un Jour Ouvré sera exécutée
          au plus tard par Lemonway à la fin dudit Jour Ouvré si elle est
          réalisée en euros au profit d’un autre Compte de paiement
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>6. REPORTING – RELEVÉS</SemiBoldText>
        <SmallText>
          Toutes les Opérations de paiement sont reprises dans un relevé établi
          en temps réel pour chaque Compte de paiement.
          <Spacer height="20px" />
          Le Titulaire aura accès aux relevés de Comptes de paiement reprenant
          l’ensemble des Opérations de paiement inscrites au débit et au crédit
          de ce compte par l’intermédiaire du Site Partenaire.
          <Spacer height="20px" />
          La période de consultation est maintenue pendant deux (2) ans, plus
          l’année en cours. Lemonway conservera sur support électronique
          d’archivage, pendant les délais réglementaires applicables, les
          enregistrements et documents des Opérations de paiement effectuées.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>7. DURÉE DU CONTRAT-CADRE ET DATE D’EFFET</SemiBoldText>
        <SmallText>
          Le Contrat-cadre entre en vigueur au moment de l’acceptation des
          présentes par le Titulaire et ce pour une durée indéterminée.
          <Spacer height="20px" />
          Le Titulaire dispose d’un délai de quatorze (14) jours calendaires
          pour renoncer sans frais au Contrat-cadre, s’il répond aux conditions
          de l’article D. 341-1 du Code monétaire et financier pour les
          personnes morales ou s’il est une personne physique. Ce délai court à
          compter du jour où le Contrat-cadre est conclu c’est-à-dire le jour où
          le Titulaire a accepté les présentes conditions générales. Pendant ce
          délai de renonciation, l’exécution du Contrat-cadre ne pourra
          commencer qu’à la demande expresse du Titulaire. Le Titulaire
          reconnaît expressément et accepte que toute instruction de paiement
          adressée à Lemonway avant l’écoulement du délai de renonciation,
          constitue une demande expresse du Titulaire d’exécuter le
          Contrat-cadre. Le Titulaire ne sera donc pas en droit d’annuler une
          instruction de paiement qu’il aurait donnée et confirmée pendant le
          délai de renonciation.
          <Spacer height="20px" />
          Ce droit de renonciation peut être exercé par le Titulaire sans
          pénalités et sans indication de motif.
          <Spacer height="20px" />
          Le Titulaire est tenu de notifier sa décision de renonciation à
          Lemonway par tout moyen. Si le Titulaire n’exerce pas son droit de
          renonciation, le Contrat-cadre sera maintenu conformément aux
          dispositions des présentes conditions générales. Il devra pour
          résilier le Contrat-cadre se conformer aux conditions de résiliation
          de l’article 19.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText> 8. RÉCLAMATIONS</SemiBoldText>
        <SmallText>
          Les réclamations qui portent sur les relations entre deux Titulaires
          ou entre un Titulaire et un tiers ne sont pas recevables auprès de
          Lemonway. Seules celles qui portent sur l’absence ou la mauvaise
          exécution d’une Opération de paiement exécutée par Lemonway sont
          visées par le présent article et par le Contrat-cadre.
          <Spacer height="20px" />
          Les réclamations (contestations, droits d’opposition, d’accès et de
          rectification) peuvent être exercées gratuitement sur demande adressée
          à Lemonway via la page de réclamation disponible à l’adresse suivante
          : www.lemonway.com/reclamation ou par courrier à l’adresse suivante :
          <Spacer height="20px" />
          Société LEMONWAY – Service Réclamation – 8 rue du Sentier, CS 60820,
          75083 Paris CEDEX 2.
          <Spacer height="20px" />
          Toute contestation ou requête relative :
          <Spacer height="20px" />
          • à une information communiquée par Lemonway en application du
          Contrat-cadre ;
          <br />• à une erreur dans l’exécution des Services de paiement ou dans
          son inexécution ;
          <br />• à une erreur dans le prélèvement de commission, taxe ou frais
          par Lemonway ;
          <Spacer height="20px" />
          doit être notifiée à Lemonway par le Titulaire dans les plus brefs
          délais à compter du jour où le Titulaire en a eu connaissance ou est
          présumé en avoir eu connaissance ou dans tout autre délai plus long
          prévu par des dispositions particulières ou par la loi.
          <Spacer height="20px" />
          Conformément à la recommandation de l’ACPR 2016-R-02 du 14 novembre
          2016, un accusé de réception sera envoyé sous dix jours maximum. Les
          réclamations seront traitées sous deux mois maximum à compter de leur
          réception.
          <Spacer height="20px" />A défaut d’accord amiable, le Titulaire
          agissant pour des besoins non professionnels peut s’adresser, par
          lettre, à un médiateur indépendant, pouvant être saisi gratuitement en
          cas de litige né de l’application des présentes, le Médiateur de
          l’AFEPAME, 36 rue Taitbout 75009 Paris, et ceci sans préjudice des
          autres voies d’actions légales.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>9. FRAIS</SemiBoldText>
        <SmallText>
          En contrepartie de la fourniture des Services de paiement au
          Titulaire, Lemonway percevra une rémunération dont le montant et les
          conditions sont fixées entre le Partenaire et Lemonway.
          <Spacer height="20px" />
          Lemonway informe le Titulaire que le Partenaire prend en charge
          l’ensemble des frais relatifs à la fourniture des Services de
          paiement.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>10. SÉCURITÉ</SemiBoldText>
        <PCSemiBoldText>10.1 Obligation de notification</PCSemiBoldText>
        <SmallText>
          Le Titulaire a l’obligation d’informer immédiatement Lemonway en cas
          de soupçon d’accès ou d’utilisation frauduleuse de son Compte de
          paiement ou de tout événement susceptible de mener à une telle
          utilisation, tels que et de manière non limitative : la perte, la
          divulgation accidentelle ou le détournement de ses identifiants de
          Compte de paiement ou une Opération non autorisée.
          <Spacer height="20px" />
          Cette notification doit s’effectuer par l’envoi d’un courrier
          électronique à l’adresse email suivante : alerte.lcbft@lemonway.com et
          être confirmée par courrier à l’adresse suivante :
          <Spacer height="20px" />
          Société LEMONWAY – Service Sécurité Financière – 8 rue du Sentier, CS
          60820, 75083 Paris CEDEX 2.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>10.2 Prévention</PCSemiBoldText>
        <SmallText>
          Lemonway fera ses meilleurs efforts pour empêcher toute autre
          utilisation du Compte de paiement. Le Partenaire dispose également de
          ses propres moyens de communication sécurisée avec le Titulaire sous
          sa propre responsabilité.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>10.3 Utilisation de cookies</PCSemiBoldText>
        <SmallText>
          Lemonway informe le Titulaire que dans le cadre des Services de
          paiement, des cookies pourront être utilisés. Ces cookies servent
          avant tout à améliorer le fonctionnement des Services de paiement
          notamment en termes de rapidité.
          <Spacer height="20px" />
          Le Titulaire est informé qu’il peut refuser les cookies auprès de
          Lemonway dans les paramètres de son navigateur, mais que cela risque
          d’altérer son utilisation des Services de paiement.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          10.4 Interruption des Services de paiement
        </PCSemiBoldText>
        <SmallText>
          Lemonway s’engage à mettre en œuvre tous les moyens raisonnables à sa
          disposition pour assurer un service permanent. Lemonway ne garantit
          toutefois pas l’accès continu, ininterrompu aux Service de paiement.
          En conséquence Lemonway ne pourra pas être tenue pour responsable du
          retard et/ou de la non-accessibilité totale ou partielle aux Services
          de paiement, dès lors qu’ils résultent de facteurs échappant au
          contrôle raisonnable de Lemonway.
          <Spacer height="20px" />
          Le Titulaire est informé que Lemonway peut interrompre,
          occasionnellement, l’accès à tout ou partie des Services de paiement :
          <Spacer height="20px" />
          • pour permettre les réparations, maintenances, ajouts de
          fonctionnalité ;
          <br />• en cas de suspicion de tentative de piratage, de détournement
          de fonds ou de tout autre risque d’atteinte ;
          <br />• sur demandes ou instructions émanant de personnes ou autorités
          compétentes habilitées.
          <Spacer height="20px" />
          Lemonway ne peut en aucun cas être tenue responsable des dommages
          résultants éventuellement de ces suspensions.
          <Spacer height="20px" />
          Dès la reprise normale du service, Lemonway mettra en œuvre les
          efforts raisonnables pour traiter les Opérations de paiement en
          attente dans les meilleurs délais.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          10.5 Opposition au dispositif de sécurité
        </PCSemiBoldText>
        <SmallText>
          Le Titulaire peut faire opposition en contactant Lemonway via la page
          de réclamation disponible à l’adresse suivante :
          www.lemonway.com/reclamation ou par téléphone au numéro : : +33 1 76
          44 04 60.
          <Spacer height="20px" />
          Le dispositif de sécurité s’entend comme toute mesure de sécurisation
          des Opérations de paiement et/ou d’accès au Titulaire à son Compte via
          son espace client du Site Partenaire, conformément à la réglementation
          en vigueur.
          <Spacer height="20px" />
          Un numéro d’enregistrement de cette opposition est créé et est
          conservé pendant 18 mois. Sur demande écrite du Titulaire et avant
          l’expiration de ce délai, Lemonway lui communiquera une copie de cette
          opposition.
          <Spacer height="20px" />
          Lemonway ne saurait être tenue pour responsable des conséquences d’une
          opposition qui n’émanerait pas du Titulaire. La demande d’opposition
          est réputée faite à la date de réception effective de la demande par
          Lemonway ou toute personne mandatée par lui, à cet effet. En cas de
          vol ou d’utilisation frauduleuse, Lemonway est habilitée à demander un
          récépissé ou une copie du dépôt de plainte au Titulaire qui s’engage à
          y répondre dans les plus brefs délais.
          <Spacer height="20px" />
          Lemonway bloquera l’accès au Compte de paiement.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>11. RESPONSABILITÉS</SemiBoldText>
        <SmallText>
          Conformément à l’article L. 133-22 du Code monétaire et financier,
          Lemonway est responsable, sous réserve des articles L. 133-5 et L.
          133-21 du Code monétaire et financier, de la bonne exécution de
          l’Opération de paiement à l’égard du Titulaire Payeur jusqu’à
          réception des fonds par le PSP tiers du Bénéficiaire. Lorsque Lemonway
          est responsable d’une Opération de paiement mal exécutée par sa faute,
          Lemonway restitue sans tarder son montant au Payeur et rétablit le
          compte débité dans la situation qui aurait prévalu si l’Opération de
          paiement mal exécutée n’avait pas eu lieu.
          <Spacer height="20px" />
          Le Titulaire agissant pour des besoins non professionnels qui souhaite
          contester une Opération de paiement non autorisée par lui doit
          contacter le service clients conformément à l’article 8 dans les plus
          brefs délais suivant sa prise de connaissance de l’anomalie et au plus
          tard 13 mois suivant l’inscription de l’Opération de paiement. En cas
          d’utilisation du dispositif de sécurité, les Opérations de paiement
          non autorisées effectuées avant la notification de l’opposition sont à
          la charge du Titulaire agissant pour des besoins non professionnels,
          dans la limite d’un plafond de 50 euros conformément à l’article L.
          133-19 du Code monétaire et financier. Toutefois, la responsabilité de
          Lemonway n’est pas engagée en cas de faute du Titulaire tel qu’un
          manquement volontaire ou constitutif d’une négligence grave à ses
          obligations, une transmission tardive de l’opposition ou de mauvaise
          foi. En cas de détournement de ses données ou contrefaçon, les pertes
          résultant des Opérations de paiement passées avant l’opposition par le
          Titulaire agissant pour des besoins non professionnels sont supportées
          par Lemonway, sauf en cas de faute telle que définie ci-dessus. Les
          Opérations de paiement réalisées après l’opposition du Titulaire
          agissant pour des besoins non professionnels sont supportées par
          Lemonway sauf en cas de fraude.
          <Spacer height="20px" />
          Lemonway n’est pas en droit d’annuler un Ordre de paiement irrévocable
          sur demande du Titulaire.
          <Spacer height="20px" />
          En aucun cas, Lemonway n’est responsable des dommages indirects, tels
          que préjudice commercial, perte de clientèle, trouble commercial
          quelconque, perte de bénéfice, perte d’image de marque subis par un
          Titulaire, ou par un tiers, et qui pourraient résulter des Services de
          paiement fournis par Lemonway. Toute action dirigée contre un
          Titulaire par un tiers est assimilée à un préjudice indirect, et en
          conséquence n’ouvre pas droit à réparation.
          <Spacer height="20px" />
          Sauf stipulation contraire des présentes conditions générales ou des
          lois impératives et sans préjudice des autres causes d’exclusion ou de
          limitation de responsabilité prévues par les présentes, Lemonway ne
          pourra en aucun cas être tenue pour responsable de tout dommage causé
          par un cas de force majeure ou un événement hors de son contrôle ou de
          toute mesure ou dispositions législatives prises par les autorités
          françaises ou étrangères. Sont réputés constituer un cas de force
          majeure ou un événement hors de son contrôle, notamment, mais sans que
          cela soit limitatif : une panne d’électricité, un incendie ou une
          inondation, la grève de son personnel ou d’un de ses sous-traitants ou
          fournisseurs, un dysfonctionnement des systèmes interbancaires ou de
          paiement par carte bancaire, troubles à l’ordre public, négligence
          d’un tiers au sens retenu par la jurisprudence et de la doctrine
          telles que les personnes responsables de la livraison d’électricité,
          des services de télécommunication ou d’hébergement.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>12. PROTECTION DES FONDS CLIENTS</SemiBoldText>
        <SmallText>
          Lemonway conservera les fonds disponibles inscrits au crédit du Compte
          de paiement du Titulaire à la fin du Jour Ouvré suivant le jour où ils
          ont été reçus sur un compte de cantonnement ouvert auprès des banques
          partenaires de Lemonway conformément l’article L. 522-17 du Code
          monétaire et financier.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>
          13. DÉCÈS – COMPTE DE PAIEMENT INACTIF – PROCURATION
        </SemiBoldText>
        <PCSemiBoldText>13.1 Décès</PCSemiBoldText>
        <SmallText>
          En cas de décès du Titulaire du Compte de paiement, Lemonway doit en
          être avisée le plus rapidement possible par les ayant droits ou leur
          mandataire. Si cet avis est donné verbalement, il doit être confirmé
          par écrit. Dès réception de cet écrit, Lemonway veillera à ce
          qu’aucune nouvelle Opération de paiement ne soit exécutée et procèdera
          à la clôture du Compte.
          <Spacer height="20px" />
          Si la Provision que Lemonway détient au nom du défunt est supérieure
          aux frais permettant de supporter les coûts de retrait, elle pourra
          faire l’objet d’un remboursement en faveur des ayants-droits
          uniquement en cas de production par ces ayants-droits ou leur
          mandataire des pièces probantes, selon la législation applicable,
          établissant la dévolution de la succession ainsi que de toute autre
          pièce que Lemonway jugerait nécessaire.
          <Spacer height="20px" />A défaut de virement pour quelque raison que
          ce soit, y compris l’absence de production de document probant à
          Lemonway, les dispositions de l’article 13.2 des présentes conditions
          générales s’appliqueront à la Provision.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>13.2 Compte inactif</PCSemiBoldText>
        <SmallText>
          Un Compte de paiement est réputé inactif si :
          <Spacer height="20px" />
          1.
          <br />
          a) le Compte de paiement n’a fait l’objet d’aucune Opération de une
          période de douze mois au cours de laquelle, hors inscription de de
          débit par Lemonway tenant le compte de frais et commissions de toutes
          et
          <Spacer height="20px" />
          b) le Titulaire du Compte, son représentant légal ou la personne
          habilitée par lui ne s’est pas manifesté, sous quelque forme que ce
          soit, auprès de Lemonway ; ou
          <Spacer height="20px" />
          2. à l’issue d’une période de douze (12) mois suivant le décès du
          Titulaire. Le Titulaire et ses ayants droit sont informés par les
          présentes des conséquences qui y sont attachées.
          <Spacer height="20px" />
          Les avoirs inscrits sur le Compte de paiement inactif sont déposés à
          la Caisse des dépôts et consignations à l’issue d’un délai de dix (10)
          ans à compter de la date de la dernière Opération de paiement hors
          inscription des débits par Lemonway tenant le compte de frais et
          commissions de toutes natures ; sauf en cas de décès du Titulaire du
          compte où les avoirs inscrits sur le compte de paiement inactif sont
          déposés à la Caisse des dépôts et consignations à l’issue d’un délai
          de trois (3) ans après la date du décès du Titulaire.
          <Spacer height="20px" />
          Pour toute relance et notification de Lemonway au Titulaire ou au
          Partenaire, des frais seront applicables. En cas de compte inactifs et
          après relance et notification de Lemonway, des frais de gestion seront
          applicables.
          <Spacer height="20px" />
          <PCSemiBoldText>13.3 Procuration</PCSemiBoldText>
          Le Titulaire peut donner à une personne pouvoir d’effectuer sur son
          Compte de paiement et sous son entière responsabilité les Opérations
          de paiement telles que définies dans la procuration. La procuration ne
          prendra effet qu’à compter de la réception et de l’acceptation du
          formulaire dûment complété par Lemonway. La procuration sera notifiée
          par tout moyen. Elle cesse automatiquement au décès du Titulaire. Elle
          peut être révoquée à l’initiative du Titulaire qui en informe le
          mandataire et Lemonway par lettre recommandée avec avis de réception.
          La révocation prend effet à la date de réception de la notification
          par Lemonway. Le Titulaire reste tenu des Opérations de paiement
          initiées pour son compte jusqu’à cette date par le mandataire désigné.
          <Spacer height="20px" />
          Le Titulaire lève expressément Lemonway du secret professionnel
          relatif aux données du Compte de paiement à l’égard du mandataire
          désigné par la procuration.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>14. PROPRIÉTÉ INTELLECTUELLE</SemiBoldText>
        <SmallText>
          Aucun droit de propriété intellectuelle relatif à l’utilisation du
          Service de paiement ou aux prestations rendues par Lemonway n’est
          transféré au Titulaire au titre des présentes conditions générales.
          <Spacer height="20px" />
          Le Titulaire s’engage à ne pas porter atteinte aux droits détenus par
          Lemonway, en s’interdisant notamment, toute reproduction, ou
          adaptation de tout ou partie des éléments intellectuels et matériels
          de Lemonway et ses accessoires, et ce quel que soit le support, actuel
          et futur.
          <Spacer height="20px" />
          L’ensemble des droits relatifs aux logiciels servant à la réalisation
          des Services de paiement sont la propriété pleine et entière de la
          société Lemonway. Ils font partie de ses informations confidentielles
          sans égard au fait que certaines composantes puissent ou non être
          protégées en l’état actuel de la législation par un droit de propriété
          intellectuelle.
          <Spacer height="20px" />
          Les logiciels de Lemonway et, le cas échéant, leur documentation, sont
          reconnus par le Titulaire comme œuvre de l’esprit que lui-même et les
          membres de son personnel s’obligent à considérer comme telle en
          s’interdisant de les copier, de les reproduire, de les traduire en
          toute autre langue ou langage, de les adapter, de les distribuer à
          titre gratuit ou onéreux, ou de leur adjoindre tout objet non conforme
          à leurs spécifications.
          <Spacer height="20px" />
          La marque « Lemonway » est la propriété de la société Lemonway. Le
          Titulaire s’engage à ne pas supprimer la mention de la marque «
          Lemonway » sur tout élément fourni ou mis à sa disposition par
          Lemonway, tels que logiciel, document ou bannière publicitaire.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>15. CONFIDENTIALITÉ</SemiBoldText>
        <SmallText>
          <Spacer height="20px" />
          Le Titulaire s’engage à respecter la plus stricte confidentialité
          concernant l’ensemble des techniques, commerciales ou de toute autre
          nature dont il viendrait à avoir connaissance dans le cadre de
          l’exécution du Service de paiement.
          <Spacer height="20px" />
          Cette obligation de confidentialité demeurera en vigueur pendant la
          durée de la souscription au Service de paiement et pendant trois (3)
          ans suivant la date de résiliation du Contrat-cadre. Cette obligation
          de confidentialité ne s’applique pas aux informations qui sont ou
          deviennent publiquement disponibles sans faute du Titulaire.
          <Spacer height="20px" />
          Les Parties reconnaissent que les Opérations de paiement sont
          couvertes par le secret professionnel en application de l’article L.
          522-19 du Code monétaire et financier.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>
          16. COLLECTE ET TRAITEMENT DES DONNEES A CARACTERE PERSONNEL
        </SemiBoldText>
        <SmallText>
          Conformément au Règlement Général sur la Protection des Données
          (General Data Protection Régulation) adopté par le Parlement européen
          le 14 avril 2016, et à la Loi Informatique et Libertés du 6 janvier
          1978 modifiée, Lemonway informe le Titulaire :
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          16.1 Identification du Responsable de traitement
        </PCSemiBoldText>
        <SmallText>
          Société Lemonway SAS, siège social sis au 8 rue du Sentier, 75002
          Paris, France, Tél. : +33 1 76 44 04 60.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          16.2 Délégué à la protection des données
        </PCSemiBoldText>
        <SmallText>
          Le Titulaire pourra joindre le délégué à la protection des données à
          l’adresse courriel suivante : dpo@lemonway.com
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>16.3 Finalités du traitement</PCSemiBoldText>
        <SmallText>
          Dans le cadre de l’exploitation du Site et des services fournis par
          Lemonway, le traitement de données personnelles a pour finalité la
          gestion des clients, la création et la gestion des comptes, la gestion
          des contrats, la gestion des résiliations, la gestion des litiges, la
          gestion du Site Internet, le mailing, les communications, les
          vérifications en matière de lutte contre le blanchiment de capitaux et
          de lutte contre le financement du terrorisme, la gestion de la
          connaissance client, l’élaboration de statistiques dans le but
          d’améliorer les outils de Lemonway, la gestion des demandes concernant
          les droits des personnes, l’implémentation des Partenaires, la gestion
          du support.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>16.4 Nature des données</PCSemiBoldText>
        <SmallText>
          Lemonway collecte de manière directe et indirecte les catégories de
          données suivantes concernant ses utilisateurs :
          <Spacer height="20px" />
          • Données d’état-civil, d’identité, d’identification… ;
          <br />• Données relatives à la vie professionnelle (CV, scolarité,
          formation professionnelle, …) ;
          <br />• Informations d’ordre économique et financier (revenus,
          situation financière, situation fiscale…) ;
          <br />• Données de connexion (adresses IP, journaux d’événements…).
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>16.5 Source des données</PCSemiBoldText>
        <SmallText>
          Lemonway collecte les données à caractère personnel de manière directe
          par le biais d’un contrat, d’une obligation légale, du consentement de
          la personne ou de l’intérêt légitime de la société.
          <Spacer height="20px" />
          Lemonway collecte également les données à caractère personnel de
          manière indirecte afin de respecter la règlementation en matière de
          lutte contre le blanchiment de capitaux et de lutte contre le
          financement du terrorisme.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>16.6 Consentement de la personne</PCSemiBoldText>
        <SmallText>
          Lorsque la personne a donné son consentement pour la collecte de ses
          données personnelles, celle-ci peut retirer son consentement, si le
          fondement juridique pour la collecte des données n’existe plus. Le
          Titulaire peut retirer son consentement via l’adresse
          dpo@lemonway.com.
          <Spacer height="20px" />
          Lemonway informe qu’un tel retrait entraînera la clôture du Compte.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>16.7 Intérêt légitime du traitement</PCSemiBoldText>
        <SmallText>
          Lorsque Lemonway collecte et utilise des données personnelles de
          représentants de ses Partenaires en se fondant sur l’intérêt légitime,
          celui-ci a pour finalité la prospection de nouveaux Partenaires.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>16.8 Scoring</PCSemiBoldText>
        <SmallText>
          Un scoring est mis en place uniquement en matière de lutte contre le
          blanchiment de capitaux, de lutte contre le financement du terrorisme
          et de lutte contre la fraude.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>16.9 Destinataires des données</PCSemiBoldText>
        <SmallText>
          {" "}
          Les destinataires des données à caractère personnel sont les
          collaborateurs habilités au sein de Lemonway, les autorités de
          contrôle, les partenaires de Lemonway et ses sous-traitants. Les
          données personnelles peuvent également être divulguées en application
          d’une loi, d’un règlement ou en vertu d’une décision d’une autorité
          réglementaire ou judiciaire compétente.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>16.10 Durée de conservation</PCSemiBoldText>
        <SmallText>
          Conformément aux dispositions en vigueur, le Titulaire dispose de
          droits concernant ses données personnelles qu’il pourra exercer en
          écrivant à l’adresse postale mentionnée au point 16.1 en l’adressant
          au DPO ou en écrivant à dpo@lemonway.com.
          <Spacer height="20px" />
          Ø Droit d’accès
          <Spacer height="20px" />
          Le Titulaire dispose de la faculté d’accéder aux données personnelles
          le concernant. Cependant, pour des motifs de sécurité et de
          confidentialité, la demande ne pourra être traitée que si le Titulaire
          apporte la preuve de son identité.
          <Spacer height="20px" />
          Lemonway peut s’opposer ou mettre en place une facturation pour les
          demandes manifestement abusives (nombre important de demandes,
          caractère répétitif ou systématique).
          <Spacer height="20px" />
          Ø Droit de rectification
          <Spacer height="20px" />
          Le Titulaire a la faculté de demander la rectification de ses données
          personnelles lorsque celles-ci sont inexactes, erronées, incomplètes
          ou obsolètes.
          <Spacer height="20px" />
          Ø Droit à limitation
          <Spacer height="20px" />
          Le Titulaire a la faculté de demander la limitation de ses données
          personnelles. Lorsque le droit à limitation est demandé, Lemonway
          pourra seulement stocker les données. Aucune autre opération ne pourra
          avoir lieu.
          <Spacer height="20px" />
          Ø Droit à portabilité
          <Spacer height="20px" />
          Le Titulaire conserve la faculté de demander de récupérer les données
          personnelles qu’il a fourni à Lemonway, dans un format structuré,
          couramment utilisé et lisible par machine afin de les transmettre à un
          autre responsable de traitement. Ce droit ne peut être utilisé que si
          le traitement des données est basé sur le consentement de la personne
          concernée ou sur un contrat.
          <Spacer height="20px" />
          Ø Droit d’opposition
          <Spacer height="20px" />
          Le Titulaire a la faculté de s’opposer à l’utilisation de ses données
          dans deux situations :
          <Spacer height="20px" />
          • Motifs légitimes ;
          <br />• En cas d’utilisation des données recueillies à des fins
          commerciales.
          <Spacer height="20px" />
          Ø Droit à l’effacement
          <Spacer height="20px" />
          Le Titulaire a la faculté de demander l’effacement de ses données dans
          les meilleurs délais si l’un des motifs du paragraphe 1 de l’article
          17 du Règlement Général sur la Protection des Données s’applique.
          <Spacer height="20px" />
          Si les données du Titulaire ont été transmises à d’autres entités, le
          mécanisme du « droit à l’oubli » s’enclenche : Lemonway devra prendre
          toutes les mesures raisonnables pour informer les autres entités que
          la personne concernée a demandé l’effacement de tout lien vers ses
          données personnelles, ou de toute copie ou reproduction de celles-ci.
          <Spacer height="20px" />
          Ø Droit post mortem
          <Spacer height="20px" />
          Le Titulaire a la faculté de définir des directives concernant les
          données personnelles du Titulaire après son décès. Le cas échéant, les
          héritiers du Titulaire peuvent exiger de prendre en considération le
          décès ou procéder aux mises à jour.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          <Spacer height="20px" />
        </PCSemiBoldText>
        <SmallText>
          Lemonway s’engage à répondre aux demandes relatives aux données à
          caractère personnel d’accès ou à l’exercice d’un droit dans un délai
          d’un (1) mois à compter de la réception de la demande.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>16.13 Transfert de données</PCSemiBoldText>
        <SmallText>
          Lemonway a recours à des prestataires habilités se situant à
          l’intérieur et à l’extérieur de l’Union Européenne.
          <Spacer height="20px" />
          En cas de transfert vers un pays tiers, Lemonway respecte le Règlement
          Général sur la Protection des Données en ayant recours à des
          partenaires ou sous-traitant présentant des garanties adéquates par le
          biais d’une procédure d’adéquation, de clauses contractuelles types ou
          de règles internes d’entreprise.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>
          16.14 Commission Nationale Informatique et Libertés (CNIL)
        </PCSemiBoldText>
        <SmallText>
          Si le Titulaire considère que Lemonway ne respecte pas ses obligations
          au regard de Loi Informatique et Libertés ou du Règlement Général sur
          la Protection des Données, il pourra adresser une plainte ou une
          demande auprès de l’autorité compétente. Le siège social de Lemonway
          se situant en France, l’autorité compétente est la Commission
          Nationale Informatique et Libertés. Le Titulaire a la possibilité de
          s’adresser à la Commission Nationale Informatique et Libertés par voie
          électronique via le lien suivant :
          https://www.cnil.fr/fr/plaintes/internet.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>17. CONVENTION DE PREUVE</SemiBoldText>
        <SmallText>
          Les communications effectuées par le biais de courrier électronique
          sont des modes de communication valablement admis à titre de preuve
          par le Titulaire et Lemonway.
          <Spacer height="20px" />
          Toutes les informations enregistrées dans les bases de données
          informatiques de Lemonway relatives notamment aux Ordres et Opérations
          de paiement, ont, jusqu’à preuve du contraire, la même force probante
          qu’un écrit signé sur un support papier, tant en ce qui concerne leur
          contenu qu’en ce qui concerne la date et l’heure à laquelle ils ont
          été effectués et/ou reçus. Ces traces inaltérables, sûres et fiables
          sont gravées et conservées dans les systèmes informatiques de
          Lemonway.
          <Spacer height="20px" />
          Les documents de Lemonway reproduisant ces informations, ainsi que les
          copies ou reproductions de documents produits par Lemonway ont la même
          force probante que l’original, jusqu’à preuve du contraire.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>18. BLOCAGE ET GEL DU COMPTE</SemiBoldText>
        <PCSemiBoldText>18.1 Blocage du Compte de paiement</PCSemiBoldText>
        <SmallText>
          La suspension temporaire et immédiate d’un Compte de paiement peut
          être prononcée par Lemonway pour toute raison à l’appréciation de
          Lemonway et notamment :
          <Spacer height="20px" />
          • Si le Titulaire n’a pas respecté les dispositions du Contrat-cadre ;
          <br />• Si le Titulaire a fourni à Lemonway des données
          d’identification inexactes, périmées ou incomplètes ;
          <br />• En cas de risque sensiblement accru d’incapacité par le
          Titulaire de s’acquitter de son obligation de paiement ;
          <br /> • En cas de réception par Lemonway d’un nombre important de
          remboursements, d’impayés, d’annulation d’Ordres ou de contestation
          pour Opérations de paiement non autorisées.
          <Spacer height="20px" />
          Cette décision est notifiée au Titulaire par tout moyen. La suspension
          du Compte de paiement ayant pour objet de protéger le Titulaire, elle
          ne pourra en aucun cas donner lieu au versement de dommages intérêts
          au profit de ce dernier.
          <Spacer height="20px" />
          La réactivation du Compte de paiement se fera à la discrétion de
          Lemonway, sur la base d’informations ou de documents additionnels qui
          pourront être réclamés.
          <Spacer height="20px" />
          En fonction de la gravité du manquement au Contrat-cadre et notamment
          en cas d’utilisation du Compte de paiement à des fins illicites ou
          contraire aux bonnes mœurs, Lemonway se réserve le droit de résilier
          le Contrat-cadre conformément aux dispositions de l’article 19.
          <Spacer height="20px" />
          Lemonway se réserve le droit d’appliquer des pénalités, des frais de
          gestion et de réclamer des dommages et intérêts au Titulaire.
        </SmallText>
        <Spacer height="20px" />
        <PCSemiBoldText>18.2 Gel du Compte de paiement</PCSemiBoldText>
        <SmallText>
          Dans le cadre du dispositif de filtrage mis en place par Lemonway
          (notamment basé sur les listes de sanctions internationales et de
          personnes politiquement exposées), le gel immédiat d’un Compte de
          paiement pourra être prononcé en cas de risque de fraude, de
          blanchiment de capitaux ou financement du terrorisme ou de risque
          pouvant affecter la sécurité du Compte de paiement.
          <Spacer height="20px" />
          Le gel du Compte de paiement sera levé ou non après analyse
          approfondie du risque par les équipes LCB-FT de Lemonway, et les fonds
          gelés seront traités conformément aux mesures définies par la
          Direction générale du Trésor.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>19. RÉSILIATION DU CONTRAT-CADRE</SemiBoldText>
        <SmallText>
          Le Titulaire peut résilier de plein droit le Contrat-cadre qui
          emportera clôture de son Compte de paiement par tout moyen suivant le
          respect d’un préavis d’un (1) mois. Il doit maintenir une Provision
          suffisante pour assurer la bonne fin des Opérations de paiement en
          cours pendant le délai nécessaire à leur dénouement et le paiement des
          frais dus par lui.
          <Spacer height="20px" />
          Lemonway peut résilier de plein droit le Contrat-cadre qui emportera
          clôture du Compte de paiement par lettre recommandée avec avis de
          réception suivant un préavis de deux (2) mois.
          <Spacer height="20px" />
          En cas de manquement grave d’une Partie, le Contrat-cadre peut être
          résilié avec effet immédiat. Il est entendu par manquements graves
          réalisés par le Titulaire : communication de fausses informations ;
          exercice d’activité illégale, contraire aux bonnes mœurs, de
          blanchiment de capitaux ou de financement du terrorisme ; menaces à
          l’encontre de préposés de Lemonway ou du Partenaire ; défaut de
          paiement ; non-respect d’une obligation du Titulaire au titre des
          présentes ; résiliation des relations entre le Titulaire et le
          Partenaire ; résiliation des relations entre le Partenaire et Lemonway
          ; surendettement ou pour les personnes morales nomination d’un
          mandataire ad hoc, d’un administrateur judiciaire, ouverture d’une
          procédure de redressement ou de liquidation. Il est entendu par
          manquements graves réalisés par Lemonway : communication de fausses
          informations ; irrespect d’une obligation au titre des présentes ;
          nomination d’un mandataire ad hoc, d’un administrateur judiciaire,
          ouverture d’une procédure de redressement ou de liquidation.
          <Spacer height="20px" />
          En cas de modification de la réglementation applicable et de
          l’interprétation qui en est faite par l’autorité de régulation
          concernée affectant la capacité de Lemonway ou de ses mandataires à
          exécuter les Opérations de paiement, le Contrat-cadre sera
          automatiquement résilié.
          <Spacer height="20px" />
          Le Titulaire ne pourra plus transmettre d’Ordre de paiement à compter
          de la date d’effet de la résiliation. Le Compte pourra être maintenu
          pendant une durée de 13 mois à l’effet de couvrir les éventuelles
          contestations et réclamations ultérieures. Les Opérations de paiement
          initiées avant la date d’effet de la résiliation ne seront pas remises
          en cause par la demande de résiliation et devront être exécutées dans
          les termes du Contrat-cadre.
          <Spacer height="20px" />
          La résiliation du Contrat-cadre entraîne la clôture définitive du
          Compte de paiement. La clôture d’un Compte de paiement ne pourra
          donner lieu à aucune indemnité quels que soient les éventuels dommages
          occasionnés par la fermeture de ce Compte de paiement. Le Titulaire
          dont le Compte a été clôturé par Lemonway n’est pas autorisé, sauf
          accord exprès de Lemonway, à ouvrir un autre Compte de paiement. Tout
          Compte de paiement ouvert en violation de cette disposition pourra
          être immédiatement clôturé par Lemonway, sans préavis.
          <Spacer height="20px" />
          La Provision sur le Compte de paiement objet de la clôture donnera
          droit à un virement au profit du Titulaire de ce compte suivant ses
          instructions sous réserve (i) des Opérations de paiement en cours et
          des éventuels impayés, rejets bancaires ou oppositions à venir et (ii)
          des mesures applicables au gel des avoirs telles que définies par la
          Direction Générale du Trésor. Si un successeur est désigné par
          Lemonway, il pourra être proposé au Titulaire de clôturer son Compte
          de paiement et de transférer la Provision sur un nouveau compte de
          paiement ouvert dans les livres de l’établissement désigné comme
          successeur.
          <Spacer height="20px" />
          Lemonway se réserve le droit de demander en justice réparation du
          préjudice qu’elle aurait subi du fait de la violation du
          Contrat-cadre. La clôture du Compte de paiement pourra donner lieu à
          des frais dans la limite de l’article L. 314-13 du Code monétaire et
          financier.
        </SmallText>
        <Spacer height="50px" />
        <SmallText>
          Les dispositions du Contrat-cadre sont susceptibles d’être modifiées
          ou complétées à tout moment, notamment en vue de se conformer à toute
          évolution législative, règlementaire, jurisprudentielle ou
          technologique.
          <Spacer height="20px" />
          Tout projet de modification du Contrat-cadre est communiqué sur
          support papier ou sur un autre support durable au Titulaire au plus
          tard deux (2) mois avant la date d’application proposée pour son
          entrée en vigueur.
          <Spacer height="20px" />
          En l’absence de contestation écrite par lettre recommandée avec avis
          de réception adressée à Lemonway par le Titulaire avant l’expiration
          de ce délai de deux mois, ce dernier est réputé avoir accepté ces
          modifications. En cas de refus de la modification proposée, le
          Titulaire peut résilier sur demande écrite le Contrat-cadre sans
          frais, avant la date d’entrée en vigueur proposée de celle-ci. Cette
          demande n’affecte pas l’ensemble des débits (frais, cotisations,
          paiement) dont le Titulaire reste redevable.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>21. GENERALITES</SemiBoldText>
        <SmallText>
          Au cas où des formalités administratives seraient nécessaires au titre
          de l’exécution du présent Contrat-cadre, Lemonway et le Titulaire se
          prêteront mutuelle assistance pour la régularisation de ces
          formalités.
          <Spacer height="20px" />
          Si l’une quelconque des stipulations non substantielles du
          Contrat-cadre est nulle au regard d’une règle de droit en vigueur,
          elle sera réputée non écrite, mais n’entraînera pas la nullité du
          présent Contrat-cadre.
          <Spacer height="20px" />
          Le fait pour l’une des Parties de ne pas se prévaloir d’un manquement
          par l’autre Partie à l’une quelconque des obligations visées dans les
          présentes ne saurait être interprété pour l’avenir comme une
          renonciation à l’obligation en cause.
          <Spacer height="20px" />
          En cas de difficulté d’interprétation entre l’un quelconque des titres
          et l’une quelconque des clauses des conditions générales il ne sera
          pas tenu compte des titres.
          <Spacer height="20px" />
          Le présent Contrat-cadre a été rédigé en français et en anglais puis
          traduit dans d’autres langues à titre d’information uniquement. En cas
          de difficulté d’interprétation, les versions françaises et anglaises
          du Contrat-cadre prévaudront sur toute autre traduction existante.
        </SmallText>
        <Spacer height="50px" />
        <SemiBoldText>22. DROIT APPLICABLE ET JURIDICTIONS</SemiBoldText>
        <SmallText>
          Le présent Contrat-cadre est régi par le droit français.
          <Spacer height="20px" />
          Sauf disposition impérative contraire, tout litige relatif à son
          exécution, son interprétation ou sa validité, sera porté devant les
          cours et tribunaux compétents et à défaut de Paris.
        </SmallText>
        <Spacer height="50px" />
      </SectionContainer>
      <Footer />
    </Container>
  );
};

export default PaymentConditions;
