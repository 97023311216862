import React from "react";
import Summary from "./HomeComponents/HeaderComponents/Summary";
import Wallet from "./HomeComponents/Wallet/Wallet";
import Obligations from "./HomeComponents/Obligations/Obligations";
import styled from "styled-components";
import GestionLm from "./HomeComponents/GestionLm";
import GestionF from "./HomeComponents/GestionF";
import GestionRc from "./HomeComponents/GestionRc";
import GestionRcc from "./HomeComponents/GestionRcc";
import Footer from "./Footer";
const Homepage = styled.div`
  width: auto;
  height: auto;
  background-color: #f7f4f7;
`;
const Home = () => {
  return (
    <Homepage>
      <Summary />
      <Wallet />
      <Obligations />
      <GestionLm />
      <GestionF />
      <GestionRc />
      <GestionRcc />
      <Footer />
    </Homepage>
  );
};
export default Home;
