import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Spacer } from "./basics/Spacer";
import { SmallText } from "./basics/Typography";
import { Button } from "./basics/Button";
import COLORS from "../constants/colors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  background-color: #49264c;
  align-self: stretch;
  @media only screen and (max-width: 900px) {
    padding: 40px 20px 50px;
  }
`;
const Decouv = styled.h1`
  width: 100%;
  text-align: center;
  align-items: center;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  @media only screen and (max-width: 900px) {
    font-size: 22px;
  }
`;
const Logofooter = styled.img`
  display: flex;
  object-fit: contain;
  width: 400px;
  margin 12px 0 12px 0;
  @media only screen and (max-width: 900px) {
    width: 70%;
  }
`;
const Textfooter = styled.p`
  width: 50%;
  text-align: center;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  opacity: 0.75;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  @media only screen and (max-width: 900px) {
    font-size: 18px;
    width: 80%;
  }
`;
const Btnfooter = styled(Button)`
  background-color: #777caa;
  text-decoration: none !important;
`;
const Demofooter = styled.p`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;
const ConditionsText = styled(SmallText)`
  color: white;
  text-align: center;
  opacity: 0.7;
  text-decoration: underline;
  margin: 0 10px;
`;

const ContactText = styled(SmallText)`
  color: ${COLORS.WHITE};
  text-align: center;
  a {
    color: ${COLORS.WHITE};
    text-decoration: none;
  }
`;

const ConditionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Contact = () => (
  <ContactText>
    21 rue d’Algérie 69001 LYON –{" "}
    <a href="tel:0428292121">Tél. 04 28 29 21 21</a> –{" "}
    <a href="mailto:contact@nahal.io">contact@nahal.io</a>
  </ContactText>
);

const Conditions = () => (
  <ConditionsContainer>
    <Link to="/mentions-legales">
      <ConditionsText> Mentions légales </ConditionsText>
    </Link>
    <Link to="/conditions-generales-de-vente">
      <ConditionsText> Conditions générales de vente</ConditionsText>
    </Link>
    <Link to="/conditions-liees-aux-moyens-de-paiements">
      <ConditionsText>Conditions liées aux moyens de paiement</ConditionsText>
    </Link>
  </ConditionsContainer>
);

const Footer = () => {
  return (
    <Container>
      <Decouv>Découvrez</Decouv>
      <Logofooter
        src="img/colors-white.png"
        srcSet="img/colors-white@2x.png,
             img/colors-white@3x.png"
      />
      <Textfooter>
        L'outil de gestion interne 100% collaboratif dédié aux Cabinets
        d'Expertises-Comptables
      </Textfooter>
      <Btnfooter href="https://calendly.com/ecp-ahmed/15min">
        <Demofooter>Réservez une démo</Demofooter>
      </Btnfooter>
      <Spacer height="20px" />
      <Contact />
      <Spacer height="20px" />
      <Conditions />
    </Container>
  );
};

export default Footer;
