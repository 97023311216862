import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import COLORS from "../../constants/colors";
import { Spacer } from "../basics/Spacer";
import {
  SemiBoldText,
  SmallText,
  SmallTextTransparent,
} from "./../basics/Typography";
import { Button } from "../basics/Button";
interface Props {
  tags: string[];
  cardImg: string;
  cardTitle: string;
  cardSubtitle: string;
  cardText: string;
  cardFooter: string;
}
const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  flex-basis: 25%;
  min-width: 330px;
  min-height: 515px;
  background-color: #ffffff;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
`;
const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
`;
const ReadMore = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: ${COLORS.AUBERGINE};
`;
const CardImage = styled.img`
  width: 100%;
`;
const ResButton = styled(Button)`
  background-color: #9ea5e2;
  padding: 3px 15px 3px;
  margin-left: 5px;
  text-decoration: none !important;
`;
const BtnContent = styled.p`
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  @media only screen and (max-width: 900px) {
    font-size: 8px;
  }
`;

const RessourcesCard = ({
  tags,
  cardImg,
  cardTitle,
  cardSubtitle,
  cardText,
  cardFooter,
}: Props) => {
  return (
    <CardItem>
      <CardImage src={cardImg} />
      <CardBody>
        <div style={{ display: "flex" }}>
          {tags.map((tag) => (
            <ResButton>
              <BtnContent>{tag}</BtnContent>
            </ResButton>
          ))}
        </div>
        <Spacer height="10px" />
        <SemiBoldText>{cardTitle}</SemiBoldText>
        <SmallTextTransparent>{cardSubtitle}</SmallTextTransparent>
        <Spacer height="5px" />
        <SmallText>{cardText}</SmallText>
        <Spacer height="20px" />
        <Link to={cardFooter}>
          <ReadMore>Lire la suite</ReadMore>
        </Link>
      </CardBody>
    </CardItem>
  );
};

export default RessourcesCard;
