import { Bullet } from "../../basics/Bullet";
import styled from "styled-components";
import { SemiBoldText } from "../../basics/Typography";

export const WalletSummary = () => (
  <Container>
    <Subtitle>
      Dans la gestion du portefeuille vous avez la possibilité de créer vos
      fiches :
    </Subtitle>
    <ItemList>
      <p>
        <Bullet /> Clients
      </p>
      <p>
        <Bullet /> Prospects
      </p>
      <p>
        <Bullet /> Cibles
      </p>
      <p>
        <Bullet /> etc...
      </p>
    </ItemList>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-right: 5vw;
  @media only screen and (max-width: 900px) {
    max-width: unset;
    margin: 0 20px 40px 20px;
  }
`;

const Subtitle = styled(SemiBoldText)`
  margin-bottom: 10px;
  @media only screen and (max-width: 900px) {
    margin-top: 10px;
  }
`;

const ItemList = styled.span`
  width: 100%;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.54;
  color: #777caa;
  @media only screen and (max-width: 900px) {
    margin: 0;
    justify-content: center;
    align-item: center;
    font-size: 12px;
  }
`;
