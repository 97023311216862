import styled from "styled-components";
import COLORS from "../../../constants/colors";
import Icon from "../../basics/Icon";
import { Spacer } from "../../basics/Spacer";
import { SemiBoldText, SmallText } from "../../basics/Typography";

export const CreateRecord = () => (
  <Container>
    <TextContainer>
      <Spacer height="40px" />
      <Subtitle>Pour créer une fiche,</Subtitle>
      <TextContent>c'est simple !</TextContent>
      <ArrowIcon
        width="60px"
        height="60px"
        src="./img/icon-arrow-handdrawn.svg"
        color={COLORS.ORAGE}
      />
    </TextContainer>
    <CreateRecordImage
      src="./img/img-portefeuille-2-ipad.png"
      srcSet="./img/img-portefeuille-2-ipad@2x.png,
                              ./img/img-portefeuille-2-ipad@3x.png"
    />
  </Container>
);

const Container = styled.div`
  position: absolute;
  top: 20vw;
  right: -8vw;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  z-index: 1;
  @media only screen and (max-width: 900px) {
    position: relative;
    top: unset;
    right: unset;
    flex-direction: column;
    margin-right: 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-right: 3vw;
  @media only screen and (max-width: 900px) {
    margin-right: 0;
    // align-self: flex-start;
    margin: 0 20px;
    align-items: center;
  }
`;

const Subtitle = styled(SemiBoldText)`
  width: 100%;
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;

const TextContent = styled(SmallText)`
  display: flex;
  width: 100%;
  @media only screen and (max-width: 900px) {
    text-align: center;
    width: unset;
  }
`;

const CreateRecordImage = styled.img`
  width: 30vw;
  object-fit: contain;
  align-self: center;
  @media only screen and (max-width: 900px) {
    width: 100%;
    margin: auto;
    display: flex;
  }
`;

const ArrowIcon = styled(Icon)`
  align-self: flex-end;
  @media only screen and (max-width: 900px) {
    transform: rotate(90deg) scaleY(-1);
  }
`;
