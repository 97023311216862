import styled from "styled-components";
import { Button } from "../basics/Button";
import { Spacer } from "../basics/Spacer";
import { NavBar } from "./NavBar";

const Container = styled.div`
  display: flex;
  background-image: url("./img/img-bg-hp.png");
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
  min-height: 45vw;
  align-self: stretch;
  @media only screen and (max-width: 900px) {
    background-image: url("./img/img-bg-hp-mobile.png");
    background-position: 0 100%;
    min-height: 100vw;
  }
`;

const HeadIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  margin: 5px;
  padding: 80px 100px 115px 100px;
  justify-content: center;
  @media only screen and (max-width: 900px) {
    align-items: center;
    margin: auto;
    width: unset;
    padding: 70px 50px 23px 50px;
    margin-bottom: 70vw;
  }
`;

const Title = styled.div`
  margin: 30px 0;
  font-family: Poppins;
  font-size: 35px;
  font-weight: 600;
  line-height: 1.43;
  color: #777caa;
  @media only screen and (max-width: 900px) {
    text-align: center;
    font-size: 28px;
    margin: 40px 0 20px 0;
  }
`;

const ButtonTitle = styled.p`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  @media only screen and (max-width: 900px) {
    font-size: 12px;
    align-self: center;
  }
`;

const HeaderButton = styled(Button)`
  margin-bottom: 30px;
  @media only screen and (max-width: 900px) {
    margin-bottom: 30px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
`;

interface Props {
  title: string;
  buttonTitle?: string;
}

export const Header = ({ title, buttonTitle }: Props) => {
  return (
    <Container>
      <HeadIntro>
        <ContentContainer>
          <Title>{title}</Title>
          {!!buttonTitle && (
            <HeaderButton href="https://calendly.com/ecp-ahmed/15min">
              <ButtonTitle>{buttonTitle}</ButtonTitle>
            </HeaderButton>
          )}
        </ContentContainer>
      </HeadIntro>
    </Container>
  );
};
