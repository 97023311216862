import React from "react";
import styled from "styled-components";
import COLORS from "../../../constants/colors";
import Icon from "../../basics/Icon";

interface Props {
  text: string;
  icon: string;
}

const ServiceItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  box-sizing: border-box;
  flex-basis: 18%;
`;
const ServiceIcon = styled(Icon)`
  display: flex;
  margin-bottom: 15px;
`;
const ServiceDesc = styled.p`
  box-sizing: border-box;
  text-align: center;
  color: #944a98;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  min-width: 100px;
  max-width: 150px;
`;
const ServiceAffich = ({ text, icon }: Props) => {
  return (
    <ServiceItem>
      <ServiceIcon src={icon} color={COLORS.BYZANTIN} height="30px" />
      <ServiceDesc>{text}</ServiceDesc>
    </ServiceItem>
  );
};

export default ServiceAffich;
