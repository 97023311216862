import React from "react";
import styled from "styled-components";
import Footer from "./Footer";
import { Spacer } from "./basics/Spacer";
import { SectionContainer } from "./HomeComponents/HeaderComponents/SectionContainer";
import { Header } from "./composed/Header";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f7f4f7;
`;

const Section = styled(SectionContainer)`
  flex-direction: row;
  padding-bottom: 30px;
  margin-top: -100px;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: -20vw;
  }
`;

const PriceItemContainer = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: center;
  padding: 58px 34px 51px 34px;
  border-radius: 20px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  @media only screen and (max-width: 900px) {
    width: 60vw;
    margin-top: 0;
  }
`;
const Plus = styled.p`
  font-family: Poppins;
  font-size: 42px;
  font-weight: 800;
  margin: 5%;
  align-self: center;
  color: #49264c;
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;

const PriceContainer = styled.p`
  margin-bottom: 20px;
`;

const Prixparmoix = styled.span`
  font-family: Poppins;
  font-size: 62px;
  font-weight: bold;
  line-height: 0.97;
  text-align: center;
  color: #49264c;
  @media only screen and (max-width: 900px) {
  }
`;
const Ht = styled.span`
  font-family: Poppins;
  font-size: 28px;
  color: #777caa;
  font-weight: 600;
  @media only screen and (max-width: 900px) {
  }
`;
const Textparmois = styled.p`
  width: 80%;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 500;
  color: #777caa;
  text-align: center;
  @media only screen and (max-width: 900px) {
  }
`;
const Textfact = styled.p`
  font-family: Poppins;
  font-size: 13px;
  text-align: center;
  color: #777caa;
  @media only screen and (max-width: 900px) {
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex: 1;
`;

const PriceItem = ({
  price,
  frequency,
}: {
  price: string;
  frequency: string;
}) => (
  <PriceItemContainer>
    <PriceContainer>
      <Prixparmoix>{price}€</Prixparmoix>
      <Ht> HT</Ht>
    </PriceContainer>
    {frequency.split("\\n").map((x, i) => (
      <Textparmois key={i.toString()}>{x}</Textparmois>
    ))}
    <Spacer height="20px" />
    <FlexDiv />
    <Textfact>Facturé annuellement</Textfact>
  </PriceItemContainer>
);

const Tarifs = () => {
  return (
    <Container>
      <Header title="Un tarif abordable, transparent et sans surprise !" />
      <Section>
        <PriceItem price="20" frequency="par utilisateur\npar mois" />
        <Plus>+</Plus>
        <PriceItem price="1" frequency="par dossier\npar mois" />
      </Section>
      <Footer />
    </Container>
  );
};

export default Tarifs;
