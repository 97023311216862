import styled from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  @media only screen and (max-width: 900px) {
    padding: 30px 30px;
  }
`;
