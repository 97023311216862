Object.assign(String.prototype, {
  withOpacity(opacity = 1) {
    return (
      this + String(Math.ceil(255 * opacity).toString(16)).padStart(2, "0")
    );
  },
});

Object.assign(String.prototype, {
  disabled(): string {
    return this + String(Math.ceil(255 * 0.3).toString(16)).padStart(2, "0");
  },
});

const COLORS = {
  ORAGE: "#777caa",
  ULTRA_LIGHT_GREY: "#f7f4f7",
  AUBERGINE: "#49264c",
  WHITE: "#ffffff",
  BLACK: "#000000",
  LAVANDE: "#9ea5e2",
  LIGHT_LAVANDE: "#b2b7e8",
  TURQUOISE_DARKER: "#76d0cb",
  TURQUOISE: "#c2f5f2",
  RED: "#a00000",
  GREY: "#f6f4f3",
  LIGHT_GREY: "#e6e3e6",
  BYZANTIN: "#944a98",
  GREEN: "#7cd076",
};

export default COLORS;
