import React from "react";
import styled from "styled-components";
import { SectionFooter } from "../composed/SectionFooter";
import { Spacer } from "../basics/Spacer";
import {
  SectionTitle,
  SemiBoldText,
  SmallText,
  SemiBoldTextTransparent,
  SmallTextTransparent,
  SectionSubtitle,
} from "../basics/Typography";
import { SectionContainer } from "./HeaderComponents/SectionContainer";
const Gestlm = styled(SectionContainer)`
  background-color: #ffffff;
`;
const Block1 = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;
const Block2 = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  flex-direction: row-reverse;
  @media only screen and (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const Lmimg1 = styled.img`
  width: 50vw;
  display: flex;
  object-fit: contain;
  align-self: center;
  @media only screen and (max-width: 900px) {
    margin: 10px 0 20px;
    max-width: 100%;
    width: 100%;
  }
`;
const Lmimg2 = styled.img`
  position: relative;
  max-width: 100%;
  height: auto;
  margin: auto;
  object-fit: contain;
  @media only screen and (max-width: 900px) {
    display: flex;
    margin: auto;
    margin-bottom: 3%;
  }
`;
const Lmimg3 = styled.img`
  position: relative;
  max-width: 100%;
  height: auto;
  margin: auto;
  object-fit: contain;
  opacity: 1;
  @media only screen and (max-width: 900px){
    display:flex;
    margin:auto;
    margin-bottom:3%;
  }
}
`;
const Lmimg4 = styled.img`
  position: relative;
  max-width: 100%;
  height: auto;
  margin: auto;
  object-fit: contain;
  @media only screen and (max-width: 900px) {
    display: flex;
    margin: auto;
    margin-bottom: 3%;
  }
`;

const ImageBlock = styled.div`
  width: 30vw;
  p {
    width: calc(23vw - 30px);
    margin-left: 30px;
  }
  img {
    margin-top: 30px;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    p {
      width: 100%;
      margin-left: 0;
    }
    img {
      margin-top: 10px;
    }
  }
`;

const ImageBlock1 = styled(ImageBlock)`
  align-self: flex-end;
  margin-top: 20%;
  margin-right: -7vw;
  @media only screen and (max-width: 900px) {
    margin-top: 30px;
    margin-right: 0;
  }
`;
const ImageBlock2 = styled(ImageBlock)`
  align-self: center;
  margin-right: -7vw;
  @media only screen and (max-width: 900px) {
    margin-top: 30px;
    margin-right: 0;
  }
`;
const ImageBlock3 = styled(ImageBlock)`
  align-self: flex-start;
  margin-top: 5%;
  @media only screen and (max-width: 900px) {
    margin-top: 30px;
  }
`;
const LmSemiBoldText = styled(SemiBoldText)`
  margin-bottom: 0;
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;
const LmSemiBoldTextTransparent = styled(SemiBoldTextTransparent)`
  margin-bottom: 0;
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;

const LmSmallText = styled(SmallText)`
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;

const LmSmallTextTransparent = styled(SmallTextTransparent)`
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: calc(25vw - 100px);
  @media only screen and (max-width: 900px) {
    width: 100%;
    p {
      opacity: 1;
    }
  }
`;

const GestionLm = () => {
  return (
    <Gestlm>
      <SectionTitle>Gestion des lettres de mission</SectionTitle>
      <SectionSubtitle>
        À partir de votre outil vous pourrez gérer une lettre de mission de sa
        création à sa signature électronique par les deux parties.
      </SectionSubtitle>

      <Block1>
        <Lmimg1
          src="./img/img-lm-1-dk.png"
          srcSet="./img/img-lm-1-dk@2x.png,
                            ./img/img-lm-1-dk@3x.png"
        />
        <InfoContainer>
          <LmSemiBoldText>Créez en toute simplicité</LmSemiBoldText>
          <LmSmallText>votre lettre de mission pour votre client</LmSmallText>
          <Spacer height="30px" />
          <LmSemiBoldTextTransparent>
            Affectez les collaborateurs
          </LmSemiBoldTextTransparent>
          <LmSmallTextTransparent>
            qui seront chargés de travailler sur le dossier
          </LmSmallTextTransparent>
          <Spacer height="30px" />
          <LmSemiBoldTextTransparent>
            Créez vos obligations
          </LmSemiBoldTextTransparent>
          <LmSmallTextTransparent>
            affectées à la lettre de mission
          </LmSmallTextTransparent>
        </InfoContainer>
      </Block1>
      <Block2>
        <ImageBlock3>
          <LmSmallTextTransparent>
            Faites votre tableau de
          </LmSmallTextTransparent>
          <LmSemiBoldTextTransparent>
            répartition des tâches
          </LmSemiBoldTextTransparent>
          <Lmimg2
            src="./img/img-lm-2-ipad.png"
            srcSet="./img/img-lm-2-ipad@2x.png,
                                         ./img/img-lm-2-ipad@3x.png"
          />
        </ImageBlock3>
        <ImageBlock2>
          <LmSmallTextTransparent>Choisissez un</LmSmallTextTransparent>
          <LmSemiBoldTextTransparent>
            calendrier de facturation et
            <br />
            les modalités de paiement
            <br />
            pour chaque jalon
          </LmSemiBoldTextTransparent>
          <Lmimg3
            src="./img/img-lm-3-ipad.png"
            srcSet="./img/img-lm-3-ipad@2x.png,
                                         ./img/img-lm-3-ipad@3x.png"
          />
        </ImageBlock2>
        <ImageBlock1>
          <LmSemiBoldText>Publiez vos lettres</LmSemiBoldText>
          <LmSmallText>à vos clients</LmSmallText>
          <Lmimg4
            src="./img/img-lm-4-ipad.png"
            srcSet="./img/img-lm-4-ipad@2x.png,
                                        ./img/img-lm-4-ipad@3x.png"
          />
        </ImageBlock1>
      </Block2>
      <Spacer height="50px" />
      <SectionFooter
        title="Pour en savoir plus"
        subtitle="et découvrir toutes les autres fonctionnalités du module Lettres de mission, réservez une démonstrationn"
      />
    </Gestlm>
  );
};

export default GestionLm;
