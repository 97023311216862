import styled from "styled-components";
import COLORS from "../../constants/colors";
import { Button } from "../basics/Button";
import { Spacer } from "../basics/Spacer";

interface Props {
  title: string;
  subtitle: string;
}

export const SectionFooter = ({ title, subtitle }: Props) => (
  <SummaryContainer>
    <Title>{title}</Title>
    <Spacer height="8px" />
    <SubTitle>{subtitle}</SubTitle>
    <Spacer height="24px" />
    <SummaryButton href="https://calendly.com/ecp-ahmed/15min">
      <ButtonContent>Réserver une démonstration</ButtonContent>
    </SummaryButton>
  </SummaryContainer>
);

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SummaryButton = styled(Button)`
  margin-bottom: 100px;
  @media only screen and (max-width: 900px) {
    margin-bottom: 40px;
  }
`;

const ButtonContent = styled.p`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

const Title = styled.p`
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  color: ${COLORS.AUBERGINE};
  @media only screen and (max-width: 900px) {
    // margin-top: 20px;
    font-size: 24px;
  }
`;

const SubTitle = styled.p`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  max-width: 700px;
  color: ${COLORS.AUBERGINE};
  @media only screen and (max-width: 900px) {
    font-size: 18px;
  }
`;
