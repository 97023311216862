import React from "react";
import ServiceAffich from "./ServiceAffich";
import "./Service.css";
import styled from "styled-components";
const Services = [
  {
    icon: "./icon/icon-account-6.svg",
    text: "Vos clients et prospects",
  },
  {
    icon: "./icon/icon-lettre-mission.svg",
    text: "Les lettres de missions",
  },
  {
    icon: "./icon/icon-tasks.svg",
    text: "Les obligations et les taches",
  },
  {
    icon: "./icon/icon-invoice.svg",
    text: "La facturation et les paiements",
  },
  {
    icon: "./icon/icon-stats.svg",
    text: "Une analyse Bl des données",
  },
  {
    icon: "./icon/icon-file.svg",
    text: "La gestion des documents electronique",
  },
  {
    icon: "./icon/icon-portefeuille.svg",
    text: "Collaboration entre collaborateurs",
  },
  {
    icon: "./icon/icon-process.svg",
    text: "Suivi de procédures internes",
  },
  {
    icon: "./icon/icon-cabinet-et-moi.svg",
    text: "Collaboration entre cabinet et clients",
  },
  {
    icon: "./icon/icon-sign.svg",
    text: "Suivi réalisation dossier et gestion des signatures",
  },
];
const ServiceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
`;

const Service = () => {
  return (
    <ServiceContainer>
      {Services.map(({ text, icon }, i) => (
        <ServiceAffich key={i} text={text} icon={icon} />
      ))}
    </ServiceContainer>
  );
};
export default Service;
