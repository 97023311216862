import React from "react";
import styled from "styled-components";
import COLORS from "../../../constants/colors";
import Icon from "../../basics/Icon";
import { Spacer } from "../../basics/Spacer";
import { SemiBoldText, SmallText } from "../../basics/Typography";

const services = [
  {
    src: "./icon/icon-mail-fat.svg",
    text: "Les emails reçus et envoyés",
  },
  {
    src: "./icon/icon-lettre-mission.svg",
    text: "Les lettres de missions",
  },
  {
    src: "./icon/icon-phone-4.svg",
    text: "Les appels entrants et sortants",
  },
  {
    src: "./icon/icon-invoice.svg",
    text: "La facturation",
  },
  {
    src: "./icon/icon-sms.svg",
    text: "Les SMS envoyés",
  },
  {
    src: "./icon/icon-sign.svg",
    text: "La gestion électronique des documents",
  },
  {
    src: "./icon/icon-tasks.svg",
    text: "Les obligations liées",
  },
];

interface ItemProps {
  src: string;
  text: string;
}

const Item = ({ src, text }: ItemProps) => (
  <ItemContainer key={src}>
    <Icon width="20px" height="20px" src={src} color={COLORS.ORAGE} />
    <Spacer width="10px" />
    <ItemDescription>{text}</ItemDescription>
  </ItemContainer>
);

export const RecordActivity = () => {
  return (
    <RecordActivityContainer>
      <Title>
        Vous pouvez accéder aux activités de chaque fiche afin de pouvoir
        visualiser:
      </Title>
      <Spacer height="25px" className="desktop-only" />
      <Spacer height="15px" className="mobile-only" />
      <ServicesContainer>
        {services.map(({ src, text }, i) => {
          return <Item key={i} src={src} text={text} />;
        })}
      </ServicesContainer>
    </RecordActivityContainer>
  );
};

const Title = styled(SemiBoldText)`
  max-width: 30vw;
  @media only screen and (max-width: 900px) {
    max-width: unset;
  }
`;

const RecordActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 45%;
  @media only screen and (max-width: 900px) {
    flex-basis: unset;
  }
`;

const ServicesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  flex-basis: 45%;
  align-items: flex-start;
  padding: 20px 5px;
  max-width: 300px;
  @media only screen and (max-width: 900px) {
    padding: 15px 5px;
  }
`;

const ItemDescription = styled(SmallText)`
  display: flex;
  flex: 1;
  min-width: 200px;
  max-width: 250px;
`;
