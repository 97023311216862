import React from "react";
import styled from "styled-components";
import {
  SectionSubtitle,
  SectionTitle,
  SemiBoldText,
  SemiBoldTextTransparent,
  SmallText,
  SmallTextTransparent,
} from "../basics/Typography";
import { Spacer } from "../basics/Spacer";
import { SectionFooter } from "../composed/SectionFooter";
import { SectionContainer } from "./HeaderComponents/SectionContainer";

const Gestf = styled(SectionContainer)`
  background-color: #f7f4f7;
`;

const Factimg1 = styled.img`
  position: relative;
  z-index: 0;
  width: 50vw;
  height: auto;
  margin-left: calc(25vw - 100px);
  object-fit: contain;
  align-self: center;
  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-left: 0;
  }
`;
const Factimg2 = styled.img`
  position: relative;
  z-index: 1;
  width: 30vw;
  height: auto;
  margin: -8vw 5% 0 5%;
  margin-left: calc(12vw - 100px);
  object-fit: contain;
  align-self: center;
  @media only screen and (max-width: 900px) {
    margin: 0 0 0 0;
    width: 100%;
  }
`;
const Block1 = styled.div`
  display: flex;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    p {
      opacity: 1;
      text-align: center;
    }
  }
`;
const Block2 = styled.div`
  display: flex;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;
const Block3 = styled.div`
  display: flex;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Factimg3 = styled.img`
  width: 50vw;
  object-fit: contain;
  align-self: center;
  @media only screen and (max-width: 900px) {
    width: calc(90vw - 30px);
    align-self: flex-start;
  }
`;

const Factimg4 = styled.img`
  width: 12vw;
  height: auto;
  margin: 15vw 0 0 -9vw;
  object-fit: contain;
  align-self: center;
  @media only screen and (max-width: 900px) {
    margin: -37vw 0 0 0;
    width: calc(30vw - 30px);
    align-self: flex-end;
  }
`;

const BillingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  @media only screen and (max-width: 900px) {
    width: 100%;
    align-self: center;
    p {
      opacity: 1;
      text-align: center;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(25vw - 100px);
  @media only screen and (max-width: 900px) {
    width: 100%;
    p {
      opacity: 1;
      text-align: center;
    }
  }
`;

const StyledSemiBoldText = styled(SemiBoldText)`
  margin-bottom: 0;
`;
const StyledSemiBoldTextTransparent = styled(SemiBoldTextTransparent)`
  margin-bottom: 0;
`;

const BillsImage = ({ className }: { className?: string }) => (
  <>
    <Factimg3
      className={className}
      src="./img/img-factu-3-dk.png"
      srcSet="./img/img-factu-3-dk@2x.png,
                                             ./img/img-factu-3-dk@3x.png"
    />
    <Factimg4
      className={className}
      src="./img/img-factu-4-iphone.png"
      srcSet="./img/img-factu-4-iphone@2x.png,
                                              ./img/img-factu-4-iphone@3x.png"
    />
  </>
);

const GestionF = () => {
  return (
    <Gestf>
      <SectionTitle>Gestion de la facturation</SectionTitle>
      <SectionSubtitle>
        Facturer vos obligations n’aura jamais été aussi simple
      </SectionSubtitle>
      <Block1>
        <Factimg1
          src="./img/img-factu-1-dk.png"
          srcSet="./img/img-factu-1-dk@2x.png,
                         ./img/img-factu-1-dk@3x.png"
        />
        <Spacer height="20px" />
        <div style={{ alignSelf: "center" }}>
          <SemiBoldText>
            Alimentez vos projets de facturation d'obligations facturables
          </SemiBoldText>
          <SmallText>en glisser déposer</SmallText>
        </div>
      </Block1>
      <Spacer className="mobile-only" height="10px" />
      <Block2>
        <Factimg2
          src="./img/img-factu-2-ipad.png"
          srcSet="./img/img-factu-2-ipad@2x.png,
                              ./img/img-factu-2-ipad@3x.png"
        />
        <Spacer height="20px" />
        <BillingTextContainer>
          <SemiBoldText>Scindez vos projets de facturation</SemiBoldText>
          <SmallText>
            en plusieurs jalons et plusieurs modalités de paiement (prélèvement,
            CB, chèque)
          </SmallText>
        </BillingTextContainer>
      </Block2>
      <Spacer className="desktop-only" height="50px" />
      <Spacer className="mobile-only" height="20px" />
      <Block3>
        <BillsImage className="mobile-only" />
        <Spacer className="mobile-only" height="20px" />
        <InfoContainer>
          <StyledSemiBoldText>
            Encaissez vos clients en un clic
          </StyledSemiBoldText>
          <SmallText>Par prélèvement ou CB</SmallText>
          <Spacer height="30px" />
          <StyledSemiBoldTextTransparent>
            Analysez vos factures d’honoraires
          </StyledSemiBoldTextTransparent>
          <SmallTextTransparent>
            Filtrez par impayés, échances, clients etc…
          </SmallTextTransparent>
          <Spacer height="30px" />
          <StyledSemiBoldTextTransparent>
            Faites vos avoirs et effectuez vos remboursements
          </StyledSemiBoldTextTransparent>
          <SmallTextTransparent>directement sur l’outil</SmallTextTransparent>
        </InfoContainer>
        <BillsImage className="desktop-only" />
      </Block3>
      <Spacer height="50px" />
      <SectionFooter
        title="Votre facturation"
        subtitle=" N'aura jaimais été gérée aussi simplement..."
      />
    </Gestf>
  );
};

export default GestionF;
