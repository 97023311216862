import React from "react";
import styled from "styled-components";
import {
  SectionSubtitle,
  SectionTitle,
  SemiBoldText,
  SmallText,
} from "../basics/Typography";
import { SectionContainer } from "./HeaderComponents/SectionContainer";

const Gestrccbloc = styled(SectionContainer)`
  background-color: #f7f4f7;
  @media only screen and (max-width: 900px) {
    p {
      text-align: center;
    }
  }
`;
const Rectgestrcc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 55px 99px calc(7vw + 10px) 98px;
  border-radius: 20px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  @media only screen and (max-width: 900px) {
    padding: 10%;
  }
`;

const Gestrcctext1 = styled.h2`
  margin-bottom: 10px;
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #777caa;
  @media only screen and (max-width: 900px) {
    font-size: 18px;
  }
`;

const Gestrcctext2 = styled.p`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #777caa;
  max-width: 600px;
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
`;

const Gestrccimg = styled.img`
  width: 50vw;
  display: flex;
  margin-left: calc(25vw - 100px);
  margin-top: -7vw;
  height: auto;
  object-fit: contain;
  align-self: center;
  @media only screen and (max-width: 900px) {
    margin-left: 0;
    width: 100%;
  }
`;

const Creerproc = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  width: calc(25vw - 100px);
  align-self: center;
  @media only screen and (max-width: 900px) {
    align-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const GestionRcc = () => {
  return (
    <Gestrccbloc>
      <SectionTitle>
        Gestion de la relation Collaborateur / Cabinet
      </SectionTitle>
      <SectionSubtitle>Mon cabinet et moi</SectionSubtitle>
      <Rectgestrcc>
        <Gestrcctext1>Approbateur</Gestrcctext1>
        <Gestrcctext2>
          L’approbateur permet à vos collaborateurs de faire une demande
          « d’Approbation ». Celle-ci peut être une information, un document à
          signer, une demande de Visa etc… Vous aurez une visibilité claire sur
          les demandes initiées, acceptées, en attentes etc…
        </Gestrcctext2>
      </Rectgestrcc>
      <ContentContainer>
        <Gestrccimg
          src="./img/img-relation-collab-1-dk.png"
          srcSet="./img/img-relation-collab-1-dk@2x.png,
                                            ./img/img-relation-collab-1-dk@3x.png"
        />
        <Creerproc>
          <SemiBoldText>
            Créez des procédures internes claires pour vos collaborateurs
          </SemiBoldText>
          <SmallText>
            Vous pourrez ainsi permettre à vos collaborateurs d'accèder à
            l'ensemble des procédures internes du Cabinet en un clic.
          </SmallText>
        </Creerproc>
      </ContentContainer>
    </Gestrccbloc>
  );
};

export default GestionRcc;
