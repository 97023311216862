import React from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

interface IconWrapperProps {
  width?: string;
  height?: string;
  noEvent?: boolean;
}

const IconWrapper = styled.span<IconWrapperProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  pointer-events: ${(props) => (props.noEvent ? "none" : undefined)};
`;

interface IconProps {
  width?: string;
  height?: string;
  color?: string;
  src: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  image?: boolean;
  noEvent?: boolean;
}

const Icon = ({
  width,
  height,
  color,
  src,
  style,
  className,
  onClick,
  image = false,
  noEvent = false,
}: IconProps) => {
  if (image) return <img alt={src} width={width} height={height} src={src} />;
  else
    return (
      <IconWrapper
        noEvent={noEvent}
        onClick={onClick}
        width={width}
        height={height}
        style={style}
        className={`flex-center ${className}`}
      >
        <SVG
          cacheRequests={false}
          onError={(error) => console.log(error.message)}
          preProcessor={(code) =>
            color ? code.replace(/fill=".*?"/g, `fill="${color}"`) : code
          }
          src={src}
          style={{ width, height }}
          key={uuidv4()}
        />
      </IconWrapper>
    );
};

export default Icon;
