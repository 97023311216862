import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import COLORS from "../../constants/colors";
import { MobileNavigation } from "./MobileNavigation";
import Navigation from "./Navigation";

const NavContainer = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-item: stretch;
  z-index: 10;
`;

const Navbar = styled.header<{ isTop: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 30px 100px;
  ${({ isTop }) =>
    isTop
      ? ""
      : `
  background-color: ${COLORS.WHITE};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);`};
  @media only screen and (max-width: 900px) {
    padding: 25px 30px;
  }
`;

const NahalLogo = styled.img`
  max-width: 100px;
  object-fit: contain;
  cursor: pointer;
  align-self: center;
`;

const useNavBar = () => {
  const [isTop, setIsTop] = useState(true);
  useEffect(() => {
    const onScroll = () => {
      setIsTop(window.pageYOffset < 10);
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return isTop;
};

export const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const isTop = useNavBar();
  return (
    <NavContainer>
      <Navbar isTop={isTop}>
        <NahalLogo
          src="./img/logo-simple.png"
          onClick={() => history.push("/")}
        />
        <Navigation isOpen={isOpen} toggleNav={() => setIsOpen(!isOpen)} />
      </Navbar>
      <MobileNavigation isOpen={isOpen} setIsOpen={setIsOpen} />
    </NavContainer>
  );
};
