import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import Ressources from "./components/RessourcesComponents/Ressources";
import Tarifs from "./components/Tarifs";
import GlobalStyles from "./Fonts";
import PaymentConditions from "./components/PaymentConditions";
import SaleConditions from "./components/SaleConditions";
import ScrollToTop from "./components/basics/ScrollToTop";
import { NavBar } from "./components/composed/NavBar";
import RessourcesListeSummary from "./components/RessourcesListeComponents/RessourcesListeSummary";
import Legalnotices from "./components/Legalnotices";

function App() {
  return (
    <div className="">
      <Router>
        <ScrollToTop />
        <GlobalStyles />
        <NavBar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/RessourcesArticle" exact component={Ressources} />
          <Route
            path="/RessourcesListe"
            exact
            component={RessourcesListeSummary}
          />
          <Route path="/tarifs" exact component={Tarifs} />
          <Route path="/mentions-legales" exact component={Legalnotices} />
          <Route
            path="/conditions-liees-aux-moyens-de-paiements"
            exact
            component={PaymentConditions}
          />
          <Route
            path="/conditions-generales-de-vente"
            exact
            component={SaleConditions}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
