import React from "react";
import styled from "styled-components";
import COLORS from "../../../constants/colors";
import { Button } from "../../basics/Button";
import Icon from "../../basics/Icon";
import { Spacer } from "../../basics/Spacer";
import { SectionTitle, SemiBoldText, SmallText } from "../../basics/Typography";
import { SectionFooter } from "../../composed/SectionFooter";
import { SectionContainer } from "../HeaderComponents/SectionContainer";
import { ObligationCard } from "./ObligationCard";

const Gestop = styled(SectionContainer)`
  background-color: #f7f4f7;
`;

const Rect = styled.div`
  margin-right: 17%;
  margin-left: 17%;
  padding: 55px 50px 5vw 50px;
  border-radius: 20px;
  box-shadow: 0 0 60px 0 rgb(0 0 0 / 4%);
  background-color: #ffffff;
  @media only screen and (max-width: 900px) {
    margin-right: 0;
    margin-left: 0;
    padding: 30px 30px 30px 30px;
  }
`;
const Qts = styled.h2`
  margin-bottom: 10px;
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #777caa;
  @media only screen and (max-width: 900px) {
    font-size: 18px;
  }
`;
const Firsttext = styled.p`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #777caa;
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
`;

const ObligationListImage = styled.img`
  width: 40vw;
  display: flex;
  object-fit: contain;
  @media only screen and (max-width: 900px) {
    margin: 10px 0 20px;
    max-width: 100%;
    width: 100%;
  }
`;

const ObligationsPhoneImage = styled.img`
  width: 10vw;
  margin-top: -4vw;
  margin-right: calc(30vw - 100px - 10vw + 7vw);
  object-fit: contain;
  align-self: flex-start;
  @media only screen and (max-width: 1200px) {
    align-self: center;
    margin-top: 0;
  }
  @media only screen and (max-width: 900px) {
    width: 80%;
    align-self: center;
    margin-right: 0;
  }
`;

const Par1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5vw;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;
const Par2 = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;
const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

const LeftContainer = styled(SideContainer)`
  align-items: flex-end;
  @media only screen and (max-width: 900px) {
    align-items: flex-start;
  }
`;

const RightContainer = styled(SideContainer)`
  @media only screen and (max-width: 1200px) {
    align-self: flex-end;
  }
  @media only screen and (max-width: 1000px) {
    margin-bottom: -40px;
  }
  @media only screen and (max-width: 900px) {
    margin-bottom: 0px;
  }
`;

const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  @media only screen and (max-width: 900px) {
    max-width: unset;
  }
`;

const PublishObligationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 40px;
  @media only screen and (max-width: 900px) {
    align-self: center;
    margin: 0 0 20px 0;
  }
`;

const ArrowIcon = styled(Icon)`
  align-self: flex-end;
  margin-top: -10px;
  margin-right: -20px;
  @media only screen and (max-width: 900px) {
    transform: rotate(90deg) scaleY(-1);
    margin-top: 0px;
    align-self: center;
  }
`;

const Obligations = () => {
  return (
    <Gestop>
      <SectionTitle>
        Gestion des Obligations <br />
      </SectionTitle>
      <Spacer height="30px" />
      <Rect>
        <Qts>Qu'est-ce qu'une obligation ?</Qts>
        <Firsttext>
          C'est une tâche affectée à un client.
          <br />
          Celle-ci est attribuée à un ou plusieurs collaborateurs au sein du
          cabinet.
        </Firsttext>
        <Spacer height="10px" />
      </Rect>
      <Par1>
        <LeftContainer>
          <ParagraphContainer>
            <SemiBoldText>Ayez une visibilité claire et précise :</SemiBoldText>
            <SmallText>À faire, En cours, Terminées</SmallText>
          </ParagraphContainer>
        </LeftContainer>
        <ObligationListImage
          src="./img/img-obligation-1-dk.png"
          srcSet="./img/img-obligation-1-dk@2x.png,
                                 ./mg/img-obligation-1-dk@3x.png"
        />
        <RightContainer>
          <ParagraphContainer>
            <SemiBoldText>Filtrez vos obligations</SemiBoldText>
            <SmallText>
              en définissant vous même vos caracteristiques : celles qui
              concernent des clients spécifiques, ayant une durée, une date
              limite, un collaborateur concerné etc...
            </SmallText>
          </ParagraphContainer>
        </RightContainer>
      </Par1>
      <Par2>
        <ObligationCard />
        <PublishObligationContainer>
          <SemiBoldText>Publiez l'obligation à votre client</SemiBoldText>
          <SmallText>
            afin de lui permettre de suivre les travaux en cours
          </SmallText>
          <ArrowIcon
            width="60px"
            height="60px"
            src="./img/icon-arrow-handdrawn.svg"
            color={COLORS.ORAGE}
          />
        </PublishObligationContainer>
        <ObligationsPhoneImage
          src="img/img-obligation-2-smartphone.png"
          srcSet="img/img-obligation-2-smartphone@2x.png,
                                               img/img-obligation-2-smartphone@3x.png"
        />
      </Par2>
      <Spacer height="50px" />
      <SectionFooter
        title="Et bien plus..."
        subtitle="Réservez une démo pour découvrir toutes les fonctionnalités"
      />
    </Gestop>
  );
};

export default Obligations;
