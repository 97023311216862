import React from "react";
import styled from "styled-components";
import { RecordActivity } from "./RecordActivity";
import { WalletSummary } from "./WalletSummary";
import { CreateRecord } from "./CreateRecord";
import { SectionTitle } from "../../basics/Typography";
import { SectionFooter } from "../../composed/SectionFooter";
import { Spacer } from "../../basics/Spacer";
import { SectionContainer } from "../HeaderComponents/SectionContainer";

const Container = styled(SectionContainer)`
  background-color: #ffffff;
`;

const RecordsContainer = styled.div`
  display: flex;
  // justify-content: center;
  flex-direction: row;
  margin-bottom: 10vw;
  @media only screen and (max-width: 1000px) {
    margin-top: 4vw;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CreateRecordContainer = styled.div`
  position: relative;
  display: flex;
  width: 50%;
  align-items: flex-end;
  flex-direction: column;
  padding-top: 7vw;
  @media only screen and (max-width: 1000px) {
    padding-top: 3vw;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    align-items: center;
  }
`;

const RecordActivityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 40px;
  @media only screen and (max-width: 900px) {
    flex-direction: column-reverse;
    margin: 0 10px;
  }
`;

const RecordActivityImage = styled.img.attrs({
  src: "./img/img-portefeuille-3-imac.png",
  srcSet:
    "./img/img-portefeuille-3-imac@2x.png, ./img/img-portefeuille-3-imac@3x.png",
})`
  display: flex;
  object-fit: contain;
  width: 45%;
  @media only screen and (max-width: 900px) {
    margin: auto;
    margin-top: 25px;
    width: 100%;
  }
`;

const RecordsImage = styled.img.attrs({
  src: "./img/img-portefeuille-1-dk.png",
  srcSet:
    "./img/img-portefeuille-1-dk@2x.png, ./img/img-portefeuille-1-dk@3x.png",
})`
  align-self: flex-start;
  width: calc(50% + 3vw);
  margin-left: -6vw;
  margin-top: 40px;
  object-fit: contain;
  @media only screen and (max-width: 900px) {
    margin: 0;
    width: 100%;
  }
`;

const Wallet = () => {
  return (
    <Container>
      <SectionTitle>Gestion de portefeuille</SectionTitle>
      <RecordsContainer>
        <CreateRecordContainer>
          <WalletSummary />
          <RecordsImage className="mobile-only" />
          <Spacer height="30px" />
          <CreateRecord />
        </CreateRecordContainer>
        <RecordsImage className="desktop-only" />
      </RecordsContainer>
      <Spacer height="100px" className="desktop-only" />
      <RecordActivityContainer>
        <RecordActivityImage />
        <RecordActivity />
      </RecordActivityContainer>
      <Spacer height="80px" />
      <SectionFooter
        title="Une analyse 360°"
        subtitle="vous permettra d’avoir un suivi régulier de chaque dossier."
      />
    </Container>
  );
};
export default Wallet;
