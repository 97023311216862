import React from "react";
import styled from "styled-components";
import { Button } from "../basics/Button";
import { SmallTextTransparent } from "./../basics/Typography";
const Resrectangle = styled.div`
  display: flex;
  flex-direction: column;
  margin: -115px 15% 0 15%;
  padding: 50px;
  border-radius: 20px;
  max-width: 1000px;
  box-shadow: 0 0 60px 0 rgb(0 0 0 / 4%);
  background-color: #ffffff;
  @media only screen and (max-width: 1600px) {
    margin: -7vw 15% 0 15%;
  }
  @media only screen and (max-width: 900px) {
    margin: -10vw 25px 0 25px;
    padding: 20px 20px;
  }
`;
const ResButton = styled(Button)`
  background-color: #777caa;
  text-decoration: none !important;
`;
const BtnContent = styled.p`
  font-family: Poppins;
  font-size: 8px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;
const RectRessource = () => {
  return (
    <Resrectangle>
      <ResButton>
        <BtnContent>Entreprise</BtnContent>
      </ResButton>
    </Resrectangle>
  );
};

export default RectRessource;
