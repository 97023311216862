import styled from "styled-components";
import COLORS from "../../constants/colors";

export const SmallText = styled.p`
  font-size: 13px;
  color: ${COLORS.ORAGE};
`;
export const SmallTextTransparent = styled(SmallText)`
  opacity: 0.3;
`;

export const SectionTitle = styled.h2`
  font-size: 40px;
  font-weight: bold;
  color: ${COLORS.AUBERGINE};
  text-align: center;
  line-height: 1.3;
  @media only screen and (max-width: 500px) {
    font-size: 30px;
  }
`;

export const SectionSubtitle = styled.p`
  font-size: 18px;
  text-align: center;
  align-self: center;
  color: ${COLORS.AUBERGINE};
  max-width: 500px;
  margin-top: 10px;
  margin-bottom: 30px;
  @media only screen and (max-width: 500px) {
    font-size: 15px;
  }
`;

export const SemiBoldText = styled.p`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  color: ${COLORS.ORAGE};
  line-height: 1.4;
  margin-bottom: 10px;
  @media only screen and (max-width: 500px) {
    font-size: 15px;
  }
`;
export const SemiBoldTextTransparent = styled(SemiBoldText)`
  opacity: 0.3;
`;
