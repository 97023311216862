import { createGlobalStyle } from "styled-components";
import PoppinsBlack from "./fonts/Poppins/Poppins-Black.ttf";
import PoppinsBlackItalic from "./fonts/Poppins/Poppins-BlackItalic.ttf";
import PoppinsMedium from "./fonts/Poppins/Poppins-Medium.ttf";
import PoppinsSemiBold from "./fonts/Poppins/Poppins-SemiBold.ttf";
import PoppinsBold from "./fonts/Poppins/Poppins-Bold.ttf";
import PoppinsRegular from "./fonts/Poppins/Poppins-Regular.ttf";
import PoppinsBoldItalic from "./fonts/Poppins/Poppins-SemiBoldItalic.ttf";
import { MOBILE_NAV_HEIGHT } from "./constants/constants";

export const GlobalStyles = createGlobalStyle`
@font-face {
    font-family: 'Poppins';
    src: url(${PoppinsBlack}) format('TrueType');
    color: black;
}

@font-face {
    font-family: 'Poppins';
    src: url(${PoppinsBlackItalic}) format('TrueType');
    color: black;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url(${PoppinsBold}) format('TrueType');
    color: black;
    font-weight: bold;
}

@font-face {
    font-family: 'Poppins';
    src: url(${PoppinsMedium}) format('TrueType');
    color: black;
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: url(${PoppinsSemiBold}) format('TrueType');
    color: black;
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: url(${PoppinsRegular}) format('TrueType');
    color: black;
}

@font-face {
    font-family: 'Poppins';
    src: url(${PoppinsBoldItalic}) format('TrueType');
    color: black;
    font-weight: bold;
    font-style: italic;
}

p, h1, h2, h3, h4, h5 {
  font-family: 'Poppins';
  margin: 0;
}

body {
  margin: 0;
}

.mobile-only {
  display: none;
  @media only screen and (max-width: 900px) {
    display: flex;
  }
}

.desktop-only {
  @media only screen and (max-width: 900px) {
    display: none;
  }
}

@keyframes open-key-frame {
  from {
    max-height: 0;
  }
  to {
    max-height: ${MOBILE_NAV_HEIGHT};
  }
}

@keyframes close-key-frame {
  from {
    max-height: ${MOBILE_NAV_HEIGHT};
  }
  to {
    max-height: 0;
  }
}

.open-animation {
  animation-name: open-key-frame;
  animation-duration: 1s;
}

.close-animation {
  animation-name: close-key-frame;
  animation-duration: 1s;
}

`;

export default GlobalStyles;
