import React from "react";
import styled from "styled-components";
import { Spacer } from "./basics/Spacer";
import {
  SectionSubtitle,
  SectionTitle,
  SemiBoldText,
  SmallText,
} from "./basics/Typography";
import { Header } from "./composed/Header";
import Footer from "./Footer";
import { SectionContainer } from "./HomeComponents/HeaderComponents/SectionContainer";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f4f7;
`;

const Legalnotices = () => {
  return (
    <Container>
      <Header title="Mentions légales" />
      <SectionContainer>
        <SectionTitle>MENTIONS LÉGALES</SectionTitle>
        <SectionSubtitle>Dernière mise à jour : Août 2021</SectionSubtitle>
        <Spacer height="30px" />
        <SmallText>
          Vous êtes actuellement connecté au site Internet de H&C Technologies.
          « Nahal » est le nom commercial. L’utilisateur en ayant accès à ce
          site s’engage à se conformer aux présentes conditions d’utilisation.
        </SmallText>
        <Spacer height="20px" />
        <SemiBoldText>Informations générales</SemiBoldText>
        <SmallText>
          Editeur : H&C Technologies – Nahal,
          <Spacer height="5px" /> Adresse : 21 rue d’Algérie 69001 Lyon{" "}
          <Spacer height="5px" />
          Directeur de la publication : Ahmed HEDIA– Président{" "}
          <Spacer height="5px" />
          Adresse électronique :
          <a
            href="mailto:contact@nahal.io"
            style={{ textDecoration: "none", color: "#777caa" }}
          >
            {" "}
            contact@nahal.io
          </a>{" "}
          <Spacer height="5px" /> Siret : 89479890900012 <Spacer height="5px" />{" "}
          N°TVA : FR18894798909
        </SmallText>
        <Spacer height="40px" />
        <SemiBoldText>Loi Informatique et Libertés</SemiBoldText>
        <SmallText>
          Le respect de votre vie privée est un souci constant sur ce serveur.
          En aucun cas, sans votre autorisation expresse, nous ne diffuserons à
          l’extérieur des informations vous concernant. Conformément à la loi n°
          78-17 du 6 janvier 1978, relative à l’informatique, aux fichiers et
          aux libertés (dite loi « Informatique et Liberté »), ce site a fait
          l’objet d’une déclaration à la CNIL. Exercice du droit d’accès
          Conformément à l’article 34 de la loi « Informatique et Libertés »,
          vous disposez d’un droit d’accès, de modification, de rectification et
          de suppression des données qui vous concernent. Pour exercer ce droit
          d’accès, adressez-vous à :<Spacer height="10px" />{" "}
          <b>
            {" "}
            H&C Technologies <br />
            21, rue d’Algérie – 69001 Lyon
          </b>
          <Spacer height="20px" />
          Pour plus d’informations sur la loi « Informatique et Libertés », vous
          pouvez consulter le site Internet de la CNIL
        </SmallText>
        <Spacer height="20px" />
        <SemiBoldText>Copyright</SemiBoldText>
        <SmallText>
          L’ensemble des informations présentes sur ce site peut être
          téléchargé, reproduit, imprimé sous réserve de ;
          <Spacer height="10px" />
          • N’utiliser de telles informations qu’à des fins personnelles et en
          aucune manière à des fins commerciales ;<br />
          • Ne pas modifier de telles informations ;<br />
          • Reproduire sur toutes copies la mention des droits d’auteur (« le
          copyright ») de H&C Technologies.
          <Spacer height="20px" />
          Toute autre utilisation non expressément autorisée est strictement
          interdite sans autorisation préalable et écrite de H&C Technologies.
          <Spacer height="10px" />
          Le non-respect de cette interdiction constitue une contrefaçon pouvant
          engager la responsabilité civile et pénale du contrefacteur.
        </SmallText>
        <Spacer height="20px" />
        <SemiBoldText>Responsabilité</SemiBoldText>
        <SmallText>
          L’ensemble des informations accessibles via ce site sont fournies en
          l’état.
          <Spacer height="20px" />
          H&C Technologies ne donne aucune garantie, explicite ou implicite, et
          n’assume aucune responsabilité relative à l’utilisation de ces
          informations.
          <Spacer height="20px" />
          H&C Technologies n’est pas responsable ni de l’exactitude, ni des
          erreurs, ni des omissions contenues sur ce site.
          <Spacer height="20px" />
          L’utilisateur est seul responsable de l’utilisation de telles
          informations.
          <Spacer height="20px" />
          H&C Technologies se réserve le droit de modifier à tout moment les
          présentes notamment en actualisant ce site.
          <Spacer height="20px" />
          H&C Technologies ne pourra être responsable pour quel que dommage que
          ce soit tant direct qu’indirect, résultant d’une information contenue
          sur ce site.
          <Spacer height="20px" />
          L’utilisateur s’engage à ne transmettre sur ce site aucune information
          pouvant entraîner une responsabilité civile ou pénale et s’engage à ce
          titre à ne pas divulguer via ce site des informations illégales,
          contraires à l’ordre public ou diffamatoires.
        </SmallText>
        <Spacer height="20px" />
        <SemiBoldText>Réclamations</SemiBoldText>
        <SmallText>
          Toute réclamation que vous pourriez avoir doit être adressée au
          service de traitement des réclamations de H&C Technologies, dont les
          coordonnées sont les suivantes : H&C Technologies, Service
          réclamations, 21, rue d’Algérie 69001 Lyon 04 28 29 21 21, email :
          <a
            href="mailto:contact@nahal.io"
            style={{ textDecoration: "none", color: "#777caa" }}
          >
            contact@nahal.io
          </a>
          .
        </SmallText>
        <Spacer height="50px" />
      </SectionContainer>
      <Footer />
    </Container>
  );
};

export default Legalnotices;
