import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../basics/Button";
import { FaBars } from "react-icons/fa";
import { CgClose } from "react-icons/cg";

const NavigationItem = styled.h2`
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  margin: 0 20px;
  text-align: center;
  color: #49264c;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

const DemoButton = styled(Button)`
  margin-left: 20px;
`;

const Text = styled.div`
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
`;
const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  z-index: 10;
`;

const Bars = styled(FaBars)`
  display: none;
  color: #fff;
  @media only screen and (max-width: 900px) {
    display: flex;
    font-size: 1.8rem;
    cursor: pointer;
    color: #49264c;
  }
`;

const Cross = styled(CgClose)`
  display: none;
  color: #fff;
  @media only screen and (max-width: 900px) {
    display: flex;
    font-size: 1.8rem;
    cursor: pointer;
    color: #49264c;
  }
`;

interface Props {
  toggleNav: () => void;
  isOpen: boolean;
}

const Navigation = ({ toggleNav, isOpen }: Props) => {
  return (
    <Nav>
      {!isOpen ? <Bars onClick={toggleNav} /> : <Cross onClick={toggleNav} />}
      <NavigationContainer className="nav-container">
        <NavLink
          exact
          to="/"
          className="navLinks"
          style={{ textDecoration: "none" }}
        >
          <NavigationItem>Nos solutions</NavigationItem>
        </NavLink>
        {/* <NavLink
          exact
          to="/RessourcesArticle"
          className="navLinks"
          style={{ textDecoration: "none" }}
        >
          <NavigationItem>Ressources</NavigationItem>
        </NavLink> */}
        <NavLink
          exact
          to="/tarifs"
          className="navLinks"
          style={{ textDecoration: "none" }}
        >
          <NavigationItem>Tarifs</NavigationItem>
        </NavLink>
        <DemoButton href="https://calendly.com/ecp-ahmed/15min">
          <Text> Réserver une démo</Text>
        </DemoButton>
      </NavigationContainer>
    </Nav>
  );
};

export default Navigation;
