import styled from "styled-components";

export const Button = styled.a`
  display: flex;
  justify-content: center;
  border: none !important;
  align-items: center;
  padding: 12px 28px 12px;
  border-radius: 100px;
  background-color: #49264c;
  cursor: pointer;
  text-decoration: none !important;
`;
