import { Bullet } from "../../basics/Bullet";
import styled from "styled-components";
import { SemiBoldText } from "../../basics/Typography";

export const ObligationCard = () => (
  <Container>
    <ObligationCardImage
      src="./img/img-obligation-3-obl.png"
      srcSet="./img/img-obligation-3-obl@2x.png,
                                        ./img/img-obligation-3-obl@3x.png"
    />
    <ListContainer>
      <Subtitle>
        Sur chaque obligation,
        <br />
        vous pouvez voir
      </Subtitle>
      <ItemList>
        <p>
          <Bullet /> Quels collaborateurs sont concernés par celle-ci
        </p>
        <p>
          <Bullet /> La lettre de mission affectée à chaque obligation
        </p>
        <p>
          <Bullet /> Le temps estimé ainsi que le temps réalisé
        </p>
      </ItemList>
    </ListContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: calc(30vw - 100px - 15vw + 7vw);
  margin-top: -4vw;
  @media only screen and (max-width: 900px) {
    flex-direction: column-reverse;
    margin: 20px 0 0 0;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 900px) {
    max-width: unset;
    margin: 0 0px 20px 0px;
  }
`;

const Subtitle = styled(SemiBoldText)`
  margin-bottom: 10px;
  @media only screen and (max-width: 900px) {
    margin-top: 10px;
  }
`;

const ItemList = styled.span`
  width: 100%;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.54;
  color: #777caa;
  @media only screen and (max-width: 900px) {
    margin: 0;
    justify-content: center;
    align-item: center;
    font-size: 12px;
  }
  p {
    margin: 10px 0;
  }
`;

const ObligationCardImage = styled.img`
  width: 15vw;
  object-fit: contain;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 40px;
  @media only screen and (max-width: 900px) {
    width: 80%;
    align-self: center;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
    align-self: center;
    margin-bottom: 20px;
  }
`;
