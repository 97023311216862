import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import COLORS from "../../constants/colors";
import { MOBILE_NAV_HEIGHT } from "../../constants/constants";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const Container = styled.nav<{ isOpen?: boolean }>`
  z-index: -1;
  display: none;
  flex-direction: column;
  background-color: ${COLORS.AUBERGINE};
  max-height: ${({ isOpen }) => (isOpen ? MOBILE_NAV_HEIGHT : "0")};
  animation-name: ${({ isOpen }) =>
    isOpen === true
      ? "open-key-frame"
      : isOpen === false
      ? "close-key-frame"
      : ""};
  animation-duration: 0.5s;
  overflow: hidden;
  @media only screen and (max-width: 900px) {
    display: flex;
  }
`;

const Content = styled.div`
  flex-direction: column;
`;

const NavigationItem = styled.p`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  margin: 15px 20px;
  text-align: left;
  color: ${COLORS.WHITE};
`;

const Separator = styled.div<{ color: string }>`
  align-self: stretch;
  height: 1px;
  background-color: ${(props) => props.color};
`;

const useMobileNavigator = (
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void
): { isDisplayed?: boolean } => {
  const [isDisplayed, setIsDisplayed] = useState<boolean | undefined>();
  const isFirstRender = useRef(true);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const history = useHistory();

  useEffect(() => {
    if (
      !isFirstRender.current &&
      !(isOpen === false && isDisplayed === undefined)
    ) {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }
      setIsDisplayed(isOpen);
      if (!isOpen) {
        timeoutRef.current = setTimeout(() => {
          setIsDisplayed(undefined);
        }, 500);
      }
    }
    isFirstRender.current = false;
  }, [isOpen]);

  useEffect(() => {
    const unListen = history.listen(() => {
      setIsDisplayed(undefined);
      setIsOpen(false);
    });
    return () => {
      unListen();
    };
  }, []);

  return {
    isDisplayed,
  };
};

export const MobileNavigation = ({ isOpen, setIsOpen }: Props) => {
  const { isDisplayed } = useMobileNavigator(isOpen, setIsOpen);
  return (
    <Container isOpen={isDisplayed}>
      <Content>
        <Separator color="#ffffff80" />
        <NavLink
          exact
          to="/"
          className="navLinks"
          style={{ textDecoration: "none" }}
        >
          <NavigationItem>Nos solutions</NavigationItem>
        </NavLink>
        <Separator color="#ffffff80" />
        {/* <NavLink
          exact
          to="/RessourcesArticle"
          className="navLinks"
          style={{ textDecoration: "none" }}
        >
          <NavigationItem>Ressources</NavigationItem>
        </NavLink>
        <Separator color="#ffffff80" /> */}
        <NavLink
          exact
          to="/tarifs"
          className="navLinks"
          style={{ textDecoration: "none" }}
        >
          <NavigationItem>Tarifs</NavigationItem>
        </NavLink>
        <Separator color="#ffffff80" />
        <a
          href="https://calendly.com/ecp-ahmed/15min"
          style={{ textDecoration: "none" }}
        >
          <NavigationItem>Réserver une démo</NavigationItem>
        </a>
        <Separator color="#ffffff80" />
      </Content>
    </Container>
  );
};
