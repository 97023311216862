import React from "react";
import styled from "styled-components";
import { Spacer } from "../basics/Spacer";
import { Link } from "react-router-dom";
import {
  SectionSubtitle,
  SectionTitle,
  SemiBoldText,
  SmallText,
} from "../basics/Typography";
import { Header } from "../composed/Header";
import { SectionContainer } from "../HomeComponents/HeaderComponents/SectionContainer";
import Footer from "../Footer";
import RessourcesListe from "./RessourcesListe";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f4f7;
`;
const ResContainer = styled(SectionContainer)`
  margin: -12vw 0 0 0;
  @media only screen and (max-width: 900px) {
    margin: -12vw 0 0 0;
    padding: 20px 20px;
  }
  @media only screen and (max-width: 450px) {
    margin: -20vw 0 0 0;
    padding: 20px 20px;
  }
`;

const RessourcesListeSummary = () => {
  return (
    <Container>
      <Header title="Découvrez nos conseils d’expertise comptable" />
      <ResContainer>
        <RessourcesListe />
      </ResContainer>
      <Footer />
    </Container>
  );
};

export default RessourcesListeSummary;
