import React from "react";
import styled from "styled-components";
import RessourcesCard from "./RessourcesCard";
const GridList = [
  {
    tags: ["Fiscalité", "impot"],
    cardImg: "./img/img-article-1.png",
    cardTitle: "Une solution sur mesure pour la fiscalité des indépendants ",
    cardSubtitle: "15 mars 2021",
    cardText:
      "Une solution innovante pour optimiser la fiscalité des indépendants ? Découvrez là avec Ahmed Hedia, responsable du pôle professions indépendantes, dans la vidéo ci-dessus. ",
    cardFooter: "Lire la suite",
  },
  {
    tags: ["Entreprise", "Fiscalité", "impot"],
    cardImg: "./img/img-article-2.png",
    cardTitle:
      "La location de locaux équipés : une véritable opportunité fiscale !",
    cardSubtitle: "17 février 2021",
    cardText:
      "La location de locaux équipés consiste à mettre à disposition des locaux professionnels équipés du mobilier et du matériel nécessaire à leur exploitation. ",
    cardFooter: "Lire la suite",
  },
  {
    tags: ["Fiscalité"],
    cardImg: "./img/img-article-4.png",
    cardTitle: "Covid 19, quelles mesures pour les entreprises ?",
    cardSubtitle: "17 février 2021",
    cardText:
      "Suite à l’épidémie de coronavirus en France, le gouvernement met en place un certain nombre de mesures destinées à protéger les entreprises qui font face à une baisse ou un arrêt total de leur activité, donc de leur chiffre d’affaire. ",
    cardFooter: "Lire la suite",
  },
  {
    tags: ["Fiscalité"],
    cardImg: "./img/img-article-4.png",
    cardTitle: "Covid 19, quelles mesures pour les entreprises ?",
    cardSubtitle: "17 février 2021",
    cardText:
      "Suite à l’épidémie de coronavirus en France, le gouvernement met en place un certain nombre de mesures destinées à protéger les entreprises qui font face à une baisse ou un arrêt total de leur activité, donc de leur chiffre d’affaire. ",
    cardFooter: "Lire la suite",
  },
  {
    tags: ["Fiscalité"],
    cardImg: "./img/img-article-4.png",
    cardTitle: "Covid 19, quelles mesures pour les entreprises ?",
    cardSubtitle: "17 février 2021",
    cardText:
      "Suite à l’épidémie de coronavirus en France, le gouvernement met en place un certain nombre de mesures destinées à protéger les entreprises qui font face à une baisse ou un arrêt total de leur activité, donc de leur chiffre d’affaire. ",
    cardFooter: "Lire la suite",
  },
  {
    tags: ["Fiscalité"],
    cardImg: "./img/img-article-4.png",
    cardTitle: "Covid 19, quelles mesures pour les entreprises ?",
    cardSubtitle: "17 février 2021",
    cardText:
      "Suite à l’épidémie de coronavirus en France, le gouvernement met en place un certain nombre de mesures destinées à protéger les entreprises qui font face à une baisse ou un arrêt total de leur activité, donc de leur chiffre d’affaire. ",
    cardFooter: "Lire la suite",
  },
  {
    tags: ["Fiscalité"],
    cardImg: "./img/img-article-4.png",
    cardTitle: "Covid 19, quelles mesures pour les entreprises ?",
    cardSubtitle: "17 février 2021",
    cardText:
      "Suite à l’épidémie de coronavirus en France, le gouvernement met en place un certain nombre de mesures destinées à protéger les entreprises qui font face à une baisse ou un arrêt total de leur activité, donc de leur chiffre d’affaire. ",
    cardFooter: "Lire la suite",
  },
  {
    tags: ["Fiscalité"],
    cardImg: "./img/img-article-4.png",
    cardTitle: "Covid 19, quelles mesures pour les entreprises ?",
    cardSubtitle: "17 février 2021",
    cardText:
      "Suite à l’épidémie de coronavirus en France, le gouvernement met en place un certain nombre de mesures destinées à protéger les entreprises qui font face à une baisse ou un arrêt total de leur activité, donc de leur chiffre d’affaire. ",
    cardFooter: "Lire la suite",
  },
  {
    tags: ["Fiscalité"],
    cardImg: "./img/img-article-4.png",
    cardTitle: "Covid 19, quelles mesures pour les entreprises ?",
    cardSubtitle: "17 février 2021",
    cardText:
      "Suite à l’épidémie de coronavirus en France, le gouvernement met en place un certain nombre de mesures destinées à protéger les entreprises qui font face à une baisse ou un arrêt total de leur activité, donc de leur chiffre d’affaire. ",
    cardFooter: "Lire la suite",
  },
];
const GridListeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
`;
const RessourcesListe = () => {
  return (
    <GridListeContainer>
      {GridList.map(
        (
          { cardImg, cardTitle, cardSubtitle, cardText, cardFooter, tags },
          i
        ) => (
          <RessourcesCard
            key={i}
            tags={tags}
            cardImg={cardImg}
            cardTitle={cardTitle}
            cardSubtitle={cardSubtitle}
            cardText={cardText}
            cardFooter={cardFooter}
          />
        )
      )}
    </GridListeContainer>
  );
};

export default RessourcesListe;
